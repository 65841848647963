/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '../../src/util';
import { SystemS3Event } from './s3';
import SystemS3EventSchema from './s3.json';

export const isSystemS3Event = guard<SystemS3Event>(SystemS3EventSchema as any);