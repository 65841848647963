import { ArrowBackIosSharp } from '@mui/icons-material';
import { AppBar as AB, IconButton as IB, styled, Toolbar } from '@mui/material';
import { PDFViewer as PW } from '@react-pdf/renderer';
import Modal, { ModalProps } from 'components/Modal';

const AppBar = styled(AB)(({ theme }) => ({
  position: 'relative',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.divider,
}));

const IconButton = styled(IB)(({ theme }) => ({
  padding: theme.spacing(2),
  marginRight: theme.spacing(1.5),
  borderRadius: 0,
}));

const PDFViewer = styled(PW)({
  border: 1,
  flexGrow: 1,
});

export function DocumentModal({
  isOpen,
  onClose,
  headerElement,
  children,
  showAppBar = true,
}: {
  isOpen: boolean;
  onClose: () => void;
  headerElement?: JSX.Element;
  children?: JSX.Element;
  showAppBar?: boolean;
} & Omit<ModalProps, 'open'>) {
  return (
    <Modal fullScreen open={isOpen} onClose={onClose}>
      {showAppBar && (
        <AppBar elevation={2}>
          <Toolbar sx={{ mr: 2 }} variant="dense" disableGutters>
            <IconButton color="primary" onClick={onClose} aria-label="close" size="large">
              <ArrowBackIosSharp />
            </IconButton>

            {headerElement}
          </Toolbar>
        </AppBar>
      )}
      {children && <PDFViewer>{children}</PDFViewer>}
    </Modal>
  );
}
