import { Button, Grid, styled, List as _List, Stack } from '@mui/material';
import { skipProps, spreadIf } from 'system';

export const Container = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ActionButton = styled(Button)(() => ({
  alignSelf: 'end',
}));

export const Bubble = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primaryBackground.main,
  color: theme.palette.primary.main,
  width: theme.spacing(6),
  height: theme.spacing(6),
  borderRadius: theme.spacing(6),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.3em',
  marginRight: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    fontSize: '0.8em',
  },
}));

export const Cell = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1, 0),
  },
}));

export const ListItem = styled(
  Stack,
  skipProps('isLast')
)<{ isLast?: boolean }>(({ theme, isLast }) => ({
  padding: theme.spacing(1, 0),
  ...spreadIf(!isLast, { borderBottom: `1px solid ${theme.palette.inputBackground}` }),
}));

export const List = styled(_List)(({ theme }) => ({
  padding: 0,
  border: `1px solid ${theme.palette.inputBackground}`,
}));

export const Info = styled('div')(() => ({
  display: 'grid',
}));
