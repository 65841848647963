import WizardForm from '../WizardForm';
import { useEditPersonalDetails, PersonalDetailsFields } from './useEditPersonalDetails';

export function PersonalDetailWizardForm({
  defaultValues,
  onCancel,
  onFinish,
  loading,
}: {
  defaultValues?: PersonalDetailsFields;
  onCancel: VoidFunction;
  onFinish: (values: PersonalDetailsFields) => void;
  loading?: boolean;
}) {
  const { steps } = useEditPersonalDetails({ onCancel, onFinish });

  return steps?.length > 0 ? (
    <WizardForm<PersonalDetailsFields>
      initialValues={defaultValues}
      steps={steps}
      loading={loading}
    />
  ) : (
    <></>
  );
}
