import { Chip as C, ChipProps as CP, Skeleton, styled } from '@mui/material';
import { ReactNode } from 'react';
import { skipProps } from 'system';

export type ChipProps = Omit<CP, 'label'> & {
  label: ReactNode;
  textColor?: string;
  borderColor?: string;
  backgroundColor?: string;
  className?: string;
  loading?: boolean;
};

export const StyledChip = styled(
  C,
  skipProps('textColor', 'backgroundColor', 'borderColor')
)<{
  textColor?: string;
  backgroundColor?: string;
  borderColor?: string;
}>(({ theme, textColor, backgroundColor, borderColor }) => ({
  fontWeight: 500,
  fontSize: '0.65rem',
  height: '1.2rem',
  textTransform: 'uppercase',
  cursor: 'default',
  backgroundColor: backgroundColor ?? 'transparent',
  color: textColor ?? theme.palette.text.primary,
  border: `2px solid ${borderColor ?? theme.palette.action.disabledBackground}`,
  [theme.breakpoints.up('sm')]: {
    fontWeight: 500,
    fontSize: '0.8rem',
    height: '1.3rem',
  },
}));

export function Chip({
  label,
  loading = false,
  className,
  textColor,
  backgroundColor,
  borderColor,
  tooltip,
  ...props
}: ChipProps) {
  return loading ? (
    <Skeleton />
  ) : (
    <StyledChip {...{ ...props, label, className, textColor, backgroundColor, borderColor }} />
  );
}
