import { Grid, Stack, Typography } from '@mui/material';
import { formatCurrency, formatDate, formatPhone, titleCase } from 'system';
import DeleteIconButton from '../DeleteIconButton';
import EditIconButton from '../EditIconButton';
import { Header, Item, Location, Phone, Work, CurrencyExchange } from './WorkHistoryDetail.styles';
import { WorkHistoryFields } from '../WorkHistoryWizardForm/schema';

export default function WorkHistoryDetail({
  history,
  onEdit,
  onDelete,
  loading,
}: {
  history: WorkHistoryFields;
  onEdit?: () => void;
  onDelete?: () => void;
  loading?: boolean;
}) {
  return (
    <Grid container marginBottom={2}>
      <Grid item>
        <Stack direction="row" gap={1} alignItems="center">
          <Header>
            {history.startDate && formatDate(history.startDate, { month: 'long', year: 'numeric' })}
            {history.endDate
              ? ` - ${formatDate(history.endDate, { month: 'long', year: 'numeric' })}`
              : ' - Present'}
          </Header>
          <Stack direction="row" gap={0} alignItems="center">
            {onEdit && <EditIconButton loading={loading} onClick={onEdit} />}
            {onDelete && <DeleteIconButton loading={loading} onClick={onDelete} />}
          </Stack>
        </Stack>
        <Item direction="row" gap={1}>
          <Work />
          <Typography variant="body2" color="text.secondary">
            {history.name}
          </Typography>
        </Item>
        <Item direction="row" gap={1}>
          <Location />
          <Typography variant="body2" color="text.secondary">
            {history.location ?? '-'}
          </Typography>
        </Item>
        <Item direction="row" gap={1}>
          <Phone />
          <Typography variant="body2" color="text.secondary">
            {history.phone ? formatPhone(history.phone) : '-'}
          </Typography>
        </Item>
        {history.frequency && (
          <Item direction="row" gap={1}>
            <CurrencyExchange />
            <Typography variant="body2" color="text.secondary">
              {formatCurrency(history.amount)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {titleCase(history.frequency)}
            </Typography>
          </Item>
        )}
      </Grid>
    </Grid>
  );
}
