import { Stack } from '@mui/material';
import { TextFieldController } from 'components';
import { WizardStep } from '../../WizardStep';
import { landlordSchema } from '../schema';
import { Header } from '../../WizardForm/WizardForm.styles';

export function LandlordStep() {
  return (
    <WizardStep
      schema={landlordSchema}
      stepperVariant="linear"
      spacing={0}
      title={<Header>The landlord information</Header>}
    >
      <Stack gap={4} sx={{ my: 2 }}>
        <TextFieldController label="Name of landlord" name="name" variant="standard" />
        <TextFieldController label="Phone number" name="phone" variant="standard" />
        <TextFieldController label="Email address" name="email" variant="standard" />
      </Stack>
    </WizardStep>
  );
}
