import { Check, Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { RichRadioFieldController } from 'components';
import { WizardStep, WizardStepProps } from '../../WizardStep';
import { Header } from '../../WizardForm/WizardForm.styles';
import { useFormContext } from 'react-hook-form';
import {
  AddressHistoryFields,
  ConfirmAdditionalAddressField,
  confirmAdditionalAddressSchema,
} from '../schema';

export function ConfirmAdditionalAddressStep({
  onFinish,
}: WizardStepProps<typeof confirmAdditionalAddressSchema>) {
  const { handleSubmit } = useFormContext<AddressHistoryFields>();

  return (
    <WizardStep
      stepperVariant="linear"
      spacing={0}
      title={<Header>Do you have another address history?</Header>}
      schema={confirmAdditionalAddressSchema}
      onFinish={() => onFinish && handleSubmit(onFinish)()}
    >
      <Stack gap={1} sx={{ my: 2 }}>
        <RichRadioFieldController<ConfirmAdditionalAddressField>
          name="hasOtherAddress"
          options={[
            {
              value: 'yes',
              label: 'Yes',
              icon: Check,
              subLabel: 'I have another address history',
            },
            {
              value: 'no',
              label: 'No',
              icon: Close,
              subLabel: 'I do not have another address history ',
            },
          ]}
        />
      </Stack>
    </WizardStep>
  );
}
