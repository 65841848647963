import { Check, Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { RichRadioFieldController } from 'components';
import { ConfirmField, confirmFieldSchema } from '../schema';
import { WizardStep, WizardStepProps } from '../../WizardStep';
import { Header } from '../../WizardForm/WizardForm.styles';
import { useWizard } from 'react-use-wizard';

export function ConfirmStep({ onFinish, ...props }: WizardStepProps<typeof confirmFieldSchema>) {
  const { nextStep } = useWizard();
  return (
    <WizardStep
      stepperVariant="linear"
      spacing={0}
      title={<Header>Do you have any work history?</Header>}
      schema={confirmFieldSchema}
      {...props}
      onNext={(data) => {
        data.hasHistory !== 'yes' ? onFinish && onFinish() : nextStep();
      }}
    >
      <Stack gap={1} sx={{ my: 2 }}>
        <RichRadioFieldController<ConfirmField>
          name="hasHistory"
          options={[
            {
              value: 'yes',
              label: 'Yes',
              icon: Check,
              subLabel: 'I have work history',
            },
            {
              value: 'no',
              label: 'No',
              icon: Close,
              subLabel: 'I do not have work history',
            },
          ]}
        />
      </Stack>
    </WizardStep>
  );
}
