import { Stack } from '@mui/material';
import { TextFieldController } from 'components';
import { nullableValidPhone } from 'system';
import { string, object, SchemaOf } from 'yup';

export type PhoneNumberField = {
  phone: string;
};

export const phoneNumberSchema = object({
  phone: string()
    .required('Phone is required')
    .test('phone', 'Phone must be a valid phone number', nullableValidPhone),
}) as SchemaOf<PhoneNumberField>;

export function PhoneNumberStep() {
  return (
    <Stack gap={1} sx={{ my: 2 }}>
      <TextFieldController label="Phone number" name="phone" variant="standard" />
    </Stack>
  );
}
