import { Menu } from '@mui/icons-material';
import { Box, Drawer, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Logo } from 'components';
import { useModalControl } from 'hooks/useModalControl';
import { ReactNode, RefObject } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AccountMenu from './AccountMenu';
import BottomNav from './BottomNav';
import {
  DesktopNavStack,
  IconButton,
  StyledBottomAppBar,
  StyledToolbar,
  StyledTopAppBar,
} from './Nav.styles';
import TopNav from './TopNav';
import { CenteredStack } from './components';
import { useShowBreakpoints } from './hooks/useShowBreakpoints';
import { useAuth } from './providers';

const LogoHeader = styled((props) => (
  <CenteredStack {...props}>
    <Logo color="primary" />
  </CenteredStack>
))(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function Nav({
  topNavRef,
  bottomNavRef,
  drawerChildComponent,
}: {
  drawerChildComponent?: ReactNode;
  topNavRef?: RefObject<HTMLDivElement> | ((instance: HTMLElement | null) => void);
  bottomNavRef?: RefObject<HTMLDivElement> | ((instance: HTMLElement | null) => void);
}) {
  const { mdUp } = useShowBreakpoints();
  const { user } = useAuth();
  const [showDrawer, hideDrawer, drawerOpen] = useModalControl();

  return user ? (
    <>
      <StyledTopAppBar position="fixed" elevation={0} ref={topNavRef}>
        <StyledToolbar disableGutters>
          {drawerChildComponent ? (
            <IconButton onClick={() => (drawerOpen ? hideDrawer() : showDrawer())}>
              <Menu />
            </IconButton>
          ) : (
            <></>
          )}
          <Link component={RouterLink} to="/">
            <Logo color="primary" sx={{ mr: 4 }} />
          </Link>
          <DesktopNavStack direction="row">
            <TopNav />
          </DesktopNavStack>
          {mdUp && <AccountMenu />}
        </StyledToolbar>
      </StyledTopAppBar>

      <StyledBottomAppBar elevation={0} ref={bottomNavRef}>
        <BottomNav />
      </StyledBottomAppBar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={hideDrawer}
        PaperProps={{
          sx: { width: '90%', bgcolor: 'background.default', pt: { xs: 5, md: 0 } },
        }}
      >
        <Box
          role="presentation"
          onClick={hideDrawer}
          onKeyDown={hideDrawer}
          sx={{ p: { xs: 3, md: 2 } }}
        >
          {drawerChildComponent}
        </Box>
      </Drawer>
    </>
  ) : (
    <LogoHeader />
  );
}
