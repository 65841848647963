/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '../../src/util';
import { SystemAutopayCollectionFileAction } from './collectionFileAction';
import SystemAutopayCollectionFileActionSchema from './collectionFileAction.json';

export const isSystemAutopayCollectionFileAction = guard<SystemAutopayCollectionFileAction>(SystemAutopayCollectionFileActionSchema as any);