import { Check, Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { RichRadioFieldController } from 'components';
import { ConfirmOtherWorkHistoryField, WorkHistoryFields } from '../schema';
import { WizardStep, WizardStepProps } from '../../WizardStep';
import { Header } from '../../WizardForm/WizardForm.styles';
import { useFormContext } from 'react-hook-form';
import { useWizard } from 'react-use-wizard';

import { GetFieldIndexProp } from '../WorkHistoryWizardForm';
import { DurationStep } from './DurationStep';

export function ConfirmOtherWorkHistoryStep({
  onFinish,
  getFieldIndex,
  isCurrentlyEmployed,
}: WizardStepProps & GetFieldIndexProp & { isCurrentlyEmployed: boolean }) {
  const { nextStep, previousStep, goToStep } = useWizard();
  const { handleSubmit, watch } = useFormContext<WorkHistoryFields>();
  const hasOtherWorkHistory = watch('hasOtherWorkHistory');

  return (
    <WizardStep
      stepperVariant="linear"
      spacing={0}
      title={<Header>Would you like to add another work history?</Header>}
      finishLabel="Submit"
      onFinish={() =>
        hasOtherWorkHistory === 'yes' ? onFinish && handleSubmit(onFinish)() : nextStep()
      }
      onBack={() => {
        isCurrentlyEmployed ? previousStep() : goToStep(getFieldIndex(DurationStep));
      }}
    >
      <Stack gap={1} sx={{ my: 2 }}>
        <RichRadioFieldController<ConfirmOtherWorkHistoryField>
          name="hasOtherWorkHistory"
          options={[
            {
              value: 'yes',
              label: 'Yes',
              icon: Check,
              subLabel: 'I have another work history to add',
            },
            {
              value: 'no',
              label: 'No',
              icon: Close,
              subLabel: 'I do not have another work history',
            },
          ]}
        />
      </Stack>
    </WizardStep>
  );
}
