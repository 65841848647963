import { ApolloProvider } from '@apollo/client';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { BrowserStorageCache as Cache } from '@aws-amplify/cache';
import { datadogRum } from '@datadog/browser-rum';
import { useFlagsmith } from 'flagsmith/react';
import { useErrorNotification, useNotification } from 'hooks';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { stripNonData } from 'system';
import { z } from 'zod';
import { useAuthClient } from './apolloClient';

export type AuthUser = z.infer<typeof authUserShape>;

type UpdateableAttributes = z.infer<typeof updateableAttributesShape>;

const updateableAttributesShape = z.object({
  name: z.string(),
  picture: z.string().optional(),
});

const authUserShape = updateableAttributesShape.extend({
  email: z.string().email(),
  unconfirmed: z.boolean().optional(),
  idToken: z.string(),
  sub: z.string(),
});

interface Signin {
  (loginPayload: { email: string; password: string }): Promise<AuthUser | void>;
  (provider: CognitoHostedUIIdentityProvider.Google): Promise<AuthUser | void>;
}

type AuthContextType = {
  signin: Signin;
  signup: (
    args: {
      email: string;
      password: string;
      name: string;
      userAttributes?: Record<string, string>;
    },
    sendConfirmation?: boolean
  ) => Promise<boolean>;
  resendCode: (args: { email: string }) => void;
  verify: (
    args: { username: string; code: string },
    config: { redirect?: boolean; notify?: boolean }
  ) => Promise<void>;
  update: (args: Partial<UpdateableAttributes>) => void;
  signout: VoidFunction;
  refresh: () => Promise<unknown>;
  user: AuthUser;
  error?: unknown;
};

const AuthContext = createContext({} as AuthContextType);

const CACHE_KEY = '-portal-user' as const;
const env = process.env.REACT_APP_ENV ?? 'dev';

const noOp = () => {};

export default function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<AuthUser>();
  const [error, setError] = useState<string>();
  const [init, setInit] = useState(true);
  useErrorNotification(error);
  const { sendNotification } = useNotification();
  const navigate = useNavigate();
  const flagsmith = useFlagsmith();

  useEffect(() => {
    const cachedUser = authUserShape.safeParse(Cache.getItem(CACHE_KEY));
    setUser(cachedUser.success ? cachedUser.data : undefined);
    setInit(false);
    void refresh();
  }, []);

  useEffect(() => {
    if (user) {
      const flagsmithTraits = {
        user_id: user.sub,
        email: user.email,
        name: user.name,
        app_env: env,
      };

      flagsmith.identify(user.sub, flagsmithTraits);
      datadogRum.setUser(user);

      Cache.setItem(CACHE_KEY, user);
    } else {
      Cache.removeItem(CACHE_KEY);
    }
  }, [flagsmith, user]);

  const signin: Signin = async (arg) => {
    const authPromise =
      typeof arg === 'string'
        ? Auth.federatedSignIn({ provider: arg })
        : Auth.signIn({ username: arg.email.toLowerCase(), password: arg.password });

    try {
      return await authPromise.then(() => refresh());
    } catch (e) {
      e instanceof Error &&
        sendNotification(e.message.replace('PreAuthentication failed with error ', ''), 'error');
    }
  };

  const signup = useCallback(
    async (
      {
        email: rawEmail,
        password,
        name,
        userAttributes,
      }: {
        email: string;
        password: string;
        name: string;
        userAttributes?: Record<string, string>;
      },
      sendConfirmation = true
    ) => {
      const email = rawEmail.toLowerCase();

      try {
        await Auth.signUp({
          username: email,
          password,
          attributes: { ...userAttributes, email, name },
          clientMetadata: { ...(user && { newLogin: 'true' }) },
        });

        return true;
      } catch (e) {
        if (e instanceof Error && e.name === 'UsernameExistsException') {
          if (sendConfirmation) {
            await Auth.resendSignUp(email).catch(noOp);
          } else {
            setError('Could not register e-mail address');
          }
        } else {
          setError(
            e instanceof Error ? e.message.replace('PreSignUp failed with error ', '') : String(e)
          );
        }

        return false;
      }
    },
    [user]
  );

  const resendCode = ({ email }: { email: string }) => {
    return Auth.resendSignUp(email);
  };

  const verify = (
    { username, code }: { username: string; code: string },
    { redirect = true, notify = true } = {}
  ) => {
    const onDone = (confirmed = false) =>
      redirect && navigate('/', { state: { email: username, confirmed } });

    return Auth.confirmSignUp(username, code)
      .then(() => {
        notify && sendNotification('Your account has been confirmed, please sign in.', 'success');
        onDone(true);
      })
      .catch((e) => {
        if (e instanceof Error && e.name === 'ExpiredCodeException') {
          return Auth.resendSignUp(username);
        } else {
          onDone();
        }
      });
  };

  const signout = useCallback(() => {
    setUser(undefined);
    return Auth.signOut()
      .catch((e) => console.error('Error signing out', e))
      .finally(() => navigate('/'));
  }, [navigate]);

  const refresh = () => {
    return (
      Auth.currentAuthenticatedUser()
        .then(async () => {
          const currentUserInfoShape = z.object({
            attributes: authUserShape
              .omit({ unconfirmed: true, idToken: true })
              .extend({
                'cognito:user_status': z.string().nullish(),
              })
              .passthrough(),
          });

          const userInfo = currentUserInfoShape.safeParse(await Auth.currentUserInfo());
          const session = await Auth.currentSession();
          const idToken = session.getIdToken();
          if (userInfo.success) {
            const updatedUser = {
              ...userInfo.data.attributes,
              unconfirmed: userInfo.data.attributes['cognito:user_status'] === 'UNCONFIRMED',
              idToken: idToken.getJwtToken(),
            };
            setUser(updatedUser);

            return updatedUser;
          }
        })
        // eslint-disable-next-line no-console
        .catch(() => console.debug('Not signed in'))
    );
  };

  const client = useAuthClient({ user, signout, refresh });

  useEffect(() => {
    if (user === undefined) {
      void client.clearStore().then(() => client.resetStore());
    }
  }, [user, client]);

  const update = async (attributes: { name?: string }) => {
    await Auth.updateUserAttributes(await Auth.currentAuthenticatedUser(), attributes);
    setUser((oldUser) => ({ ...oldUser, ...(stripNonData(attributes) as AuthUser) }));
  };

  useEffect(() => {
    void refresh();
  }, []);

  const value = useMemo(
    () => ({
      user: user as AuthUser,
      error,
      signin,
      signup,
      resendCode,
      verify,
      update,
      signout,
      refresh,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, error]
  );

  return init ? (
    <></>
  ) : (
    <AuthContext.Provider value={value}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
