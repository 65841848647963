import { Typography, useTheme, Stack } from '@mui/material';
import { VehicleFieldsFragment } from 'api';
import DeleteIconButton from '../DeleteIconButton';
import EditIconButton from '../EditIconButton';
import { Car } from './HouseholdDetails.styles';

export function VehicleDetail({
  vehicle,
  onEdit,
  onDelete,
  loading,
}: {
  vehicle: VehicleFieldsFragment;
  onEdit?: () => void;
  onDelete?: () => void;
  loading?: boolean;
}) {
  const theme = useTheme();

  const asDisplayedVehicle = () =>
    [vehicle.color, vehicle.make, vehicle.model, vehicle.plate].filter(Boolean).join(' ');

  return (
    <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 1 }}>
      <Car />
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="body2" color={theme.palette.text.secondary}>
          {asDisplayedVehicle()}
        </Typography>
        <Stack direction="row" gap={0} alignItems="center">
          {onEdit && <EditIconButton loading={loading} onClick={onEdit} />}
          {onDelete && <DeleteIconButton loading={loading} onClick={onDelete} />}
        </Stack>
      </Stack>
    </Stack>
  );
}
