import {
  Button,
  FormHelperText,
  Input,
  InputLabel,
  InputProps,
  Stack,
  styled,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Controller, FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import SignaturePad from 'react-signature-pad-wrapper';

const ControlContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(1, 0),
  padding: theme.spacing(1),
  background: theme.palette.inputBackground,
  borderTopLeftRadius: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1),
}));

const cropSignature = function (svgStr: string) {
  const div = document.createElement('div');
  div.innerHTML = svgStr.trim();
  document.body.appendChild(div);
  const svg = div.querySelector('svg');
  if (svg) {
    const bbox = svg.getBBox();
    const viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(' ');
    svg.setAttribute('viewBox', viewBox);
    const trimmedSvg = svg.outerHTML;
    div.remove();
    return trimmedSvg;
  }
  return svgStr;
};

export default function SignatureFieldController<
  T extends FieldValues = Record<string, undefined>
>({
  name,
  label,
  helperText,
  onChange,
  defaultValue,
  ...props
}: Omit<InputProps, 'ref' | 'defaultValue'> & {
  name: Path<T>;
  label?: string;
  helperText?: string;
  defaultValue?: string;
}) {
  const { control, setValue, resetField } = useFormContext<T>();
  const [signaturePad, setSignaturePad] = useState<SignaturePad>();
  const signaturePadRef = useCallback((signaturePad: SignaturePad) => {
    setSignaturePad(signaturePad);
  }, []);

  const onStrokeEnd = () => {
    if (signaturePad) {
      const signature = cropSignature(
        atob(signaturePad.toDataURL('image/svg+xml').replace(/data:image\/svg\+xml;base64,/, ''))
      );
      setValue(name, signature as PathValue<T, (string | undefined) & Path<T>>, {
        shouldTouch: true,
      });
    }
  };

  const clearSignature = () => {
    signaturePad?.clear();
    resetField(name);
    setValue(name, '' as PathValue<T, (string | undefined) & Path<T>>);
  };

  useEffect(() => {
    if (defaultValue && signaturePad) {
      signaturePad.fromDataURL(
        `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(defaultValue)))}`
      );
    }
  }, [defaultValue, signaturePad]);

  useEffect(() => {
    if (signaturePad) {
      signaturePad.instance.addEventListener('endStroke', onStrokeEnd);
    }
    return () => signaturePad?.instance?.removeEventListener('endStroke', onStrokeEnd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signaturePad]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <>
          <ControlContainer>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {label && (
                <InputLabel shrink htmlFor={name}>
                  {label}
                </InputLabel>
              )}
              <Button size="small" onClick={clearSignature}>
                Clear
              </Button>
            </Stack>
            <SignaturePad ref={signaturePadRef} height={200} redrawOnResize={false} />
            <Input
              {...props}
              {...field}
              value={field.value ?? ''}
              onChange={(e) => {
                field.onChange(e.target.value);
                onChange?.(e);
              }}
              type="hidden"
              error={Boolean(fieldState.error?.message)}
              sx={{ mt: 0 }}
            />
          </ControlContainer>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {fieldState.error?.message && (
            <FormHelperText error>{fieldState.error?.message}</FormHelperText>
          )}
        </>
      )}
    />
  );
}
