import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  Button,
  CardHeader,
  MobileStepper,
  Stack,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LoadingButton } from 'components';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useWizard } from 'react-use-wizard';
import { z } from 'zod';
import { LinearStepper } from './LinearStepper';

export type WizardStepProps<TZodType extends z.ZodTypeAny = z.ZodTypeAny> = PropsWithChildren<{
  title?: ReactNode;
  disableNext?: boolean;
  onFinish?: () => void | Promise<void>;
  onNext?: (data: z.infer<TZodType>) => void | Promise<void>;
  onCancel?: () => void;
  onBack?: () => void;
  finishLabel?: ReactNode;
  nextLabel?: ReactNode;
  backLabel?: ReactNode;
  schema?: TZodType;
  stepperPosition?: 'top' | 'bottom' | 'both' | 'auto';
  stepperVariant?: 'default' | 'linear';
  spacing?: number;
  sx?: BoxProps['sx'];
}>;

export const WizardStep = ({
  children,
  title,
  disableNext,
  onFinish,
  onCancel,
  onBack,
  onNext,
  finishLabel = 'Finish',
  nextLabel = 'Next',
  backLabel = 'Back',
  spacing = 2,
  schema,
  stepperPosition = 'auto',
  stepperVariant = 'default',
  sx,
}: WizardStepProps) => {
  const theme = useTheme();
  const smUp = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.up('sm'));
  const isLinearStepper = stepperVariant === 'linear';

  const showTopStepper =
    stepperPosition === 'top' ||
    stepperPosition === 'both' ||
    (stepperPosition === 'auto' && !smUp) ||
    isLinearStepper;
  const showBottomStepper =
    stepperPosition === 'bottom' || stepperPosition === 'both' || stepperPosition === 'auto';

  const { stepCount, activeStep, isFirstStep, isLastStep, nextStep, previousStep } = useWizard();

  const [finishing, setFinishing] = useState(false);

  const formContext = useFormContext();
  const values = formContext?.watch();
  const parseResult = schema?.safeParse(values);
  const valid = schema ? parseResult?.success : true;

  const stepper = isLinearStepper ? (
    <LinearStepper />
  ) : (
    <MobileStepper
      sx={{ width: '100%' }}
      steps={stepCount}
      position="static"
      activeStep={activeStep}
      nextButton={
        <LoadingButton
          loading={finishing}
          size="small"
          onClick={
            isLastStep && onFinish
              ? () => {
                  setFinishing(true);
                  void Promise.resolve(onFinish()).finally(() => setFinishing(false));
                }
              : onNext
                ? () => {
                    if (!parseResult?.success) {
                      // this shouldn't be possible, the schema has to be valid for this button to be clickable
                      return;
                    }
                    return onNext(parseResult.data);
                  }
                : nextStep
          }
          disabled={disableNext || !valid || (isLastStep && !onFinish)}
        >
          {onFinish ? (
            <>{finishLabel}</>
          ) : (
            <>
              {nextLabel}
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </>
          )}
        </LoadingButton>
      }
      backButton={
        <Button
          size="small"
          onClick={isFirstStep ? onCancel : onBack ? onBack : previousStep}
          disabled={finishing || (isFirstStep && !onCancel)}
          sx={{ textTransform: 'inherit' }}
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          {backLabel}
        </Button>
      }
    />
  );

  const controls = (
    <Stack direction="row" justifyContent="space-between">
      <Button
        sx={{ borderRadius: 6, minWidth: 120 }}
        variant="outlined"
        onClick={isFirstStep ? onCancel : onBack ? onBack : previousStep}
        disabled={finishing || (isFirstStep && !onCancel)}
      >
        {backLabel}
      </Button>
      <LoadingButton
        loading={finishing}
        sx={{ borderRadius: 6, minWidth: 120 }}
        variant="contained"
        onClick={
          isLastStep && onFinish
            ? () => {
                setFinishing(true);
                void Promise.resolve(onFinish()).finally(() => setFinishing(false));
              }
            : onNext
              ? () => {
                  if (!parseResult?.success) {
                    // this shouldn't be possible, the schema has to be valid for this button to be clickable
                    return;
                  }
                  return onNext(parseResult.data);
                }
              : nextStep
        }
        disabled={disableNext || !valid || (isLastStep && !onFinish)}
      >
        {onFinish ? finishLabel : nextLabel}
      </LoadingButton>
    </Stack>
  );

  return (
    <Stack spacing={spacing}>
      {showTopStepper ? stepper : <></>}
      {title && <CardHeader title={title} />}
      <Box sx={{ p: 2, ...sx }}>{children}</Box>
      {!isLinearStepper && showBottomStepper ? stepper : <></>}
      {isLinearStepper ? controls : <></>}
    </Stack>
  );
};
