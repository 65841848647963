import { useNotification } from 'hooks';
import { useCancelRequestMutation } from 'api';
import { useNavigate } from 'react-router-dom';

export const useCancelRequest = ({ id }: { id?: string }) => {
  const navigate = useNavigate();
  const { sendNotification } = useNotification();
  const [cancelRequestMutation] = useCancelRequestMutation();

  const cancelRequest = async () => {
    await cancelRequestMutation({
      variables: {
        id: id ?? '',
      },
    });

    sendNotification('Request successfully cancelled.', 'success');
    navigate('/request/cancelled');
  };

  return { cancelRequest };
};
