import { TabList as _TabList, TabPanel as _TabPanel } from '@mui/lab';
import { styled, AppBar as _AppBar, Tab as _Tab } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

export const AppBar = styled(_AppBar)(({ theme }) => ({
  margin: theme.spacing(0, 0, 1, 0),
}));

export const TabList = styled(_TabList)(({ theme }) => ({
  margin: theme.spacing(0, 0, 1, 0),
  minWidth: '170px',
  '& .MuiTabs-scroller': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '&.vertical': {
    '& .MuiTabs-scroller': {
      borderRight: `1px solid ${theme.palette.divider}`,
      borderBottom: 0,
    },
  },
  '&.noBottom': {
    '& .MuiTabs-scroller': {
      borderBottom: 0,
    },
  },
}));

export const Tab = styled(_Tab)(({ theme }) => ({
  minWidth: theme.spacing(1),
  padding: theme.spacing(1, 0),
  marginRight: theme.spacing(4),
}));

export const TabPanel = styled(_TabPanel)(({ theme }) => ({
  padding: theme.spacing(0),
  width: '100%',
  '&.vertical': {
    padding: theme.spacing(0, 0, 0, 3),
  },
}));

export const InfoIcon = styled(InfoOutlined)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: '-3px 5px',
  fontSize: '14px',
}));
