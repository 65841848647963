import { Box, Stack } from '@mui/material';
import { DropdownFieldController } from 'components';
import { WizardStep, WizardStepProps } from '../../WizardStep';
import { otherIncomeWizardSchema } from '../schema';
import { Header } from '../../WizardForm/WizardForm.styles';
import { IncomeSource } from 'api';
import { titleCase } from 'system';
import CurrencyFieldController from 'components/FormController/CurrencyFieldController';

export default function AdditionalIncomeStep({
  ...props
}: WizardStepProps<typeof otherIncomeWizardSchema>) {
  return (
    <WizardStep
      stepperVariant="linear"
      spacing={0}
      title={<Header>Tell us about your additional income</Header>}
      schema={otherIncomeWizardSchema}
      {...props}
    >
      <Stack direction="row" spacing={2} alignItems="center" my={2}>
        <CurrencyFieldController type="number" name="amount" label="Monthly Income" />
        <DropdownFieldController
          label="Source"
          name="source"
          variant="filled"
          data={Object.values(IncomeSource).map((value) => ({
            label: titleCase(value.toLowerCase()),
            value,
          }))}
        />
      </Stack>
    </WizardStep>
  );
}
