import { FullScreenSpinner } from 'components';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes } from './routes';

export default function Navigation() {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <Routes>
        {routes.map(({ path, page: Page }) => {
          return <Route key={path} path={path} element={<Page />} />;
        })}
      </Routes>
    </Suspense>
  );
}
