/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantApplicationCoapplicantsAddedEvent } from './coapplicantsAdded';
import TenantApplicationCoapplicantsAddedEventSchema from './coapplicantsAdded.json';

export const isTenantApplicationCoapplicantsAddedEvent = guard<TenantApplicationCoapplicantsAddedEvent>(TenantApplicationCoapplicantsAddedEventSchema as any);