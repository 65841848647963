import { InMemoryCache } from '@apollo/client';
import {
  isTenantAutopayEnrollmentCreatedEvent,
  isTenantLeaseExecutedEvent,
  isTenantProfileBankAccountChangedEvent,
  isTenantResidentAddedEvent,
} from '@propra-resident/registry';
import { HandlerMap, guardHandler } from '@propra-system/registry';

export const cacheHandlers = (cache: InMemoryCache) =>
  [
    guardHandler(isTenantLeaseExecutedEvent, ({ detail: { leaseAgreementId } }) => {
      let agreementFileKey = '';
      cache.modify({
        id: cache.identify({ id: leaseAgreementId, __typename: 'LeaseAgreement' }),
        fields: {
          agreementFileKey(cached: string) {
            agreementFileKey = cached;
            return '';
          },
        },
      });
      setTimeout(() => {
        cache.modify({
          id: cache.identify({ id: leaseAgreementId, __typename: 'LeaseAgreement' }),
          fields: {
            agreementFileKey() {
              return agreementFileKey;
            },
          },
        });
      });
      return Promise.resolve();
    }),
    guardHandler(isTenantProfileBankAccountChangedEvent, ({ detail: { profileId } }) => {
      cache.evict({ id: cache.identify({ id: profileId, __typename: 'Profile' }) });
      cache.gc();
      return Promise.resolve();
    }),
    guardHandler(isTenantAutopayEnrollmentCreatedEvent, ({ detail: { profileId } }) => {
      cache.evict({ id: cache.identify({ id: profileId, __typename: 'Profile' }) });
      cache.gc();
      return Promise.resolve();
    }),
    guardHandler(isTenantResidentAddedEvent, ({ detail: { profileId } }) => {
      cache.evict({ id: cache.identify({ id: profileId, __typename: 'Profile' }) });
      cache.gc();
      return Promise.resolve();
    }),
  ] as HandlerMap;
