import clsx from 'clsx';
import { StepIconProps, Theme } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.disabled,
      display: 'flex',
      height: 26,
      alignItems: 'center',
    },
    active: {
      color: theme.palette.primary.main,
    },
    completed: {
      color: theme.palette.primary.main,
    },
  })
);

export default function FlowStepIcon(props: StepIconProps) {
  const classes = useStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <Icons.DescriptionOutlined style={{ height: 20 }} />,
    2: <Icons.Event style={{ height: 20 }} />,
    3: <Icons.DraftsOutlined style={{ height: 20 }} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
