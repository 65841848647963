import { maybe, nullableValidPhone } from 'system';
import { z } from 'zod';
import { IncomeSource } from 'api';

export const yesNoShape = z.enum(['yes', 'no']);

export const confirmFieldSchema = z.object({
  hasHistory: z.string().min(1, 'This field is required'),
});
export type ConfirmField = z.infer<typeof confirmFieldSchema>;

export const durationSchema = z.object({
  startDate: z
    .string()
    .min(1, 'Start Date is required')
    .refine((value) => !isNaN(Date.parse(value)), {
      message: 'Invalid date format',
      path: [],
    }),
  endDate: maybe(
    z.string().refine((value) => !isNaN(Date.parse(value)), {
      message: 'Invalid date format',
      path: [],
    })
  ),
});
export type DurationField = z.infer<typeof durationSchema>;

export const locationFieldSchema = z.object({
  location: z.string().min(1, 'Location is required'),
});
export type LocationField = z.infer<typeof locationFieldSchema>;

export const nameFieldSchema = z.object({
  name: z.string().min(1, 'Name of workplace is required'),
});
export type NameField = z.infer<typeof nameFieldSchema>;

export const phoneNumberSchema = z.object({
  phone: maybe(
    z.string().refine(nullableValidPhone, {
      message: 'Phone must be empty or a valid phone number',
    })
  ),
});
export type PhoneNumberField = z.infer<typeof phoneNumberSchema>;

export const salarySchemaForm = z.object({
  amount: maybe(z.number()),
  frequency: maybe(z.string()),
});

export const salarySchema = z.object({
  amount: z.number(),
  frequency: z.string(),
});
export type SalaryField = z.infer<typeof salarySchema>;

export const confirmOtherWorkHistorySchema = z.object({
  hasOtherWorkHistory: yesNoShape,
});
export type ConfirmOtherWorkHistoryField = z.infer<typeof confirmOtherWorkHistorySchema>;

export const otherIncomeWizardSchema = z.object({
  id: maybe(z.string()),
  amount: maybe(z.number().int().positive()),
  source: maybe(z.nativeEnum(IncomeSource)),
});

export const confirmAdditionalIncomeSchema = z.object({
  hasOtherIncome: yesNoShape,
});
export type ConfirmAdditionalIncomeField = z.infer<typeof confirmAdditionalIncomeSchema>;

export const workHistoryFieldsSchema = salarySchemaForm
  .merge(phoneNumberSchema)
  .merge(nameFieldSchema)
  .merge(locationFieldSchema)
  .merge(durationSchema)
  .merge(
    z.object({
      hasOtherIncome: maybe(yesNoShape),
    })
  )
  .merge(
    z.object({
      hasOtherWorkHistory: maybe(yesNoShape),
    })
  );

export type WorkHistoryFields = z.infer<typeof workHistoryFieldsSchema> &
  ConfirmOtherWorkHistoryField &
  ConfirmAdditionalIncomeField;
