import { DateOfBirthField, dateOfBirthSchema, DoBStep } from './DoBStep';
import { NameStep, NameField, nameFieldSchema } from './NameStep';
import { PhoneNumberStep, PhoneNumberField, phoneNumberSchema } from './PhoneNumberStep';
import { WizardStep } from 'apps/portal/components/WizardForm/useWizard';
import { useMemo } from 'react';
import {
  EmergencyContactsField,
  emergencyContactsSchema,
  EmergencyContactsStep,
} from './EmergencyContactsStep';

export type PersonalDetailsFields =
  | NameField
  | PhoneNumberField
  | DateOfBirthField
  | EmergencyContactsField;

export const useEditPersonalDetails = ({
  onCancel,
  onFinish,
}: {
  onCancel: VoidFunction;
  onFinish: (values: PersonalDetailsFields) => void;
}) => {
  const steps: WizardStep<PersonalDetailsFields>[] = useMemo(
    () => [
      {
        header: {
          label: 'What is your name?',
        },
        schema: nameFieldSchema,
        FieldsComponent: NameStep,
        actions: [
          {
            label: 'Back',
            onClick: onCancel,
            skipValidation: true,
            align: 'left',
          },
          {
            label: 'Next',
            onClick: async ({ handleNext }: { handleNext: () => void }) => {
              handleNext();
            },
            align: 'right',
          },
        ],
      },
      {
        header: {
          label: 'How can we contact you?',
        },
        schema: phoneNumberSchema,
        FieldsComponent: PhoneNumberStep,
        actions: [
          {
            label: 'Back',
            onClick: ({ handleBack }: { handleBack: () => void }) => {
              handleBack();
            },
            skipValidation: true,
            align: 'left',
          },
          {
            label: 'Next',
            onClick: ({ handleNext }: { handleNext: () => void }) => {
              handleNext();
            },
            align: 'right',
          },
        ],
      },
      {
        header: {
          label: 'Who is your emergency contact?',
        },
        schema: emergencyContactsSchema,
        FieldsComponent: EmergencyContactsStep,
        props: { accountName: 'Propra' },
        actions: [
          {
            label: 'Back',
            onClick: ({ handleBack }: { handleBack: () => void }) => {
              handleBack();
            },
            skipValidation: true,
            align: 'left',
          },
          {
            label: 'Next',
            onClick: ({ handleNext }: { handleNext: () => void }) => {
              handleNext();
            },
            align: 'right',
          },
        ],
      },
      {
        header: {
          label: 'What is your date of birth?',
        },
        schema: dateOfBirthSchema,
        FieldsComponent: DoBStep,
        actions: [
          {
            label: 'Back',
            onClick: ({ handleBack }: { handleBack: () => void }) => {
              handleBack();
            },
            skipValidation: true,
            align: 'left',
          },
          {
            label: 'Submit',
            onClick: ({
              handleNext,
              values,
            }: {
              handleNext: () => void;
              values: PersonalDetailsFields;
            }) => {
              onFinish(values);
              handleNext();
            },
            align: 'right',
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    steps,
  };
};
