/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '../../src/util';
import { SystemAutopaySummaryStatusUpdatedEvent } from './summaryStatusUpdated';
import SystemAutopaySummaryStatusUpdatedEventSchema from './summaryStatusUpdated.json';

export const isSystemAutopaySummaryStatusUpdatedEvent = guard<SystemAutopaySummaryStatusUpdatedEvent>(SystemAutopaySummaryStatusUpdatedEventSchema as any);