import { CloudDownload, Delete } from '@mui/icons-material';
import { Alert, useMediaQuery, useTheme } from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import bytes from 'bytes';
import { AutoSizeBox } from 'components/AutoSizeBox';
import { useEffect, useState } from 'react';
import { ensureArray, filterByValue, formatDate, spreadListIf } from 'system';
import { DocumentCell } from './DocumentCell';
import DocumentList from './DocumentList';
import { Document, DocumentRowModel, KeyAction } from './types';
import { compact } from 'lodash';

type DocumentTableProps<T extends Record<string, unknown>> = {
  documents: Document<T>[];
  extraColumns?: GridColDef<DocumentRowModel<T>>[];
  loading: boolean;
  downloadFn?: (document: Document<T>) => void;
  renameFn?: (document: Document<T>) => void;
  deleteFn?: KeyAction;
  disabled?: boolean;
  showType?: boolean;
};

export function DocumentTable<T extends Record<string, unknown>>({
  documents,
  extraColumns,
  downloadFn,
  deleteFn,
  loading,
  showType = false,
}: DocumentTableProps<T>) {
  const [rows, setRows] = useState<DocumentRowModel<T>[]>([]);

  useEffect(() => {
    setRows([
      ...ensureArray(documents).map(
        ({ key, typename, name, createdZ, size, downloadFn: docDownloadFn, ...rest }) =>
          ({
            ...rest,
            id: key,
            name: name ?? decodeURIComponent(key),
            key,
            createdZ,
            ...{ ...(size ? { size: bytes(size, { unitSeparator: ' ' }) } : {}) },
            typename: typename ?? '',
            downloadFn: docDownloadFn,
          }) as DocumentRowModel<T>
      ),
    ]);
  }, [documents]);

  const columns: GridColDef<DocumentRowModel<T>>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      renderCell({
        row: { name, key: docKey, size, subtext, address },
      }: {
        row: DocumentRowModel<T>;
      }) {
        return <DocumentCell {...{ name, docKey, size, subtext, address }} />;
      },
    },
    {
      field: 'createdZ',
      headerName: 'Date',
      flex: 1,
      type: 'dateTime',
      getApplyQuickFilterFn: (value: string) => {
        return value
          ? (params: GridCellParams): boolean =>
              Boolean(
                filterByValue(
                  compact([params.row.createdZ && formatDate(params.row.createdZ)]),
                  value
                ).length
              )
          : null;
      },
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) => value && formatDate(value),
    },
    ...spreadListIf(showType, [
      {
        field: 'typename',
        headerName: 'Type',
        flex: 1,
      },
    ]),
    ...spreadListIf(extraColumns, extraColumns),
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<CloudDownload />}
          label="Download"
          title="Download"
          onClick={() => row?.downloadFn?.()}
        />,
        ...spreadListIf(deleteFn, [
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            title="Delete"
            onClick={() => deleteFn?.(row?.key ?? '')}
          />,
        ]),
      ],
    },
  ];

  const Toolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return mdDown ? (
    <DocumentList {...{ documents: rows, loading, extraColumns, downloadFn, deleteFn }} />
  ) : (
    <AutoSizeBox>
      <DataGridPro
        disableColumnReorder
        disableRowSelectionOnClick
        disableColumnPinning
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        sx={{
          '& .MuiDataGrid-row': { cursor: 'pointer' },
        }}
        getRowHeight={() => 'auto'}
        loading={loading}
        rows={rows}
        columns={columns}
        onRowClick={({ row }) => {
          (row?.downloadFn ?? downloadFn)?.(row);
        }}
        components={{
          Toolbar,
          NoRowsOverlay: () => <Alert severity="info">There are no documents to show.</Alert>,
        }}
      />
    </AutoSizeBox>
  );
}
