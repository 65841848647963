/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '../../src/util';
import { SystemAutopaySettlementFileGroupAction } from './settlementFileGroupAction';
import SystemAutopaySettlementFileGroupActionSchema from './settlementFileGroupAction.json';

export const isSystemAutopaySettlementFileGroupAction = guard<SystemAutopaySettlementFileGroupAction>(SystemAutopaySettlementFileGroupActionSchema as any);