import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { RadioField, RadioFieldProps } from '../Fields/RadioField';

export default function RadioFieldController<T extends FieldValues = Record<string, undefined>>({
  name,
  onChange,
  disabled,
  ...props
}: RadioFieldProps & { name: Path<T> }) {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <RadioField
          value={field.value}
          onChange={(event, checked) => {
            field.onChange(event);
            onChange?.(event, checked);
          }}
          disabled={disabled || isSubmitting}
          {...props}
        />
      )}
    />
  );
}
