import { Box, Button, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { FieldPathByValue, FieldValues, useController, useFormContext } from 'react-hook-form';
import { spreadIf } from 'system';

export default function FileUploadFieldController<
  TFields extends FieldValues = Record<string, unknown>,
  TPath extends FieldPathByValue<TFields, File | undefined> = FieldPathByValue<
    TFields,
    File | undefined
  >
>({
  name,
  helperText,
  accept,
  ...props
}: Omit<TextFieldProps, 'name'> & {
  name: TPath;
  accept?: string;
  helperText?: string;
}) {
  const { control } = useFormContext<TFields>();
  const { field, fieldState } = useController<TFields, TPath>({ name, control });
  const value: File | undefined = field.value;

  return (
    <Box>
      <TextField
        type="text"
        variant="filled"
        fullWidth
        {...props}
        sx={spreadIf(Boolean(fieldState.error?.message ?? helperText), { mb: 1 })}
        value={value?.name ?? ''}
        {...{
          error: Boolean(fieldState.error?.message),
          helperText: fieldState.error?.message ?? helperText,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <label htmlFor={`real-file-input-${name}`}>
                <Button color="primary" component="span">
                  Select
                </Button>
              </label>
            </InputAdornment>
          ),
        }}
      />
      <input
        style={{ display: 'none' }}
        id={`real-file-input-${name}`}
        type="file"
        accept={accept}
        onChange={(event) => {
          if (event.target.files) {
            field.onChange(event.target.files[0]);
          }
        }}
      />
    </Box>
  );
}
