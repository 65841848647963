import { Stack } from '@mui/material';
import { SalaryFrequency } from 'api';
import { DropdownFieldController } from 'components';
import { sameMonthAsToday, titleCase } from 'system';

import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import CurrencyFieldController from 'components/FormController/CurrencyFieldController';
import { WizardStep } from '../../WizardStep';
import { Header } from '../../WizardForm/WizardForm.styles';
import { salarySchema, WorkHistoryFields } from '../schema';

export function SalaryStep() {
  const { watch, setValue } = useFormContext<WorkHistoryFields>();
  const endDate = watch('endDate');
  const frequency = watch('frequency');
  const isSameMonth = sameMonthAsToday(endDate);

  useEffect(() => {
    if (!frequency && (isSameMonth || !endDate))
      setValue('frequency', SalaryFrequency.AnnualSalary);
  }, [isSameMonth, setValue, endDate]);

  return (
    <WizardStep
      stepperVariant="linear"
      spacing={0}
      title={<Header>What is your salary?</Header>}
      schema={salarySchema}
    >
      <Stack flexDirection="row" gap={1} sx={{ my: 2 }}>
        <CurrencyFieldController label="Amount" name="amount" variant="standard" />
        <DropdownFieldController
          label="Frequency"
          name="frequency"
          variant="standard"
          data={Object.values(SalaryFrequency).map((value) => ({
            label: titleCase(value.toLowerCase()),
            value,
          }))}
        />
      </Stack>
    </WizardStep>
  );
}
