import { SvgIcon, SvgIconProps } from '@mui/material';
import { useTheme } from 'context';
import { colours } from 'system';

export default function Expired({ ...props }: SvgIconProps) {
  const { darkMode } = useTheme();
  const fill = darkMode ? colours.whiteSmoke : colours.black;
  return (
    <SvgIcon viewBox="0 0 94 92" {...props}>
      <path
        d="M7.66699 46.0029C7.66699 65.8979 22.8087 82.2279 42.167 84.1445L42.167 76.4012C27.0637 74.5229 15.3337 61.6045 15.3337 46.0029C15.3337 37.0712 19.2437 29.0595 25.377 23.4629L34.5003 32.5862L34.5003 9.58619L11.5003 9.58619L19.9337 18.0195C12.4203 24.9962 7.66698 34.9245 7.66699 46.0029Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.8991 41.6063C73.9106 36.0144 71.357 30.7888 67.5524 26.573L73.3386 21.3514C78.1228 26.6528 81.331 33.2177 82.574 40.2497L74.8991 41.6063ZM73.7107 56.6445C74.6555 53.894 75.1792 51.1071 75.3193 48.351L83.1031 48.7466C82.9264 52.2232 82.2658 55.7296 81.082 59.1762C79.8982 62.6229 78.2649 65.7952 76.2681 68.6467L69.8839 64.1761C71.4669 61.9156 72.766 59.395 73.7107 56.6445ZM51.8799 77.6338C51.8799 77.1466 51.8799 76.6595 51.8799 76.28C56.9021 75.1261 61.573 72.7002 65.4082 69.2392L70.6298 75.0253C65.3657 79.7759 58.8558 82.9725 51.8799 84.2342C51.8304 84.2431 51.8799 81.5307 51.8799 80.5565L51.8799 77.6338Z"
        fill={fill}
      />
      <path
        d="M62.3552 21.7881C57.771 18.833 52.453 17.1665 47.0087 16.972C47.0087 16.2569 47.0087 15.2826 47.0087 14.7955L47.0092 10.4127C47.0092 10.4127 47.009 9.43806 47.0087 9.1742C53.9514 9.37123 60.7355 11.471 66.5781 15.2374L62.3552 21.7881Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8524 51.0115C38.5188 51.4206 39.3107 51.6536 40.1719 51.6536H44.3529V51.6539H44.3534V55.4883H40.1724C40.0755 55.4883 39.979 55.4866 39.883 55.4831C35.4593 55.333 32 51.7477 32 47.244C32 42.6427 35.6109 39 40.1719 39H44.3529V42.8344H40.1719C37.7014 42.8344 35.8009 44.7516 35.8009 47.244C35.8009 47.2441 35.8009 47.2442 35.8009 47.2443H35.8013C35.8013 48.8676 36.6076 50.247 37.8524 51.0115ZM50.6244 45.3271H50.6249V49.1615L42.0728 49.1615V49.1612H42.0724V45.3268H50.6244V45.3271ZM52.8136 39.0051C57.2376 39.1549 60.6973 42.7404 60.6973 47.2443C60.6973 51.8456 57.0864 55.4883 52.5253 55.4883L48.3443 55.4883V51.6539L52.5253 51.6539C54.9959 51.6539 56.8964 49.7366 56.8964 47.2443C56.8964 47.2442 56.8964 47.2441 56.8964 47.244H56.8959C56.8959 45.6207 56.0897 44.2413 54.8448 43.4768C54.1785 43.0677 53.3866 42.8347 52.5253 42.8347L48.3443 42.8347V42.8344H48.3439V39H52.5249C52.6216 39 52.7178 39.0017 52.8136 39.0051Z"
        fill="#EB5757"
      />
    </SvgIcon>
  );
}
