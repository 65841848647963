import {
  FormControl,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
  Card,
  CardContent as _CardContent,
  Stack,
  styled,
  FormHelperText,
  CardActionArea,
} from '@mui/material';

export const StyledIcon = styled('div')(({ theme }) => ({
  borderRadius: '50%',
  width: theme.spacing(6),
  height: theme.spacing(4.5),
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primaryBackground.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
  },
}));

const CardContent = styled(_CardContent)(({ theme }) => ({
  '&:last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

export type RichRadioFieldProps = RadioGroupProps & {
  label?: string;
  helperText?: string;
  options: { value: string; label: string; subLabel?: string; icon?: React.ComponentType }[];
  value?: string;
  disabled?: boolean;
  error?: { message?: string };
  onActionAreaClick?: (value: string) => void;
};

export function RichRadioField({
  label,
  options,
  disabled,
  helperText,
  error,
  onActionAreaClick,
  ...RadioFieldProps
}: RichRadioFieldProps) {
  return (
    <FormControl fullWidth variant="filled">
      <FormControl>
        {label && <Typography>{label}</Typography>}
        <RadioGroup color="primary" {...RadioFieldProps}>
          {options.map(({ value, label: optionLabel, subLabel, icon: Icon }) => (
            <Card variant="outlined" key={value} sx={{ mb: 2 }}>
              <CardActionArea onClick={() => onActionAreaClick?.(value)}>
                <CardContent>
                  <Stack direction="row" gap={2} alignItems="center">
                    {Icon && (
                      <StyledIcon>
                        <Icon />
                      </StyledIcon>
                    )}
                    <Stack>
                      <Typography fontWeight="500" fontSize="16px">
                        {optionLabel}
                      </Typography>
                      {subLabel && (
                        <Typography sx={{ fontSize: 14 }} color="text.secondary">
                          {subLabel}
                        </Typography>
                      )}
                    </Stack>
                    <Stack marginLeft="auto">
                      <Radio color="primary" size="small" disabled={disabled} value={value} />
                    </Stack>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </RadioGroup>
        {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
        {error?.message && <FormHelperText error>{error?.message}</FormHelperText>}
      </FormControl>
    </FormControl>
  );
}
