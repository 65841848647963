import { Box, Grid, Stack, SxProps } from '@mui/material';
import { FullSizePaper, LoadingButton, SubmitSplitButton } from 'components';
import { ForwardedRef, PropsWithChildren, forwardRef, useImperativeHandle } from 'react';
import { DeepPartial, FieldValues } from 'react-hook-form';
import { Merge, UnionToIntersection } from 'system';
import { ConnectForm } from '../ConnectForm';
import {
  Container,
  Form,
  Header,
  HeadingBox,
  IconContainer,
  LinearProgress,
  LinearProgressContainer,
} from './WizardForm.styles';
import { WizardStep, useWizard } from './useWizard';
import { FormProps } from 'components/FormController/Form';

export type WizardFormRef = {
  goBack: () => void;
  goNext: () => Promise<unknown>;
};

type WizardFormProps<T extends FieldValues> = PropsWithChildren<{
  initialValues?: DeepPartial<T>;
  steps: WizardStep<T>[];
  loading?: boolean;
  goToStep?: number;
  showProgress?: boolean;
  paperSx?: SxProps;
  formProps?: Omit<FormProps<T>, 'defaultValue' | 'children'>;
}>;

function WizardForm<T extends FieldValues = Record<string, unknown>>(
  {
    initialValues,
    steps,
    children,
    loading,
    goToStep,
    showProgress = true,
    paperSx,
    formProps,
  }: WizardFormProps<T>,
  mRef?: ForwardedRef<WizardFormRef>
) {
  const { stepIndex, schema, activeStep, handleNext, handleBack, last, setStepIndex } =
    useWizard<T>({
      steps,
      goToStep,
    });

  const stepsToPercent = (100 / steps.length) * (stepIndex + 1);

  useImperativeHandle(mRef, () => ({
    goBack: handleBack,
    goNext: handleNext,
  }));

  return (
    <FullSizePaper
      sx={{ backgroundColor: 'transparent', marginTop: 0, height: '100%', ...paperSx }}
    >
      <Container container direction="column">
        {steps.length > 1 && showProgress ? (
          <LinearProgressContainer>
            <LinearProgress variant="determinate" value={stepsToPercent} />
          </LinearProgressContainer>
        ) : (
          <></>
        )}

        {activeStep.header ? (
          <HeadingBox>
            {activeStep.header?.icon && <IconContainer>{activeStep.header.icon}</IconContainer>}
            {activeStep.header?.label && <Header>{activeStep.header.label}</Header>}
          </HeadingBox>
        ) : (
          <></>
        )}

        <Form<T>
          schema={schema}
          shouldUnregister={false}
          defaultValues={initialValues}
          {...formProps}
        >
          <ConnectForm<UnionToIntersection<T> & FieldValues>>
            {({ handleSubmit, formState: { isSubmitting }, getValues }) => {
              return (
                <>
                  <Grid container alignItems="center" justifyContent="center" marginBottom={3}>
                    <Grid item xs={12}>
                      <activeStep.FieldsComponent
                        {...activeStep.props}
                      ></activeStep.FieldsComponent>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    sx={{ marginTop: 'auto' }}
                  >
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="space-between" marginTop="auto">
                        {activeStep.actions.filter(({ align = 'left' }) => align === 'left')
                          .length ? (
                          <Stack
                            direction="row"
                            gap={4}
                            sx={{ flex: 1, justifyContent: 'flex-start' }}
                          >
                            {activeStep.actions
                              .filter(({ align = 'left' }) => align === 'left')
                              .map((action, idx) =>
                                action.splitOptions ? (
                                  <SubmitSplitButton<Merge<UnionToIntersection<T>>>
                                    disabled={action.disabled || isSubmitting}
                                    options={action.splitOptions.map((option) => ({
                                      label: option.label ?? '',
                                      onClick: (values) =>
                                        option.onClick?.({
                                          values,
                                          handleNext,
                                          setStepIndex,
                                          handleBack,
                                          last,
                                        }),
                                    }))}
                                    key={idx}
                                  />
                                ) : (
                                  <LoadingButton
                                    onClick={() =>
                                      action.skipValidation
                                        ? action.onClick?.({
                                            values: getValues(),
                                            handleNext,
                                            handleBack,
                                            setStepIndex,
                                            last,
                                          })
                                        : handleSubmit(
                                            (values) =>
                                              action.onClick?.({
                                                values,
                                                handleNext,
                                                handleBack,
                                                setStepIndex,
                                                last,
                                              })
                                          )()
                                    }
                                    variant={action.variant ?? 'outlined'}
                                    disabled={action.disabled || isSubmitting}
                                    loading={loading || isSubmitting || action.loading}
                                    fullWidth={action.fullWidth}
                                    sx={{ borderRadius: 6, minWidth: 120 }}
                                    key={idx}
                                  >
                                    {action.label}
                                  </LoadingButton>
                                )
                              )}
                          </Stack>
                        ) : (
                          <></>
                        )}

                        {activeStep.actions.filter(({ align = 'left' }) => align === 'right')
                          .length ? (
                          <Stack
                            direction="row"
                            gap={4}
                            sx={{ flex: 1, justifyContent: 'flex-end' }}
                          >
                            {activeStep.actions
                              .filter(({ align = 'left' }) => align === 'right')
                              .map((action, idx) =>
                                action.splitOptions ? (
                                  <SubmitSplitButton<Merge<UnionToIntersection<T>>>
                                    disabled={action.disabled || isSubmitting}
                                    options={action.splitOptions.map((option) => ({
                                      label: option.label ?? '',
                                      onClick: (values) =>
                                        option.onClick?.({
                                          values,
                                          handleNext,
                                          handleBack,
                                          setStepIndex,
                                          last,
                                        }),
                                    }))}
                                    key={idx}
                                  />
                                ) : (
                                  <LoadingButton
                                    type="submit"
                                    onClick={() =>
                                      action.skipValidation
                                        ? action.onClick?.({
                                            values: getValues(),
                                            handleNext,
                                            handleBack,
                                            setStepIndex,
                                            last,
                                          })
                                        : handleSubmit(
                                            (values) =>
                                              action.onClick?.({
                                                values,
                                                handleNext,
                                                handleBack,
                                                setStepIndex,
                                                last,
                                              })
                                          )()
                                    }
                                    variant={action.variant ?? 'contained'}
                                    disabled={action.disabled || isSubmitting}
                                    loading={loading || isSubmitting || action.loading}
                                    fullWidth={action.fullWidth}
                                    sx={{ borderRadius: 6, minWidth: 120 }}
                                    key={idx}
                                  >
                                    {action.label}
                                  </LoadingButton>
                                )
                              )}
                          </Stack>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  {children}
                </>
              );
            }}
          </ConnectForm>
        </Form>
      </Container>
    </FullSizePaper>
  );
}

export default forwardRef(WizardForm) as <T extends FieldValues>(
  props: WizardFormProps<T> & { ref?: React.ForwardedRef<WizardFormRef> }
) => ReturnType<typeof WizardForm>;
