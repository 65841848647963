import {
  LocalPhone,
  Mail as _Mail,
  Person as _Person,
  Pets as _Pets,
  DirectionsCar,
  Key,
  CurrencyExchange as _CurrencyExchange,
} from '@mui/icons-material';
import { styled, Typography } from '@mui/material';

export const Header = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: theme.typography.fontWeightMedium,
  textTransform: 'uppercase',
}));

export const Mail = styled(_Mail)(({ theme }) => ({
  height: theme.spacing(2),
  width: theme.spacing(2),
  color: theme.palette.primary.main,
}));

export const LeaseHolder = styled(Key)(({ theme }) => ({
  height: theme.spacing(2),
  width: theme.spacing(2),
  color: theme.palette.primary.main,
}));

export const CurrencyExchange = styled(_CurrencyExchange)(({ theme }) => ({
  height: theme.spacing(2),
  width: theme.spacing(2),
  color: theme.palette.primary.main,
}));

export const Phone = styled(LocalPhone)(({ theme }) => ({
  height: theme.spacing(2),
  width: theme.spacing(2),
  color: theme.palette.primary.main,
}));

export const Person = styled(_Person)(({ theme }) => ({
  height: theme.spacing(2),
  width: theme.spacing(2),
  color: theme.palette.primary.main,
}));

export const Pets = styled(_Pets)(({ theme }) => ({
  height: theme.spacing(2),
  width: theme.spacing(2),
  color: theme.palette.primary.main,
}));

export const Car = styled(DirectionsCar)(({ theme }) => ({
  height: theme.spacing(2),
  width: theme.spacing(2),
  color: theme.palette.primary.main,
}));
