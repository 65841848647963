/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantPropertyPresignedPhotosAnonymousAction } from './propertyPresignedPhotos';
import TenantPropertyPresignedPhotosAnonymousActionSchema from './propertyPresignedPhotos.json';

export const isTenantPropertyPresignedPhotosAnonymousAction = guard<TenantPropertyPresignedPhotosAnonymousAction>(TenantPropertyPresignedPhotosAnonymousActionSchema as any);