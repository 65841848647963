/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantProfileBankAccountChangedEvent } from './changed';
import TenantProfileBankAccountChangedEventSchema from './changed.json';

export const isTenantProfileBankAccountChangedEvent = guard<TenantProfileBankAccountChangedEvent>(TenantProfileBankAccountChangedEventSchema as any);