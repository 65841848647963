import { ImageList } from '@mui/material';
import { ImagePicker, ThumbnailImage } from 'components';
import { ensureArray, Image as ImageType } from 'system';

type PhotoListProps = {
  images?: ImageType[];
  baseUrl: string;
  pickImage?: (file: File | undefined) => void;
  localUploadedImages?: {
    key: string;
    uri: string;
  }[];
  onDelete?: (key: string) => void;
};

export default function PhotoList({
  images,
  baseUrl,
  pickImage,
  localUploadedImages,
  onDelete,
}: PhotoListProps) {
  return (
    <>
      <ImageList cols={4} rowHeight="auto">
        {ensureArray(images).map((image) => (
          <ThumbnailImage
            key={image.key}
            imageKey={image.key}
            baseUrl={baseUrl}
            onDelete={onDelete}
          />
        ))}

        {ensureArray(localUploadedImages).map((image) => (
          <ThumbnailImage
            uri={image.uri}
            imageKey={image.key}
            key={image.key}
            onDelete={onDelete}
          />
        ))}
      </ImageList>
      {pickImage && <ImagePicker inputId="photo-list-picker" pickImage={pickImage} />}
    </>
  );
}
