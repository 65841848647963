/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantAutopayEnrollmentActivatedEvent } from './autopayEnrollmentActivated';
import TenantAutopayEnrollmentActivatedEventSchema from './autopayEnrollmentActivated.json';

export const isTenantAutopayEnrollmentActivatedEvent = guard<TenantAutopayEnrollmentActivatedEvent>(TenantAutopayEnrollmentActivatedEventSchema as any);