import { IconButton, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from '@mui/icons-material';

export type TristateCellProps = {
  id: string;
  name?: string;
  onChange?: (args: { id: string; newState: Tristate }) => void;
  value: Tristate;
  disabled?: boolean;
};

export type Tristate = 'selected' | 'deselected' | 'unavailable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
      },
    },
    icon: {
      marginRight: theme.spacing(1.5),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(0),
      },
    },
    name: {
      textTransform: 'capitalize',
      fontSize: '1em',
      fontWeight: 500,
      [theme.breakpoints.down('md')]: {
        fontSize: '0.8em',
      },
    },
  })
);

export default function TristateCell({ id, name, onChange, disabled, value }: TristateCellProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <IconButton
        className={classes.icon}
        onClick={() =>
          onChange?.({ id, newState: value === 'selected' ? 'deselected' : 'selected' })
        }
        disabled={disabled || value === 'unavailable'}
        size="large"
      >
        {value === 'unavailable' ? (
          <IndeterminateCheckBox fontSize="small" />
        ) : value === 'selected' ? (
          <CheckBox fontSize="small" {...(!disabled && { color: 'primary' })} />
        ) : (
          <CheckBoxOutlineBlank fontSize="small" {...(!disabled && { color: 'primary' })} />
        )}
      </IconButton>
      {name && <Typography className={classes.name}>{name}</Typography>}
    </div>
  );
}
