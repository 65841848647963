import {
  IconButton,
  Theme,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Typography,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { MoreVert, MoreHoriz } from '@mui/icons-material';
import { MouseEvent, useState } from 'react';
import { Modal } from 'components';
import { useCancelRequest } from './hooks/useCancelRequest';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        width: 100,
        '&:hover': {
          background: 'rgba(159, 120, 250, 0.16)',
        },
      },
    },
    item: {
      fontSize: 14,
      fontWeight: 500,
    },
  })
);

export default function RequestMenu({ id, phone }: { id?: string; phone?: string }) {
  const { cancelRequest } = useCancelRequest({ id });
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <div className={classes.menu}>
      <IconButton
        aria-label="open-request-menu"
        onClick={(event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
      >
        {mdUp ? <MoreHoriz /> : <MoreVert />}
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {phone && (
          <MenuItem
            className={classes.item}
            component="a"
            href={`tel:${phone}`}
            onClick={() => {
              setAnchorEl(null);
            }}
          >
            Call property manager
          </MenuItem>
        )}
        <MenuItem
          className={classes.item}
          onClick={() => {
            setIsCancelModalOpen(true);
            setAnchorEl(null);
          }}
        >
          Cancel request
        </MenuItem>
      </Menu>
      <Modal
        onClose={() => setIsCancelModalOpen(false)}
        open={isCancelModalOpen}
        title="Are you sure you want to cancel this request?"
        actions={{
          cancel: {
            label: 'CLOSE',
            onClick: () => setIsCancelModalOpen(false),
          },
          confirm: {
            label: 'CANCEL REQUEST',
            onClick: cancelRequest,
            loading: false,
          },
        }}
      >
        <Typography>
          This is irreversable, you may resubmit your email request to create a new request.
        </Typography>
      </Modal>
    </div>
  );
}
