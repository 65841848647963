/* eslint-disable no-console */
import { ApolloClient, HttpLink, from } from '@apollo/client';
import { Auth } from '@aws-amplify/auth';
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { useNotification } from 'hooks';
import { useCallback, useMemo } from 'react';
import { cache } from './cache';
import cleanVariablesLink from './cleanVariablesLink';
import errorLink from './errorLink';

const region = String(process.env.REACT_APP_REGION);
const RESIDENT_URL = String(process.env.REACT_APP_APPSYNC_GRAPHQL);
const auth: AuthOptions = {
  type: 'AMAZON_COGNITO_USER_POOLS' as const,
  jwtToken: () => Auth.currentSession().then((session) => session.getIdToken().getJwtToken()),
};

const residentAuthLink = createAuthLink({ url: RESIDENT_URL, region, auth });
const residentHttpLink = createSubscriptionHandshakeLink(
  { url: RESIDENT_URL, region, auth },
  new HttpLink({ uri: RESIDENT_URL })
);

export const useAuthClient = ({
  user,
  signout,
  refresh,
}: {
  signout: VoidFunction;
  refresh: () => Promise<unknown>;
  user?: { idToken: string };
}) => {
  const { sendNotification } = useNotification();
  const notifier = useCallback(
    (message: Parameters<typeof sendNotification>[0]) => {
      console.error(message);
      sendNotification(message, 'error');
    },
    [sendNotification]
  );

  return useMemo(
    () =>
      new ApolloClient({
        link: from([
          errorLink({ refresh, signout, notifier }),
          cleanVariablesLink,
          residentAuthLink,
          residentHttpLink,
        ]),
        cache,
        credentials: 'include',
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refresh, signout, notifier, user]
  );
};
