import { TextFieldProps } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { FieldValues, Path, PathValue, useController, useFormContext } from 'react-hook-form';
import { parseDates } from 'system';

export default function DateFieldController<T extends FieldValues = Record<string, unknown>>({
  name,
  minDate,
  maxDate,
  disableFuture,
  className,
  disabled,
  label,
  defaultValue,
  format = 'yyyy-MM-dd',
  fullWidth = true,
  variant = 'filled',
  ...props
}: TextFieldProps &
  Partial<Parameters<typeof DatePicker>[0]> & {
    name: Path<T>;
    defaultValue?: PathValue<T, (string | undefined) & Path<T>>;
  }) {
  const { control, setValue, getValues } = useFormContext<T>();
  const { field, fieldState } = useController<T>({ name, control });

  useEffect(() => {
    if (defaultValue && !getValues(name)) {
      setValue(name, defaultValue, { shouldValidate: true });
    }
  });

  const [fieldValue, minDateTime, maxDateTime] = parseDates(field.value, minDate, maxDate);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DatePicker
        {...props}
        {...field}
        value={fieldValue.isValid ? fieldValue : field.value}
        format={format}
        {...{
          minDate: minDateTime.isValid ? minDateTime : undefined,
          maxDate: maxDateTime.isValid ? maxDateTime : undefined,
          disableFuture,
          disabled,
        }}
        slotProps={{
          textField: {
            type: 'text',
            fullWidth,
            error: Boolean(fieldState.error?.message),
            helperText: fieldState.error?.message,
            label,
            disabled,
            variant,
            className,
          },
        }}
        onChange={(date) => {
          field.onChange(date instanceof DateTime ? (date.isValid ? date.toISODate() : '') : date);
        }}
      />
    </LocalizationProvider>
  );
}
