import { Step, StepConnector, StepLabel, Stepper, Theme } from '@mui/material';
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import FlowStepIcon from './FlowStepIcon';

const CustomConnector = withStyles((theme: Theme) => ({
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: theme.palette.text.disabled,
    borderTopWidth: 2,
    borderRadius: 1,
  },
}))(StepConnector);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 420,
      margin: 'auto',
      padding: 0,
      paddingTop: theme.spacing(1),
    },
  })
);

export default function FlowStepper({ stepType }: { stepType: string }) {
  const classes = useStyles();
  const step = stepType === 'detail' ? 0 : stepType === 'schedule' ? 1 : 2;

  return (
    <Stepper
      activeStep={step}
      alternativeLabel
      connector={<CustomConnector />}
      className={classes.root}
    >
      <Step>
        <StepLabel StepIconComponent={FlowStepIcon} />
      </Step>
      <Step>
        <StepLabel StepIconComponent={FlowStepIcon} />
      </Step>
      <Step>
        <StepLabel StepIconComponent={FlowStepIcon} />
      </Step>
    </Stepper>
  );
}
