import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { ReactNode, useEffect } from 'react';
import { DeepPartial, FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import Lazy from 'yup/lib/Lazy';
import { ValidateOptions } from 'yup/lib/types';

export type FormProps<T extends FieldValues = Record<string, unknown>> = {
  children: ReactNode;
  schema?: AnyObjectSchema | Lazy<AnyObjectSchema>;
  defaultValues?: DeepPartial<T>;
  onSubmit?: SubmitHandler<T>;
  className?: string;
  shouldUnregister?: boolean;
  schemaOptions?: ValidateOptions<unknown>;
  resetOnDefaultValueChange?: boolean;
};

export default function Form<T extends FieldValues = Record<string, unknown>>({
  children,
  schema,
  defaultValues,
  onSubmit = () => null,
  shouldUnregister,
  schemaOptions,
  resetOnDefaultValueChange: resetOnDefaultChange,
  ...props
}: FormProps<T>) {
  const methods = useForm<T>({
    ...(schema && { resolver: yupResolver(schema, schemaOptions) }),
    mode: 'onChange',
    defaultValues,
    shouldUnregister,
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (resetOnDefaultChange && defaultValues) {
      reset(defaultValues);
    }
  }, [resetOnDefaultChange, defaultValues, reset]);

  useEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      console.warn(`Form errors`, methods.formState.errors);
    }
  }, [methods.formState.errors]);

  return (
    <FormProvider<T> {...methods}>
      <form
        autoComplete="off"
        onSubmit={(e) => {
          void handleSubmit(onSubmit)(e);
        }}
        {...props}
      >
        {children}
      </form>
    </FormProvider>
  );
}
