/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantResidentDocumentSentEvent } from './documentSent';
import TenantResidentDocumentSentEventSchema from './documentSent.json';

export const isTenantResidentDocumentSentEvent = guard<TenantResidentDocumentSentEvent>(TenantResidentDocumentSentEventSchema as any);