import { Box, BoxProps, Paper as MUIPaper, Stack, styled } from '@mui/material';
import Nav from 'apps/portal/Nav';
import { PropsWithChildren, useState } from 'react';

const Paper = styled(MUIPaper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  background: theme.palette.background.default,
}));

const Content = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: 0,
  paddingBottom: theme.spacing(12),
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    height: '100%',
  },
}));

export default function PaperFrame({ children, ...props }: PropsWithChildren<BoxProps>) {
  const [topNavHeight, setTopNavHeight] = useState(0);
  const [bottomNavHeight, setBottomNavHeight] = useState(0);

  return (
    <Paper elevation={0}>
      <Stack
        sx={{
          mt: `${topNavHeight}px`, // TODO: Make header not fixed
          height: {
            md: `calc(100% - ${topNavHeight}px)`,
            xs: `calc(100% - ${topNavHeight}px - ${bottomNavHeight}px)`,
          },
        }}
      >
        <Nav
          topNavRef={(node) => setTopNavHeight(node?.clientHeight ?? 0)}
          bottomNavRef={(node) => setBottomNavHeight(node?.clientHeight ?? 0)}
        />
        <Content {...props}>{children}</Content>
      </Stack>
    </Paper>
  );
}
