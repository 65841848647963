import { IncomeSource } from 'api';
import { maybe } from 'system';
import { z } from 'zod';

export type OtherIncomeField = z.infer<typeof otherIncomeWizardSchema>;

export const otherIncomeWizardSchema = z.object({
  id: maybe(z.string()),
  amount: z.number().int().positive(),
  source: z.nativeEnum(IncomeSource),
});
