import { ApolloLink } from '@apollo/client';
import { stripNonData } from 'system';

const cleanVariablesLink = new ApolloLink((operation, forward) => {
  operation.variables = stripNonData(operation.variables);

  return forward(operation);
});

export default cleanVariablesLink;
