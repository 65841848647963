import { Box, Theme, Tooltip as T, TooltipProps as TP } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      height: theme.spacing(5),
      lineHeight: '2.4em',
      maxWidth: 800,
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.background.default,
      fontWeight: 400,
      fontSize: 14,
    },
  })
);

type TooltipProps = {
  disabled?: boolean;
};

export function Tooltip({ title, classes, children, disabled, ...props }: TP & TooltipProps) {
  const tooltipClasses = useStyles();

  return (
    <T
      title={title ?? ''}
      classes={{ ...tooltipClasses, ...classes }}
      placement="top"
      disableFocusListener={disabled}
      disableHoverListener={disabled}
      disableTouchListener={disabled}
      {...props}
    >
      <Box display="flex" flexDirection="column">
        {children}
      </Box>
    </T>
  );
}
