import { Address } from 'api';
import gPhoneNumber from 'google-libphonenumber';
import { compact, isEmpty, isNil, isObject, map, mapValues, omitBy, reject } from 'lodash';
import { DateTime, Interval } from 'luxon';
import * as Yup from 'yup';
import { Provinces } from './types';
import { maxISODate } from './residency';
import equal from 'fast-deep-equal';

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

export const noOp = () => {
  /* no-op */
};

export const ifDifferent =
  <T = unknown>(newValue: T) =>
  (prev: T) =>
    equal(prev, newValue) ? prev : newValue;

export const prefixFilename = ({ filename, prefix }: { filename?: string; prefix: string }) => {
  const arr = filename?.split('/') ?? [];
  const file = arr.pop();
  return [arr.join('/'), prefix + '-' + file].join('/');
};

export const getFilename = (key?: string) => {
  const arr = key?.split('/') ?? [];
  return arr.pop();
};

export const insert = <T>(arr: T[], idx: number, newItem: T) => [
  ...arr.slice(0, idx),
  newItem,
  ...arr.slice(idx),
];

export const shallowEqual = (base: Record<string, unknown>, compare: Record<string, unknown>) =>
  Object.entries(base)
    .map(([k, v]) => v === compare[k])
    .reduce((a, c) => (a = a && c), true);

export const capitalize = (name?: string | null) =>
  name && name.charAt(0).toUpperCase() + name.slice(1).toLocaleLowerCase();

export const avatarInitials = (name = '') =>
  name
    .replace(/[^\w\s]/gi, '')
    .split(/[ ]+/)
    .filter((_w, i, arr) => i === 0 || i === arr.length - 1)
    .map((word) => word[0])
    .join('')
    .toUpperCase();

export const titleCase = (snakeCase: string) =>
  map(snakeCase.replaceAll('_', ' ').split(' '), capitalize).join(' ');

export const isNilOrEmptyObject = (
  item: unknown
): item is null | undefined | Record<string, never> =>
  isNil(item) || (typeof item === 'object' && !Array.isArray(item) && isEmpty(item));

export const stripNonData = <T = unknown>(
  item: T
): T extends null | undefined | Record<string, never> ? undefined : T => {
  return (
    isNilOrEmptyObject(item)
      ? undefined
      : Array.isArray(item)
        ? reject(item.map(stripNonData), isNilOrEmptyObject)
        : isObject(item)
          ? omitBy(
              mapValues(item, stripNonData),
              (value, key) => key === '__typename' || isNilOrEmptyObject(value)
            )
          : item
  ) as T extends null | undefined | Record<string, never> ? undefined : T;
};

type UnionToIntersectionHelper<U> = (U extends unknown ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;

export type UnionToIntersection<U> = boolean extends U
  ? UnionToIntersectionHelper<Exclude<U, boolean>> & boolean
  : UnionToIntersectionHelper<U>;

export const spreadIf = <P = unknown, T = unknown>(predicate: P, obj?: T) =>
  predicate ? (obj ? obj : predicate) : {};

export const spreadListIf = <P = unknown, T = unknown>(predicate: P, list?: T) =>
  predicate ? (list ? list : []) : [];

export const formatDate = (dateZ: string | Date | DateTime, format = DateTime.DATE_FULL) => {
  return dateZ instanceof DateTime
    ? dateZ.toLocaleString(format)
    : dateZ instanceof Date
      ? DateTime.fromJSDate(dateZ).toLocaleString(format)
      : DateTime.fromISO(dateZ).toLocaleString(format);
};

export const formatDateTime = (
  dateZ: string | Date,
  {
    dateFormat = DateTime.DATE_FULL,
    timeFormat = DateTime.TIME_SIMPLE,
  }: { dateFormat?: Intl.DateTimeFormatOptions; timeFormat?: Intl.DateTimeFormatOptions } = {}
) => {
  const dateTime = dateZ instanceof Date ? DateTime.fromJSDate(dateZ) : DateTime.fromISO(dateZ);
  return [dateFormat, timeFormat].map((f) => dateTime.toLocaleString(f)).join(' ');
};

export const formatPhone = (phone: string) => {
  return phone
    ? phoneUtil.format(
        phoneUtil.parseAndKeepRawInput(phone, 'CA'),
        gPhoneNumber.PhoneNumberFormat.NATIONAL
      )
    : '';
};

export const yupDateAsString = () =>
  Yup.string()
    .transform((_, v) => {
      if (!v) {
        return undefined;
      }
      return v instanceof DateTime
        ? v.toString().substring(0, 10)
        : typeof v === 'string'
          ? v === ''
            ? v
            : DateTime.fromISO(v).toString().substring(0, 10)
          : DateTime.fromJSDate(v as Date)
              .toString()
              .substring(0, 10);
    })
    .test('isValidDate', 'Invalid Date', (v, context) => {
      return v
        ? DateTime.fromISO(v ?? 'invalid').isValid
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          context.schema.spec.presence !== 'required';
    });

export const yupDateTimeAsString = () =>
  Yup.string()
    .transform((_, v) => {
      if (!v) {
        return undefined;
      }
      return v instanceof DateTime
        ? v.toString()
        : typeof v === 'string'
          ? v === ''
            ? v
            : DateTime.fromISO(v).toString()
          : DateTime.fromJSDate(v as Date).toString();
    })
    .test('isValidDate', 'Invalid Date', (v, context) => {
      return v
        ? DateTime.fromISO(v ?? 'invalid').isValid
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          context.schema.spec.presence !== 'required';
    });

export const yupTimeAsString = () =>
  Yup.string()
    .transform((_, v) => {
      if (!v) {
        return undefined;
      }
      return v instanceof Date
        ? v.toString().substring(11, 19)
        : typeof v === 'string'
          ? v === ''
            ? v
            : DateTime.fromISO(v).toString().substring(11, 19)
          : DateTime.fromJSDate(v as Date)
              .toString()
              .substring(11, 19);
    })
    .test('isValidTime', 'Invalid Time', (v, context) => {
      return v
        ? DateTime.fromISO(v ?? 'invalid').isValid
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          context.schema.spec.presence !== 'required';
    });

export const yupDateRangeAsString = () =>
  Yup.object({
    start: yupDateAsString(),
    end: yupDateAsString(),
  }).test(
    'isValidDateRange',
    'Invalid Date range',
    (v) => DateTime.fromISO(v.start ?? 'invalid') < DateTime.fromISO(v.end ?? 'invalid')
  );

export const parseDates = <TDates extends unknown[]>(...dates: TDates) =>
  dates.map((date) =>
    DateTime.isDateTime(date)
      ? date
      : date instanceof Date
        ? DateTime.fromJSDate(date)
        : typeof date === 'string'
          ? DateTime.fromISO(date)
          : DateTime.invalid('invalid date')
  );

const addressFormats: Record<AddressStyle, Array<keyof Address | (keyof Address)[]>> = {
  full: [['suite', 'street'], 'city', 'province', 'postal'],
  line1: [['suite', 'street']],
  line2: ['city', 'province', 'postal'],
};

type AddressStyle = 'full' | 'line1' | 'line2';

export const formatAddress = (
  address?: Address,
  { style = 'full', omit = [] }: { style?: AddressStyle; omit?: (keyof Address)[] } = {}
) =>
  compact(
    addressFormats[style].map((field) =>
      Array.isArray(field)
        ? compact(field.filter((f) => !omit.includes(f)).map((f) => address?.[f])).join(' ')
        : !omit.includes(field)
          ? address?.[field]
          : null
    )
  ).join(', ');

export const formatPercentage = (percent: number): string => {
  return percent.toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 2,
  });
};

export const safeRound = (arg: number) => Math.round(arg * 100) / 100;

export const formatCurrency = (
  rawAmount?: number,
  options?: {
    showDollarSign?: boolean;
    hideZeroCents?: boolean;
    negativeStyle?: 'default' | 'cr' | 'parens';
    hideZeroAmount?: boolean;
  }
): string => {
  const {
    showDollarSign = true,
    hideZeroCents = false,
    negativeStyle = 'default',
    hideZeroAmount = false,
  } = options ?? {};
  const amount = rawAmount ? (safeRound(rawAmount) === 0 ? 0 : safeRound(rawAmount)) : 0;

  const prefix = negativeStyle === 'cr' && amount < 0 ? 'CR' : '';
  const diplayedAmount = prefix && amount < 0 ? -amount : amount;

  const formattedAmount = Intl.NumberFormat('en-CA', {
    ...(showDollarSign ? { style: 'currency', currency: 'CAD' } : {}),
    ...(negativeStyle === 'parens' ? { currencySign: 'accounting' } : {}),
    ...(hideZeroCents && Math.floor(diplayedAmount) === diplayedAmount
      ? { maximumFractionDigits: 0 }
      : {}),
    ...(!showDollarSign ? { maximumFractionDigits: 2, minimumFractionDigits: 2 } : {}),
  }).format(diplayedAmount);

  const display = amount === 0 && hideZeroAmount ? '' : `${prefix}${formattedAmount}`;

  return display;
};

export const formatSimplePercentage = (percent: number): string => `${percent}%`;

export const hashCode = (inputs: string[]) =>
  inputs
    .join('#')
    .split('')
    .reduce((a, c) => (Math.imul(31, a) + c.charCodeAt(0)) | 0, 0);

export const downloadFromUrl = (url: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const ensureArray = <T = unknown[] | null | undefined>(arr: T) =>
  arr ?? ([] as unknown as NonNullable<T>);

export const download = (data: Blob, options?: { filename?: string }) => {
  const { filename = 'export.csv' } = { ...options };

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(data);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const skipProps = (...props: string[]) => ({
  shouldForwardProp: (prop: string) => !props.includes(prop),
});

export const yupImageFile = () =>
  Yup.mixed().test('fileType', 'File should be an image only', (file: File) =>
    ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(file.type)
  );

export const THUMBNAIL_SIZE_SQUARE = 69;

export const shallowOmit = <
  TRecord extends Record<string, unknown> | undefined,
  TKey extends keyof NonNullable<TRecord>,
>(
  obj: TRecord,
  keys: TKey[]
) => {
  const clone = { ...(obj ?? {}) } as NonNullable<TRecord>;
  for (const key of keys) {
    delete clone[key as string];
  }

  return clone as Omit<typeof clone, TKey>;
};

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export const getCondoLabels = (province: keyof typeof Provinces | string | undefined) =>
  province === Provinces.BC
    ? {
        condo: 'strata',
        unitFactor: 'unit entitlement',
      }
    : {
        condo: 'condo',
        unitFactor: 'unit factor',
      };

export const DEFAULT_GST = 0.05;

export const safeSum = (...args: (number | number[])[]) =>
  args.flat().reduce((a, c) => safeRound(a + c), 0);

export const parseJSON = (jsonString = '{}'): Record<string | number | symbol, unknown> => {
  try {
    return JSON.parse(jsonString) as Record<string | number | symbol, unknown>;
  } catch (e) {
    return {};
  }
};

export const getCurrentTenantName = (
  tenants: { name?: string; leaseHolder?: boolean }[] | undefined,
  maxDisplayed = 2
) => {
  if (!tenants || tenants.length === 0) {
    return null;
  }

  const leaseHolders = tenants.filter((tenant) => tenant.leaseHolder);
  const displayed = (leaseHolders.length > 0 ? leaseHolders : tenants).slice(0, maxDisplayed);
  const more = leaseHolders.length > displayed.length ? ' (...)' : '';

  const name = displayed
    .filter((tenant) => tenant.name)
    .map((tenant) => tenant.name)
    .reduce((prev, curr) => (prev === '' ? curr : `${prev} & ${curr}`), '');

  return `${name} ${more}`;
};

export const isAfter = (startZ: string) => (effect: { startZ: string; endZ?: string }) =>
  effect.endZ
    ? Interval.fromISO(`${effect.startZ}/${effect.endZ}`).isAfter(DateTime.fromISO(startZ))
    : DateTime.fromISO(effect.startZ) > DateTime.fromISO(startZ);

export const isNever = (dateZ?: string) =>
  !dateZ || DateTime.fromISO(dateZ).hasSame(DateTime.fromISO(maxISODate), 'day');

export const sameMonthAsToday = (date?: string) => {
  const endDate = DateTime.fromISO(date ?? '');
  const now = DateTime.local();
  return endDate.hasSame(now, 'month');
};

export const invalidate = (_: unknown, { DELETE }: { DELETE: unknown }) => DELETE;

export const formatNth = (n: number) => {
  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ]);
  const rules = new Intl.PluralRules('en-US', { type: 'ordinal' });
  return `${n}${suffixes.get(rules.select(n))}`;
};
