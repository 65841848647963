/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '../../src/util';
import { SystemAutopaySettlementFileAction } from './settlementFileAction';
import SystemAutopaySettlementFileActionSchema from './settlementFileAction.json';

export const isSystemAutopaySettlementFileAction = guard<SystemAutopaySettlementFileAction>(SystemAutopaySettlementFileActionSchema as any);