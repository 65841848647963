import { Paper, Theme, Typography, Grid } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import * as Icons from '@mui/icons-material';
import { CustomIcons } from 'components';
import { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      borderRadius: 4,
      textAlign: 'center',
      width: '100%',
    },
    icon: {
      width: 78,
      height: 81,
      marginBottom: theme.spacing(3),
      color: theme.palette.primary.main,
    },
    title: {
      fontSize: 18,
      marginBottom: theme.spacing(2),
    },
    text: {
      fontSize: 13,
    },
  })
);

type HeroPaperProps = {
  customIcon?: keyof typeof CustomIcons;
  materialIcon?: keyof typeof Icons;
  title?: string;
  text?: string | ReactNode;
};

export default function HeroPaper({ customIcon, materialIcon, title, text }: HeroPaperProps) {
  const classes = useStyles();
  const Icon = customIcon
    ? CustomIcons[customIcon]
    : materialIcon
    ? Icons[materialIcon]
    : undefined;

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid>
        {Icon && <Icon className={classes.icon} />}
        {title && <Typography className={classes.title}>{title}</Typography>}
        {text && <Typography className={classes.text}>{text}</Typography>}
      </Grid>
    </Paper>
  );
}
