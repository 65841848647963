import { Interval } from 'api';
import { DateTime, Duration, Interval as LuxonInterval } from 'luxon';
import { useEffect, useState } from 'react';
import { FieldValues, UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';

const MAX_DAYS = 3;
const MIN_LEAD_HOURS = 4;

type SlotGridColumn = {
  date: DateTime;
  slots: Interval[];
};

const intervalFromISO = (isoInterval: string) => {
  const luxonInterval = LuxonInterval.fromISO(isoInterval);
  return { start: luxonInterval.start.toISO(), end: luxonInterval.end.toISO() };
};

export const useAvailabilityWindows = ({
  minutes,
  availabilityWindows,
  appointmentPreferences,
}: {
  minutes?: number;
  availabilityWindows?: string[];
  appointmentPreferences?: Interval[];
}) => {
  const [slots, setSlots] = useState<Interval[]>([]);
  const [grid, setGrid] = useState<SlotGridColumn[]>([]);

  useEffect(() => {
    if (minutes && availabilityWindows) {
      const dailySlots = availabilityWindows
        .map((iso) => LuxonInterval.fromISO(iso))
        .flatMap((luxonInterval) => luxonInterval.splitBy(Duration.fromObject({ minutes })))
        .reduce<Record<string, LuxonInterval[]>>(
          (p, c) => ({
            ...p,
            ...{
              [c.start.startOf('day').toISO()]: [...(p[c.start.startOf('day').toISO()] || []), c],
            },
          }),
          {}
        );

      setSlots(
        Object.entries(dailySlots)
          .map(([, slots]) =>
            slots.filter(
              (slot, index) =>
                index === 0 || // first
                (index > 0 && index === slots.length - 1) || // last
                (slots.length > 2 && index === Math.floor(slots.length / 2)) // middle
            )
          )
          .flat()
          .map((luxonInterval) => ({
            start: luxonInterval.start.toISO(),
            end: luxonInterval.end.toISO(),
          }))
      );
    }
  }, [minutes, availabilityWindows]);

  useEffect(() => {
    setGrid(
      slots
        .map((slot) => DateTime.fromISO(slot.start).startOf('day').toISO())
        .filter(
          (isoDate, index, arr) =>
            DateTime.fromISO(isoDate) > DateTime.now().plus({ hours: MIN_LEAD_HOURS }) &&
            arr.indexOf(isoDate) === index
        )
        .map((isoDate) => ({
          date: DateTime.fromISO(isoDate),
          slots: slots
            .filter((slot) =>
              DateTime.fromISO(isoDate).equals(DateTime.fromISO(slot.start).startOf('day'))
            )
            .sort((a, b) => a.start.localeCompare(b.start)),
        }))
        .sort((a, b) => a.date.toISO().localeCompare(b.date.toISO()))
        .slice(0, MAX_DAYS)
    );
  }, [slots]);

  const findPreferenceIndex = (isoInterval: string) => {
    const interval = intervalFromISO(isoInterval);
    const preferences = appointmentPreferences ?? [];

    return preferences.findIndex(
      (v) =>
        DateTime.fromISO(v.start).equals(DateTime.fromISO(interval.start)) &&
        DateTime.fromISO(v.end).equals(DateTime.fromISO(interval.end))
    );
  };

  const handleClick = (
    isoInterval: string,
    append: UseFieldArrayAppend<FieldValues, 'appointmentPreferences'>,
    remove: UseFieldArrayRemove
  ) => {
    const index = findPreferenceIndex(isoInterval);
    if (index < 0) {
      append(intervalFromISO(isoInterval));
    } else {
      remove(index);
    }
  };

  return {
    grid,
    findPreferenceIndex,
    handleClick,
  };
};
