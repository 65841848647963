import { IconButton, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export default function DeleteIconButton({
  loading,
  onClick,
}: {
  loading?: boolean;
  onClick: () => void;
}) {
  return (
    <IconButton aria-label="delete" color="error" onClick={onClick} disabled={loading}>
      {loading ? <CircularProgress size={14} /> : <DeleteIcon fontSize="small" />}
    </IconButton>
  );
}
