import { Stack } from '@mui/material';
import { TextFieldController } from 'components';
import { WizardStep, WizardStepProps } from '../../WizardStep';
import { Header } from '../../WizardForm/WizardForm.styles';
import { locationFieldSchema } from '../schema';

export function LocationStep(props: WizardStepProps<typeof locationFieldSchema>) {
  return (
    <WizardStep
      stepperVariant="linear"
      spacing={0}
      title={<Header>What is their location?</Header>}
      schema={locationFieldSchema}
      {...props}
    >
      <Stack gap={1} sx={{ my: 2 }}>
        <TextFieldController label="City and Province" name="location" variant="standard" />
      </Stack>
    </WizardStep>
  );
}
