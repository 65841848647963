import { SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { RequestFieldsFragment } from 'api';
import Footer from '../Footer';
import FlowStepper from './FlowStepper';
import RequestMenu from './RequestMenu';
import BackButton from '../BackButton';
import { Container, Content, EmptyMenuBox, Header, HeaderContent } from './FlowFrame.style';
import { spreadIf } from 'system';

export default function FlowFrame({
  children,
  request,
  handleGoBack,
  stepType,
  showBackButton,
  containerSx,
}: {
  children: ReactNode;
  request?: RequestFieldsFragment;
  handleGoBack: () => void;
  stepType: string;
  showBackButton: boolean;
  containerSx?: SxProps;
}) {
  return (
    <Container {...spreadIf(containerSx, { sx: containerSx })}>
      <Header container justifyContent="space-between">
        <BackButton show={showBackButton} onBackClick={handleGoBack} />
        <HeaderContent item>
          <Typography fontSize="15" fontWeight="500">
            Issue
          </Typography>
          <FlowStepper stepType={stepType} />
        </HeaderContent>
        {request ? (
          <RequestMenu
            id={request.id}
            phone={request.unit?.property?.phone ?? request.unit?.property?.account?.phone}
          />
        ) : (
          <EmptyMenuBox />
        )}
      </Header>
      <Content>{children}</Content>
      <Footer />
    </Container>
  );
}
