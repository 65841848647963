/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantEnrollmentClearableIncludedEvent } from './enrollmentClearableIncluded';
import TenantEnrollmentClearableIncludedEventSchema from './enrollmentClearableIncluded.json';

export const isTenantEnrollmentClearableIncludedEvent = guard<TenantEnrollmentClearableIncludedEvent>(TenantEnrollmentClearableIncludedEventSchema as any);