import { Button, ButtonProps, CircularProgress, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import clsx from 'clsx';

export type LoadingButtonProps = ButtonProps & {
  loading?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontWeight: 500,
      fontSize: 14,
      textTransform: 'none',
    },
  })
);

export default function LoadingButton({
  loading = false,
  children,
  disabled,
  className,
  ...props
}: LoadingButtonProps) {
  const classes = useStyles();

  return (
    <Button
      {...props}
      disabled={disabled || loading}
      disableElevation
      className={clsx(classes.root, className)}
    >
      {loading ? <CircularProgress size={24} /> : children}
    </Button>
  );
}
