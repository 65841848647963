import ZodForm from 'components/FormController/ZodForm';
import { useFormContext } from 'react-hook-form';
import { Wizard } from 'react-use-wizard';
import { OtherIncomeField, otherIncomeWizardSchema } from './schema';
import AdditionalIncomeStep from './Steps/AdditionalIncomeStep';

import { compact } from 'lodash';
import { Box } from '@mui/material';

const LocalWizard = ({
  onCancel,
  onFinish,
}: {
  onCancel: VoidFunction;
  onFinish: (values?: OtherIncomeField) => void;
}) => {
  const { handleSubmit } = useFormContext<OtherIncomeField>();

  const steps = compact([
    <AdditionalIncomeStep
      onCancel={onCancel}
      finishLabel="Save"
      onFinish={handleSubmit(onFinish)}
    />,
  ]);

  return (
    <Wizard>
      {steps.map((step, index) => (
        <Box key={index}>{step}</Box>
      ))}
    </Wizard>
  );
};

export const OtherIncomeWizard = ({
  defaultValues,
  onCancel,
  onFinish,
  loading,
}: {
  defaultValues?: OtherIncomeField;
  onCancel: VoidFunction;
  onFinish: (values?: OtherIncomeField) => void;
  loading?: boolean;
}) => {
  return loading ? (
    <></>
  ) : (
    <ZodForm defaultValues={defaultValues} schema={otherIncomeWizardSchema}>
      <LocalWizard {...{ onCancel, onFinish }} />
    </ZodForm>
  );
};
