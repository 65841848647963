import { SvgIcon, SvgIconProps } from '@mui/material';
import { useTheme } from 'context';
import { colours } from 'system';

export default function Envelope({ ...props }: SvgIconProps) {
  const { darkMode } = useTheme();
  return (
    <SvgIcon viewBox="0 0 84 88" {...props}>
      <path
        d="M74.4977 87.9994H2.85068C1.33032 87.9994 0 86.6574 0 85.1236V42.5615C0 41.6029 0.570136 40.6443 1.52036 40.0692C1.71041 40.0692 1.71041 39.8774 1.90045 39.8774L13.8733 32.0169C15.2036 31.0583 16.914 31.4417 17.8643 32.7838C18.8145 34.1258 18.4344 35.8513 17.1041 36.8099L8.36199 42.5615L38.7692 61.9254L69.3665 42.7533L60.6244 37.0016C59.2941 36.043 58.914 34.3175 59.8643 32.9755C60.8145 31.6334 62.5249 31.25 63.8552 32.2086L76.0181 40.2609C76.7783 40.836 77.3484 41.6029 77.3484 42.7533V85.1236C77.3484 85.8905 76.9683 86.6574 76.5882 87.2326C76.0181 87.616 75.2579 87.9994 74.4977 87.9994ZM5.70136 82.2478H71.6471V47.9297L40.0995 67.677C39.1493 68.2522 38.009 68.2522 37.0588 67.677L5.70136 47.738V82.2478Z"
        fill={darkMode ? colours.whiteSmoke : colours.black}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.1786 47.2889C30.8449 47.698 31.6369 47.9309 32.4981 47.9309H36.6791V47.9312H36.6795V51.7656H32.4986C32.4016 51.7656 32.3052 51.7639 32.2091 51.7605C27.7854 51.6104 24.3262 48.025 24.3262 43.5214C24.3262 38.92 27.937 35.2773 32.4981 35.2773H36.6791V39.1118H32.4981C30.0275 39.1118 28.1271 41.029 28.1271 43.5214C28.1271 43.5214 28.1271 43.5215 28.1271 43.5216H28.1275C28.1275 45.145 28.9337 46.5243 30.1786 47.2889ZM42.9506 41.6044H42.951V45.4388L34.399 45.4388V45.4386H34.3986V41.6041H42.9506V41.6044ZM45.1398 35.2825C49.5638 35.4322 53.0234 39.0177 53.0234 43.5216C53.0234 48.1229 49.4126 51.7656 44.8515 51.7656L40.6705 51.7656V47.9312L44.8515 47.9312C47.3221 47.9312 49.2225 46.014 49.2225 43.5216C49.2225 43.5215 49.2225 43.5214 49.2225 43.5214H49.2221C49.2221 41.898 48.4159 40.5186 47.171 39.7541C46.5047 39.345 45.7127 39.112 44.8515 39.112L40.6705 39.112V39.1118H40.6701V35.2773H44.8511C44.9477 35.2773 45.044 35.2791 45.1398 35.2825Z"
        fill={darkMode ? colours.lightSlateBlue : colours.purpleHeart}
      />
      <path
        d="M63.8551 46.2043L58.1538 49.7689V31.2734H40.0995V13.2516H21.8552C20.3348 13.2516 19.1945 14.402 19.1945 15.9357V37.4085H13.4932V15.9357C13.4932 11.3344 17.2941 7.5 21.8552 7.5H45.4208L63.8551 26.097V46.2043ZM45.9909 25.5218H55.4932L45.9909 15.9357V25.5218ZM19.1945 49.5L13.4932 46V35.6002H19.1945V49.5Z"
        fill={colours.lightSlateBlue}
      />
      <path
        d="M75.0669 18.0218L77.9176 11.8867L83.999 9.01089L77.9176 6.13508L75.0669 0L72.4063 6.13508L66.1348 9.01089L72.4063 11.8867L75.0669 18.0218Z"
        fill="#24D1AD"
      />
      <path
        d="M57.5834 13.9952L59.1037 10.5442L62.5246 9.01045L59.1037 7.47669L57.5834 3.83398L55.873 7.47669L52.4521 9.01045L55.873 10.5442L57.5834 13.9952Z"
        fill="#24D1AD"
      />
      <path
        d="M75.0681 21.8564L73.5478 25.3074L70.127 26.8412L73.5478 28.5667L75.0681 32.0177L76.7785 28.5667L80.1994 26.8412L76.7785 25.3074L75.0681 21.8564Z"
        fill="#24D1AD"
      />
    </SvgIcon>
  );
}
