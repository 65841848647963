import { StyledEngineProvider, Theme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from 'context';
import { Navigation } from 'navigation';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router } from 'react-router-dom';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function App() {
  return (
    <Router>
      <StyledEngineProvider injectFirst>
        <ThemeProvider>
          <CssBaseline />
          <SnackbarProvider>
            <Navigation />
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Router>
  );
}

export default App;
