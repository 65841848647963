import { Stack } from '@mui/material';
import { DateFieldController } from 'components';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';
import { parseDates, spreadIf } from 'system';
import { WizardStep, WizardStepProps } from '../../WizardStep';
import { Header } from '../../WizardForm/WizardForm.styles';
import { DurationField, durationSchema } from '../schema';
import { useWizard } from 'react-use-wizard';
import { GetFieldIndexProp } from '../WorkHistoryWizardForm';

export function DurationStep({ onFinish }: WizardStepProps & GetFieldIndexProp) {
  const { watch, handleSubmit } = useFormContext<DurationField>();
  const { isLastStep } = useWizard();
  const [start, end] = watch(['startDate', 'endDate']);
  const [startDate, endDate] = parseDates(start, end);

  return (
    <WizardStep
      stepperVariant="linear"
      spacing={0}
      title={<Header>How long did you work there?</Header>}
      schema={durationSchema}
      onFinish={isLastStep ? () => onFinish && handleSubmit(onFinish)() : undefined}
      finishLabel={isLastStep ? 'Submit' : undefined}
    >
      <Stack direction="row" spacing={4} sx={{ my: 2 }}>
        <DateFieldController
          label="Start date"
          name="startDate"
          variant="standard"
          views={['year', 'month']}
          format="MMMM, yyyy"
          {...(endDate.isValid && {
            maxDate: endDate.plus({ days: -1 }),
          })}
        />
        <DateFieldController
          label="End date"
          name="endDate"
          variant="standard"
          {...spreadIf(startDate.isValid, { minDate: startDate.plus({ days: 1 }) })}
          maxDate={DateTime.now()}
          views={['year', 'month']}
          format="MMMM, yyyy"
        />
      </Stack>
    </WizardStep>
  );
}
