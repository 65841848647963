import { useEffect } from 'react';
import { toNumber } from 'lodash';
import { ButtonGroup, Button, Input, InputProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Add, Remove } from '@mui/icons-material';

export default function NumberSpinnerController({
  name,
  disabledUp = false,
  disabledDown = false,
  onChange,
  minValue = 0,
  maxValue,
  stepValue = 1,
  onDecrease,
  ...props
}: Omit<InputProps, 'ref' | 'defaultValue'> & {
  name: string;
  disabledUp?: boolean;
  disabledDown?: boolean;
  minValue?: number;
  maxValue?: number;
  stepValue?: number;
  onDecrease?: (value: number) => void;
}) {
  const { control, setValue, getValues } = useFormContext();

  useEffect(() => {
    const value = getValues(name);
    !value && setValue(name, minValue);
  }, [minValue, name, setValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button
              variant="contained"
              color="primary"
              disabled={disabledDown}
              onClick={() => {
                (field.value ?? 0) > minValue && setValue(name, (field.value ?? 0) - stepValue);
                onDecrease?.(field.value > 1 ? field.value - 1 : 1);
              }}
            >
              <Remove />
            </Button>
            <Button
              variant="outlined"
              disabled
              sx={[
                { border: 'none', color: 'text.primary' },
                { '&:disabled': { color: 'text.primary' } },
              ]}
            >
              {field.value}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={disabledUp}
              onClick={() => {
                (field.value ?? 0) < (maxValue ?? Infinity) &&
                  setValue(name, (field.value ?? 0) + stepValue);
              }}
            >
              <Add />
            </Button>
          </ButtonGroup>
          <Input
            {...props}
            {...field}
            value={field.value ?? ''}
            onChange={(e) => {
              field.onChange(e.target.value ? toNumber(e.target.value) : e);
              onChange?.(e);
            }}
            type="hidden"
            error={Boolean(fieldState.error?.message)}
          />
        </>
      )}
    />
  );
}
