/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantAnnouncementRepliedEvent } from './replied';
import TenantAnnouncementRepliedEventSchema from './replied.json';

export const isTenantAnnouncementRepliedEvent = guard<TenantAnnouncementRepliedEvent>(TenantAnnouncementRepliedEventSchema as any);