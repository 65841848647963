import { SvgIcon, SvgIconProps } from '@mui/material';
import { useTheme } from 'context';
import { colours } from 'system';

export default function Envelope({ ...props }: SvgIconProps) {
  const { darkMode } = useTheme();
  return (
    <SvgIcon viewBox="0 0 78 81" {...props}>
      <path
        d="M74.4977 80.9994H2.85068C1.33032 80.9994 0 79.6574 0 78.1236V35.5615C0 34.6029 0.570136 33.6443 1.52036 33.0692C1.71041 33.0692 1.71041 32.8774 1.90045 32.8774L13.8733 25.0169C15.2036 24.0583 16.914 24.4417 17.8643 25.7838C18.8145 27.1258 18.4344 28.8513 17.1041 29.8099L8.36199 35.5615L38.7692 54.9254L69.3665 35.7533L60.6244 30.0016C59.2941 29.043 58.914 27.3175 59.8643 25.9755C60.8145 24.6334 62.5249 24.25 63.8552 25.2086L76.0181 33.2609C76.7783 33.836 77.3484 34.6029 77.3484 35.7533V78.1236C77.3484 78.8905 76.9683 79.6574 76.5882 80.2326C76.0181 80.616 75.2579 80.9994 74.4977 80.9994ZM5.70136 75.2478H71.6471V40.9297L40.0995 60.677C39.1493 61.2522 38.009 61.2522 37.0588 60.677L5.70136 40.738V75.2478Z"
        fill={darkMode ? colours.whiteSmoke : colours.black}
      />
      <path
        d="M63.8551 39.2043L58.1538 42.7689V24.2734H40.0995V6.25163H21.8552C20.3348 6.25163 19.1945 7.40196 19.1945 8.93573V30.4085H13.4932V8.93573C13.4932 4.33442 17.2941 0.5 21.8552 0.5H45.4208L63.8551 19.097V39.2043ZM45.9909 18.5218H55.4932L45.9909 8.93573V18.5218ZM19.1945 42.5L13.4932 39V28.6002H19.1945V42.5Z"
        fill={darkMode ? colours.lightSlateBlue : colours.purpleHeart}
      />
      <rect x="25" y="18" width="9" height="6" fill="#24D1AD" />
      <rect x="25" y="29" width="28" height="6" fill="#24D1AD" />
      <rect x="25" y="40" width="20" height="6" fill="#24D1AD" />
    </SvgIcon>
  );
}
