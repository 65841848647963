import { CircularProgress, Grid, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      alignSelf: 'center',
    },
  })
);

export default function FullScreenSpinner({ size }: { size?: number }) {
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.root}>
      <CircularProgress size={size ?? (mdUp ? 240 : 120)} />
    </Grid>
  );
}
