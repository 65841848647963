import { FC, lazy } from 'react';
import NotFound from '../pages/notfound';

type PageRoute = {
  page: FC;
  path: string;
};

export const routes: PageRoute[] = [
  {
    path: '/request/*',
    page: lazy(() => import('../apps/request')),
  },
  {
    path: '/*',
    page: lazy(() => import('../apps/portal')),
  },
  {
    path: '/not-found',
    page: NotFound,
  },
];
