import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Theme,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ReactElement } from 'react';

export type CheckboxFieldProps = CheckboxProps & {
  label?: string | ReactElement;
  helperText?: string;
  fullWidth?: boolean;
  error?: { message?: string };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: 13,
      color: theme.palette.text.primary,
    },
  })
);

export function CheckboxField({
  label,
  color,
  disabled,
  className,
  helperText,
  fullWidth,
  ...checkboxProps
}: CheckboxFieldProps) {
  const classes = useStyles();
  return (
    <FormControl fullWidth={fullWidth}>
      <FormControlLabel
        control={<Checkbox {...checkboxProps} color={color ?? 'primary'} />}
        label={label ?? ''}
        disabled={disabled}
        className={clsx(className)}
        classes={{ label: classes.label }}
      />

      {checkboxProps.error?.message ? (
        <FormHelperText error>{checkboxProps.error.message}</FormHelperText>
      ) : (
        <>{helperText ? <FormHelperText>{helperText}</FormHelperText> : <></>}</>
      )}
    </FormControl>
  );
}
