import { Box, Grid, Typography } from '@mui/material';
import Lottie from 'react-lottie';

export default function LoadingBox({
  animationData,
  message = 'Loading...',
  height = 500,
}: {
  animationData: unknown;
  message?: string;
  height?: number;
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ height }}>
      <Box>
        <Lottie options={defaultOptions} height={150} width={150} />
        <Typography variant="subtitle1" align="center">
          {message}
        </Typography>
      </Box>
    </Grid>
  );
}
