/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '../../src/util';
import { SystemFlinksResponseEvent } from './response';
import SystemFlinksResponseEventSchema from './response.json';

export const isSystemFlinksResponseEvent = guard<SystemFlinksResponseEvent>(SystemFlinksResponseEventSchema as any);