import { Grid as _Grid, Skeleton, styled, Typography, TypographyProps } from '@mui/material';

const Grid = styled(_Grid)(() => ({
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  lineHeight: 2,
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightMedium,
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  textAlign: 'left',
  marginBottom: theme.spacing(1.5),
  fontWeight: theme.typography.fontWeightMedium,
}));

type AttributeTextProps = TypographyProps & {
  title: string;
  loading?: boolean;
};

export default function AttributeText({ title, children, loading }: AttributeTextProps) {
  return (
    <Grid container>
      <Title color="textSecondary">{title}</Title>
      <Text>{loading ? <Skeleton sx={{ minWidth: '120px' }} /> : children}</Text>
    </Grid>
  );
}
