/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '../../src/util';
import { SystemAutopayCollectionZipAction } from './collectionZipAction';
import SystemAutopayCollectionZipActionSchema from './collectionZipAction.json';

export const isSystemAutopayCollectionZipAction = guard<SystemAutopayCollectionZipAction>(SystemAutopayCollectionZipActionSchema as any);