/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '../../src/util';
import { SystemAutopaySettlementZipAction } from './settlementZipAction';
import SystemAutopaySettlementZipActionSchema from './settlementZipAction.json';

export const isSystemAutopaySettlementZipAction = guard<SystemAutopaySettlementZipAction>(SystemAutopaySettlementZipActionSchema as any);