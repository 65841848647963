import { Apartment, AttachMoney, Build, Campaign, Flag, FolderCopy } from '@mui/icons-material';
import { ReactNode, useMemo } from 'react';
import { useProfileContext } from './context';

type NavItem = {
  to: string;
  label: string;
  icon?: ReactNode;
  hidden?: boolean;
};

export const useNavItems = () => {
  const {
    loading,
    hasAssets,
    hasMaintenance,
    hasCampaigns,
    hasResidencies,
    hasRequests,
    hasBillingEnabled,
    hasDocuments,
    hasAnnouncements,
  } = useProfileContext();

  const navItems: NavItem[] = useMemo(() => {
    return [
      {
        to: '/listings',
        label: 'Listings',
        icon: <Flag />,
        hidden: !hasCampaigns,
      },
      {
        to: '/maintenance',
        label: 'Maintenance',
        icon: <Build />,
        hidden: !hasResidencies && !hasMaintenance && !hasRequests,
      },
      {
        to: '/assets',
        label: 'Assets',
        icon: <Apartment />,
        hidden: !hasAssets,
      },
      {
        to: '/lease',
        label: 'Lease',
        icon: <Apartment />,
        hidden: !hasResidencies,
      },
      {
        to: '/announcements',
        label: 'Announcements',
        icon: <Campaign />,
        hidden: !hasAnnouncements,
      },
      {
        to: '/documents',
        label: 'Documents',
        icon: <FolderCopy />,
        hidden: !hasDocuments,
      },
      {
        to: '/billing',
        label: 'Billing',
        icon: <AttachMoney />,
        hidden: !hasBillingEnabled,
      },
    ];
  }, [hasCampaigns, hasResidencies, hasAssets, hasRequests, hasBillingEnabled, hasDocuments]);

  return {
    loading,
    navItems,
  };
};
