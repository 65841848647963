import { PopperPlacementType, SxProps, Theme } from '@mui/material';
import { SplitButton } from 'components';
import { FieldValues, UseFormReset, useFormContext } from 'react-hook-form';
import { spreadIf } from 'system';

export default function SubmitSplitButton<T extends FieldValues>({
  options,
  className,
  disabled,
  dropDownPlacement = 'bottom-end',
  sx,
}: {
  options: {
    label: string;
    onClick: (
      values: T,
      reset: UseFormReset<T>
    ) => Record<string, unknown> | Promise<Record<string, unknown>> | void | Promise<void>;
  }[];
  className?: string;
  disabled?: boolean;
  dropDownPlacement?: PopperPlacementType;
  sx?: SxProps<Theme>;
}) {
  const {
    formState: { isSubmitting },
    reset,
    handleSubmit,
  } = useFormContext<T>();
  return (
    <SplitButton
      className={className}
      {...spreadIf(sx, { sx })}
      disabled={disabled}
      {...(dropDownPlacement ? { dropDownPlacement } : {})}
      options={options.map((option) => ({
        ...option,
        loading: isSubmitting,
        onClick: () =>
          handleSubmit((values) => {
            option.onClick(values as T, reset);
          })(),
      }))}
    />
  );
}
