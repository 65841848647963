import { Stack } from '@mui/material';
import { TextFieldController } from 'components';
import { string, object, SchemaOf } from 'yup';

export type NameField = {
  name: string;
};

export const nameFieldSchema = object({
  name: string().required('Name is required'),
}) as SchemaOf<NameField>;

export function NameStep() {
  return (
    <Stack gap={1} sx={{ my: 2 }}>
      <TextFieldController label="Name" name="name" variant="standard" />
    </Stack>
  );
}
