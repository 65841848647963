import { CloudDownload, Delete } from '@mui/icons-material';
import { Box, IconButton, ListItemButton, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { get } from 'lodash';
import { DocumentCell } from './DocumentCell';
import { ListItem } from './Documents.styles';
import { Document, DocumentRowModel, KeyAction } from './types';

export default function DocumentListItem<T extends Record<string, unknown>>({
  document,
  isLastItem,
  downloadFn,
  deleteFn,
  extraColumns,
}: {
  document: DocumentRowModel<T>;
  isLastItem?: boolean;
  downloadFn?: (document: Document<T>) => void;
  deleteFn?: KeyAction;
  extraColumns?: GridColDef<DocumentRowModel<T>>[];
}) {
  const { name, key: docKey, size, subtext } = document;
  return (
    <ListItem isLast={isLastItem} direction="row">
      <ListItemButton onClick={() => (document?.downloadFn ?? downloadFn)?.(document)}>
        <Box>
          <DocumentCell {...{ name, docKey, size }} subtext={subtext as string} />
          {extraColumns?.map((c, i) => (
            <Stack key={i} sx={{ mt: !i ? 2 : 1 }} title={c.field}>
              <Typography color="textSecondary" fontSize="small">
                {c.headerName}
              </Typography>
              <Typography variant="body2">{get(document, c.field)}</Typography>
            </Stack>
          ))}
        </Box>
      </ListItemButton>
      <Stack direction="row" spacing={1}>
        <IconButton size="small" onClick={() => (document?.downloadFn ?? downloadFn)?.(document)}>
          <CloudDownload />
        </IconButton>
        {deleteFn && (
          <IconButton size="small" onClick={() => deleteFn(document?.key ?? '')}>
            <Delete />
          </IconButton>
        )}
      </Stack>
    </ListItem>
  );
}
