import { HeroPaper, SimpleFrame } from 'components';

export default function NotFound() {
  return (
    <SimpleFrame title="Page not found">
      <HeroPaper
        customIcon={'Oops'}
        title="This page or link was not found"
        text="Check that the URL was entered correctly, or go back to the previous page."
      />
    </SimpleFrame>
  );
}
