/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantApplicationPreSubmittedEvent } from './applicationPresubmitted';
import TenantApplicationPreSubmittedEventSchema from './applicationPresubmitted.json';

export const isTenantApplicationPreSubmittedEvent = guard<TenantApplicationPreSubmittedEvent>(TenantApplicationPreSubmittedEventSchema as any);