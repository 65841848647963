import { Badge, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import plur from 'plur';

export type BadgeNumberCellProps = {
  badgeNumber: number;
  text?: string;
  showZero?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(1.6),
    },
    badge: {
      backgroundColor: theme.palette.primaryBackground.main,
      padding: theme.spacing(1),
    },
    text: {
      fontSize: '1em',
      marginLeft: theme.spacing(2.5),
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.9em',
      },
    },
  })
);

export default function BadgeNumberCell({
  badgeNumber,
  text = '',
  showZero = false,
}: BadgeNumberCellProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Badge badgeContent={badgeNumber} classes={{ badge: classes.badge }} showZero={showZero} />
      {text && (showZero || badgeNumber > 0) && (
        <Typography className={classes.text}>{plur(text, badgeNumber)}</Typography>
      )}
    </div>
  );
}
