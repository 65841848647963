import { TextFieldProps } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AutocompleteField, AutocompleteFieldProps } from '../Fields';

type AutocompleteFieldControllerProps = AutocompleteFieldProps & {
  reset?: number;
  name: string;
  onValueChange?: (id: string, optionalId?: string) => void;
  onInputChange?: (_: unknown, query: string, reason: string) => void;
  manualSort?: boolean;
};

export default function AutocompleteFieldController({
  name,
  disabled,
  onValueChange,
  groupBy,
  defaultValue,
  ...props
}: Omit<
  TextFieldProps,
  'onBlur' | 'onChange' | 'onFocus' | 'defaultValue' | 'autoComplete' | 'error'
> &
  AutocompleteFieldControllerProps) {
  const { control, setValue, watch } = useFormContext();
  const value = watch(name);

  useEffect(() => {
    if (!value && defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, value, name, setValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState }) => (
        <AutocompleteField
          {...{
            ...props,
            disabled,
            groupBy,
            value,
          }}
          error={fieldState.error}
          onChange={(_, newValue) => {
            if (typeof newValue === 'string') {
              setValue(name, newValue);
              return;
            }

            setValue(name, newValue?.id ?? '', { shouldValidate: true });
            onValueChange?.(newValue?.id ?? '');
          }}
        />
      )}
    />
  );
}
