import { Controller, Path, useFormContext } from 'react-hook-form';
import { GroupCheckboxField, GroupCheckboxFieldProps } from '../Fields/GroupCheckboxField';

export default function CheckboxFieldController<T>({
  name,
  disabled,
  ...props
}: Omit<GroupCheckboxFieldProps, 'name' | 'value'> & { name: Path<T> }) {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <GroupCheckboxField
          name={field.name}
          value={field.value as string[]}
          disabled={disabled || isSubmitting}
          error={fieldState?.error}
          {...props}
        />
      )}
    />
  );
}
