import { Stack } from '@mui/material';
import { DateFieldController } from 'components';
import { DateTime } from 'luxon';
import { yupDateAsString } from 'system';
import { object, SchemaOf } from 'yup';

export type DateOfBirthField = {
  dob?: string;
};

export const dateOfBirthSchema = object({
  dob: yupDateAsString().required('Date of Birth is required'),
}) as SchemaOf<DateOfBirthField>;

export function DoBStep() {
  return (
    <Stack gap={1} sx={{ my: 2 }}>
      <DateFieldController
        label="Date of Birth"
        name="dob"
        variant="standard"
        maxDate={DateTime.now()}
      />
    </Stack>
  );
}
