import { Link, Stack, StackProps } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import { Link as RouterLink, useMatch, useResolvedPath } from 'react-router-dom';
import AccountMenu from './AccountMenu';
import { LinkText, NavIcon } from './Nav.styles';
import { useNavItems } from './useNavItems';

const NavItem = ({
  to,
  icon,
  children,
  orientation = 'horizontal',
}: PropsWithChildren<{
  to: string;
  icon?: ReactNode;
  orientation?: 'vertical' | 'horizontal';
}>) => {
  const resolved = useResolvedPath(to);
  const selected = Boolean(useMatch({ path: resolved.pathname, end: true }));

  return (
    <Link component={RouterLink} {...{ to, selected }} sx={{ textDecoration: 'none' }}>
      <Stack
        alignItems="center"
        alignContent="center"
        direction={orientation === 'horizontal' ? 'row' : 'column'}
        spacing={orientation === 'horizontal' ? 2 : 0}
      >
        {icon && <NavIcon selected={selected}>{icon}</NavIcon>}
        <LinkText selected={selected}>{children}</LinkText>
      </Stack>
    </Link>
  );
};

export default function TopNav({
  orientation = 'vertical',
  ...props
}: { orientation?: 'vertical' | 'horizontal' } & StackProps) {
  const { navItems } = useNavItems();
  return (
    <Stack {...props} direction={orientation === 'vertical' ? 'row' : 'column'} spacing={4}>
      {navItems
        .filter(({ hidden }) => !hidden)
        .map(({ label, ...props }) => (
          <NavItem key={props.to} {...props} orientation={orientation}>
            {label}
          </NavItem>
        ))}
      <AccountMenu />
    </Stack>
  );
}
