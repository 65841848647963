import { toNumber } from 'lodash';
import { DateTime } from 'luxon';

type ObjectWithName = { name: string };
type ObjectWithDate = { date: DateTime };
type SortableKeys<T> = {
  [k in keyof T]: T[k] extends string | number ? k : never;
}[keyof T] extends never
  ? never
  : keyof T;

export const sortByName = (a: ObjectWithName, b: ObjectWithName) => a.name.localeCompare(b.name);

export const newestToOldest = ({ date: a }: ObjectWithDate, { date: b }: ObjectWithDate) =>
  a < b ? 1 : a > b ? -1 : 0;

export const sortByAttribute = <T>(attribute: SortableKeys<T>, dir: 'asc' | 'desc' = 'asc') => {
  return (...[a, b]: Parameters<typeof sorter>) => (dir === 'asc' ? sorter(a, b) : sorter(b, a));
  function sorter(a?: Partial<T> | undefined, b?: Partial<T> | undefined) {
    const a_ = a?.[attribute] ?? '';
    const b_ = b?.[attribute] ?? '';

    if (typeof a_ === 'string' && typeof b_ === 'string') {
      if (!Number.isNaN(toNumber(a_)) && !Number.isNaN(toNumber(b_))) {
        return toNumber(a_) - toNumber(b_);
      }

      return a_.localeCompare(b_);
    }

    if (typeof a_ === 'number' && typeof b_ === 'number') {
      return a_ - b_;
    }

    return 0;
  }
};
