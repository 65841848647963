import { Paper as MUIPaper, PaperProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const Paper = styled(MUIPaper)(({ theme }) => ({
  borderRadius: 4,
  width: '100%',
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

export default function FullSizePaper({ children, ...props }: PaperProps) {
  return (
    <Paper {...props} elevation={0}>
      {children}
    </Paper>
  );
}
