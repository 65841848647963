import { CloudUpload } from '@mui/icons-material';
import { Chip, Grid, GridProps, styled, Typography } from '@mui/material';
import plur from 'plur';
import { useDropzone } from 'react-dropzone';

type DropzoneFieldProps = {
  filesToUpload: File[];
  maxFileSizeMB?: number;
  onDrop: (f: File[]) => void;
  onRemove: (f: File) => void;
  maxFiles?: number;
  multiple?: boolean;
};

const DropzoneArea = styled(Grid)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 16,
  borderColor: theme.palette.secondary.main,
  borderStyle: 'dashed',
  backgroundColor: theme.palette.background.paper,
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
}));

export const DropZoneField = ({
  onDrop,
  onRemove,
  filesToUpload,
  maxFileSizeMB = 10,
  maxFiles,
  multiple,
}: DropzoneFieldProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    maxFiles,
    maxSize: maxFileSizeMB * 1024 * 1024,
  });

  return (
    <Grid>
      <DropzoneArea item xs={12} {...(getRootProps() as GridProps)}>
        <input {...getInputProps()} />
        <CloudUpload color="secondary" sx={{ height: 48, width: 48 }} />
        <Typography variant="h5">Upload {plur('Document', maxFiles ?? 2)}</Typography>
        <Typography variant="subtitle1">
          Drag and drop your {plur('document', maxFiles ?? 2)} or click here to select a file.
        </Typography>
        <Typography variant="caption">Maximum file upload size: {maxFileSizeMB} MB</Typography>
        <Grid container direction="row" alignItems="center" justifyContent="center">
          {filesToUpload.length > 0 &&
            filesToUpload.map((file) => (
              <Grid item key={file.name}>
                <Chip label={file.name} onDelete={() => onRemove(file)} sx={{ m: 1 }} />
              </Grid>
            ))}
        </Grid>
      </DropzoneArea>
    </Grid>
  );
};
