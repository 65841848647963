import { ReactElement } from 'react';
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
  Theme,
} from '@mui/material';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { makeStyles, createStyles } from '@mui/styles';

export type GroupCheckboxFieldProps = CheckboxProps & {
  label?: string | ReactElement;
  helperText?: string;
  fullWidth?: boolean;
  options: { value: string; label: string | ReactElement }[];
  error?: { message?: string };
  name: string;
  value: string[];
  handleClick?: (value: string, checked: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: 13,
      color: theme.palette.text.primary,
    },
  })
);

export function GroupCheckboxField({
  label,
  className,
  helperText,
  fullWidth,
  options,
  handleClick,
  error,
  ...checkboxProps
}: GroupCheckboxFieldProps) {
  const classes = useStyles();
  const { setValue } = useFormContext();

  const { name, value } = checkboxProps;
  return (
    <FormControl fullWidth={fullWidth}>
      {label && <Typography>{label}</Typography>}
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={`option-${option.value}`}
            control={
              <Checkbox
                color="primary"
                size="small"
                onChange={(event, checked: boolean) => {
                  if (checked) {
                    setValue(name, [...(value ?? []), option.value]);
                  } else {
                    setValue(
                      name,
                      (value ?? []).filter((val) => val !== option.value)
                    );
                  }
                  handleClick && handleClick(option.value, checked);
                }}
                checked={(value ?? []).some((val) => val === option.value)}
                {...checkboxProps}
              />
            }
            label={option.label}
            className={clsx(className)}
            classes={{ label: classes.label }}
          />
        ))}
      </FormGroup>

      {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
      {error?.message && <FormHelperText error>{error?.message}</FormHelperText>}
    </FormControl>
  );
}
