/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantProfileConsentsChangedEvent } from './consentsChanged';
import TenantProfileConsentsChangedEventSchema from './consentsChanged.json';

export const isTenantProfileConsentsChangedEvent = guard<TenantProfileConsentsChangedEvent>(TenantProfileConsentsChangedEventSchema as any);