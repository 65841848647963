import { useState } from 'react';
import { ChevronLeft, Delete } from '@mui/icons-material';
import { Box, ImageListItem } from '@mui/material';
import { prefixFilename } from 'system';
import { useGetS3Image } from 'hooks';
import {
  CancelIconButton,
  DeleteIconButton,
  Image,
  PreviewModal,
  Thumbnail,
} from './ThumbnailImage.styles';

type Props = {
  imageKey?: string;
  baseUrl?: string;
  uri?: string;
  onDelete?: (key: string) => void;
};

export default function ThumbnailImage({ baseUrl, imageKey, uri, onDelete }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const thumbnailKey = `${baseUrl}/${prefixFilename({
    filename: imageKey,
    prefix: 'thumbnail',
  })}`;

  const optimizedKey = `${baseUrl}/${prefixFilename({
    filename: imageKey,
    prefix: 'optimized',
  })}`;

  const s3Thumbnail = useGetS3Image(uri ? '' : thumbnailKey);
  const s3Optimized = useGetS3Image(uri ? '' : optimizedKey);

  const source = {
    thumbnail: {
      uri: uri ?? s3Thumbnail,
    },
    optimized: {
      uri: uri ?? s3Optimized,
    },
  };

  if (!source.thumbnail.uri) return <></>;

  return (
    <>
      <ImageListItem>
        <Thumbnail
          alt="Tenant uploaded"
          onClick={() => setIsModalOpen(true)}
          src={source.thumbnail.uri}
        />
      </ImageListItem>
      <PreviewModal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <>
          <CancelIconButton onClick={() => setIsModalOpen(false)}>
            <ChevronLeft fontSize="large" />
          </CancelIconButton>

          {onDelete && (
            <DeleteIconButton onClick={() => onDelete(imageKey ?? '')}>
              <Delete color="error" fontSize="large" />
            </DeleteIconButton>
          )}
          <Box>
            <Image alt="Tenant uploaded" src={source.optimized.uri} />
          </Box>
        </>
      </PreviewModal>
    </>
  );
}
