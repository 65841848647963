import { ApolloError } from '@apollo/client';
import { useAllErrors } from './useErrorNotification';
import { map } from 'lodash';

export const useMeta = (
  ...args: { loading?: boolean; done?: boolean; error?: ApolloError | string | null }[]
) => {
  useAllErrors(...map(args, 'error'));
  const loading = map(args, 'loading').some(Boolean);

  const working =
    args.every((arg) => arg.done === undefined) ||
    args.some((arg) => !arg.loading && arg.done === false);

  return { loading, working };
};
