import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';

export type RadioFieldProps = RadioGroupProps & {
  label?: string;
  helperText?: string;
  options: { value: string; label: string }[];
  value?: string;
  disabled?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: 16,
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(2),
    },
    radio: {
      fontSize: 13,
    },
  })
);

export function RadioField({
  label,
  className,
  options,
  disabled,
  ...RadioFieldProps
}: RadioFieldProps) {
  const classes = useStyles();
  return (
    <FormControl fullWidth variant="filled" className={clsx(className)}>
      <FormControl>
        {label && <Typography className={classes.label}>{label}</Typography>}
        <RadioGroup color="primary" {...RadioFieldProps}>
          {options.map((option) => (
            <FormControlLabel
              key={`option-${option.value}`}
              classes={{ label: classes.radio }}
              value={option.value}
              control={<Radio color="primary" size="small" disabled={disabled} />}
              label={option.label}
              checked={option.value === RadioFieldProps.value}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </FormControl>
  );
}
