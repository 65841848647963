import { Button, Grid, Theme, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import clsx from 'clsx';
import { useFieldArray, Controller, useFormContext } from 'react-hook-form';
import { DateTime, Interval as LuxonInterval } from 'luxon';
import { capitalize, colours } from 'system';
import { useAvailabilityWindows } from '../hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panel: {
      background: theme.palette.background.paper,
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    title: {
      fontSize: 14,
      marginBottom: theme.spacing(1),
    },
    subtitle: {
      fontSize: 13,
      marginBottom: theme.spacing(3),
    },
    timezone: {
      fontSize: 13,
      marginTop: theme.spacing(1),
    },
    date: {
      fontSize: 13,
    },
    day: {
      fontWeight: 500,
      fontSize: 14,
      marginBottom: theme.spacing(1),
    },
    slotBtn: {
      borderRadius: 3,
      border: `1px solid ${colours.silver}`,
      marginBottom: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.primaryBackground.main,
      },
    },
    selectedSlotBtn: {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primaryBackground.main,
    },
  })
);

export function Schedule({
  minutes,
  availabilityWindows,
  timezone,
}: {
  minutes?: number;
  availabilityWindows?: string[];
  timezone?: string;
}) {
  const classes = useStyles();

  const { getValues } = useFormContext();
  const { appointmentPreferences } = getValues();
  const { append, remove } = useFieldArray({
    name: 'appointmentPreferences',
  });

  const { handleClick, findPreferenceIndex, grid } = useAvailabilityWindows({
    minutes,
    availabilityWindows,
    appointmentPreferences,
  });

  return (
    <>
      <Typography className={classes.title}>
        Which times would suit you best for us to come by?
      </Typography>
      <div className={classes.panel}>
        <Typography color="textSecondary" className={classes.subtitle}>
          Select preferred times
        </Typography>
        <Controller
          name="appointmentPreferences"
          render={() => (
            <Grid container justifyContent="space-between">
              {grid.map((day) => (
                <Grid item key={day.date.toISO()}>
                  <Typography color="textSecondary" className={classes.date}>
                    {day.date.toLocaleString({ month: 'short', day: 'numeric' })}
                  </Typography>
                  <Typography className={classes.day}>
                    {capitalize(
                      day.date.minus({ day: 1 }).equals(DateTime.local().startOf('day'))
                        ? day.date.toRelativeCalendar()
                        : day.date.toLocaleString({ weekday: 'long' })
                    )}
                  </Typography>
                  {day.slots.map((slot) => (
                    <Grid container key={slot.start}>
                      <Button
                        fullWidth
                        onClick={() => handleClick(`${slot.start}/${slot.end}`, append, remove)}
                        className={clsx(classes.slotBtn, {
                          [classes.selectedSlotBtn]: !(
                            findPreferenceIndex(`${slot.start}/${slot.end}`) < 0
                          ),
                        })}
                      >
                        {LuxonInterval.fromISO(`${slot.start}/${slot.end}`).start.toLocaleString(
                          DateTime.TIME_SIMPLE
                        )}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Grid>
          )}
        />
        {timezone && (
          <Typography color="textSecondary" className={classes.timezone}>
            Times are displayed in {DateTime.now().setZone(timezone).toFormat('ZZZZ')}.
          </Typography>
        )}
      </div>
    </>
  );
}
