import { Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import BackButton from './BackButton';
import Footer from './Footer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      textAlign: 'center',
      background: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    headerContent: {
      flexGrow: 1,
      padding: theme.spacing(1.5),
      marginRight: 64,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2),
        marginRight: 100,
      },
    },
    title: {
      fontSize: 15,
      fontWeight: 500,
    },
    back: {
      padding: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2.5),
      },
    },
    content: {
      maxWidth: 420,
      width: '100%',
      margin: 'auto',
      flexGrow: 1,
      textAlign: 'center',
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
      },
    },
  })
);

export default function SimpleFrame({
  children,
  title,
  showBackButton = false,
}: {
  children: ReactNode;
  title: string | ReactNode;
  showBackButton?: boolean;
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Grid className={classes.container}>
      <Grid container justifyContent="space-between" alignItems="center" className={classes.header}>
        <BackButton
          show={showBackButton}
          className={classes.back}
          onBackClick={() => navigate(-1)}
        />
        <Grid item className={classes.headerContent}>
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
      </Grid>
      <Grid className={classes.content}>{children}</Grid>
      <Footer />
    </Grid>
  );
}
