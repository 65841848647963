import { ApolloError } from '@apollo/client';
import { useNotification } from 'hooks';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useErrorNotification = (error?: ApolloError | string | null) => {
  const { sendNotification } = useNotification();

  useEffect(() => {
    const message = typeof error === 'string' ? error : error?.message;
    if (message) sendNotification(message, 'error');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (error) console.error('API error', error);
  }, [error]);
};

export const useStringErrorNotification = (): [
  Dispatch<SetStateAction<string | undefined | null>>,
] => {
  const [stringError, setStringError] = useState<string | undefined | null>(undefined);
  useErrorNotification(stringError);
  return [setStringError];
};

export const useAllErrors = (...errors: Parameters<typeof useErrorNotification>[0][]) =>
  errors.map(useErrorNotification);
