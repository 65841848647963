import { CampaignApplicationRulesFieldFragment, Fraction } from 'api';
import { compact, groupBy } from 'lodash';
import plur from 'plur';
import { ensureArray, safeRound } from 'system';
import { z } from 'zod';

const safeParseJSON = (value: string | null): unknown => {
  try {
    return value ? JSON.parse(value) : undefined;
  } catch (e) {
    console.warn(`Failed to parse JSON: ${String(value)}`);
    return undefined;
  }
};

export const storeFor = <TOut = unknown, TDef extends z.ZodTypeDef = z.ZodAnyDef, TIn = TOut>(
  keys: string[],
  {
    prefix = '',
    shape = z.unknown() as unknown as z.ZodType<TOut, TDef, TIn>,
  }: { prefix?: string; shape?: z.ZodType<TOut, TDef, TIn> } = {}
) => {
  const cacheKey = [prefix, keys.join('--')].join('-');

  return {
    get(): z.infer<typeof shape> | undefined {
      const result = shape.safeParse(safeParseJSON(localStorage.getItem(cacheKey)));

      if (result?.success) {
        return result.data as unknown as z.infer<typeof shape>;
      }

      console.warn(`Failed to parse stored data for ${cacheKey}: ${result?.error?.message}`);
      return undefined;
    },
    set(value: z.infer<typeof shape>) {
      localStorage.setItem(cacheKey, JSON.stringify(value));
    },
    remove() {
      localStorage.removeItem(cacheKey);
    },
    isEmpty() {
      return !localStorage.getItem(cacheKey);
    },
  };
};

export const applicationStoreFor = ({ profileId = '', campaignId = '', listedUnitId = '' }) =>
  storeFor([profileId, campaignId, listedUnitId], {
    prefix: 'application',
    shape: z
      .object({
        moveInDate: z
          .string()
          .nullish()
          .transform((x) => x ?? undefined),
        additionalInformation: z
          .string()
          .nullish()
          .transform((x) => x ?? undefined),
      })
      .nullish()
      .transform((x) => x ?? undefined),
  });

export const documentsStoreFor = ({ profileId = '', campaignId = '', listedUnitId = '' }) =>
  storeFor([profileId, campaignId, listedUnitId], {
    prefix: 'docs',
    shape: z
      .array(
        z.object({
          key: z.string(),
          name: z
            .string()
            .nullish()
            .transform((x) => x ?? undefined),
          size: z
            .number()
            .nullish()
            .transform((x) => x ?? undefined),
          typename: z
            .string()
            .nullish()
            .transform((x) => x ?? undefined),
        })
      )
      .nullish()
      .transform((arr) => compact(arr)),
  });
export const findMissingRequiredDocs = ({
  requiredDocs,
  uploadedDocs,
}: {
  uploadedDocs: { name?: string; typename?: string }[];
  requiredDocs: CampaignApplicationRulesFieldFragment['requiredDocs'];
}) => {
  const groupedDocs = groupBy(uploadedDocs, 'typename');

  return ensureArray(requiredDocs).reduce<string[]>((missingDocuments, { quantity, docTypes }) => {
    const uploadedCount = docTypes.reduce(
      (count, docType) => count + (groupedDocs[docType]?.length ?? 0),
      0
    );
    if (uploadedCount < quantity) {
      missingDocuments.push(
        `Missing ${quantity - uploadedCount} ${plur(
          'document',
          quantity - uploadedCount
        )} from ${docTypes.join(', ')}`
      );
    }
    return missingDocuments;
  }, []);
};

export const fractionToPercentage = (fraction: Fraction) => {
  const percentage = safeRound((fraction.numerator / fraction.denominator) * 100);
  return `${percentage}%`;
};
