import { Grid, Theme, Typography, TypographyProps } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    title: {
      fontSize: 13,
      lineHeight: 2,
    },
    text: {
      fontSize: 16,
      textAlign: 'left',
      marginBottom: theme.spacing(1.5),
    },
  })
);

type AttributeTextProps = TypographyProps & {
  title: string;
};

export default function AttributeText({ title, children }: AttributeTextProps) {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Typography color="textSecondary" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.text}>{children}</Typography>
    </Grid>
  );
}
