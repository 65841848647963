/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantLeaseExecutedEvent } from './leaseExecuted';
import TenantLeaseExecutedEventSchema from './leaseExecuted.json';

export const isTenantLeaseExecutedEvent = guard<TenantLeaseExecutedEvent>(TenantLeaseExecutedEventSchema as any);