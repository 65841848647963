import {
  FieldMergeFunction,
  FieldReadFunction,
  InMemoryCache,
  Reference,
  StoreObject,
  TypePolicy,
} from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { keyBy, uniqBy } from 'lodash';

const listConnection = (): TypePolicy => ({
  keyFields: false,
  fields: {
    items: {
      merge(
        existing: Reference[] = [],
        incoming: Reference[] = [],
        { readField }: { readField: ReadFieldFunction }
      ) {
        return uniqBy([...existing, ...incoming], (item?: StoreObject | Reference) =>
          readField<string>('id', item)
        );
      },
    },
    read(existing: Reference[]) {
      return existing;
    },
  },
});

type Connection = { items?: { id: string }[]; nextToken?: string };

const mergeItemsWith =
  <T>(getKey: (item: T) => string) =>
  (existing: T[] = [], incoming: T[] = []) =>
    Object.values({
      ...keyBy(existing, getKey),
      ...keyBy(incoming, getKey),
    });

// I don't know why this is necessary but without it `connectionMerge` doesn't seem to work
const connectionRead: FieldReadFunction<Connection> = (existing) => existing;
const connectionMerge: (fields?: string[]) => FieldMergeFunction<Connection> =
  (fields = ['id']) =>
  (existing, incoming, { readField }) => {
    const asKey = (item: NonNullable<Connection['items']>[0]) =>
      fields.map((field) => readField(field, item)).join();

    const items = mergeItemsWith(asKey)(existing?.items, incoming?.items);

    return {
      items,
      nextToken: incoming.nextToken,
    };
  };

export const cache = new InMemoryCache({
  typePolicies: {
    Profile: {
      keyFields: ['id'],
      fields: {
        listInboxMessages: {
          keyArgs: ['filter'],
          read: connectionRead,
          merge: connectionMerge(),
        },
        insurancePolicies: {
          keyArgs: ['filter'],
        },
      },
    },
    Request: { keyFields: ['id', 'name'] },
    Inbox: { keyFields: ['id'] },
    InboxMessageConnection: listConnection(),
    ProfileRequestConnection: listConnection(),
  },
});
