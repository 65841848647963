import { LocalPhone, Person as _Person, LocationOn, Mail as _Mail } from '@mui/icons-material';
import { styled, Typography, Stack } from '@mui/material';

export const Phone = styled(LocalPhone)(({ theme }) => ({
  height: theme.spacing(2),
  width: theme.spacing(2),
  color: theme.palette.primary.main,
}));

export const Person = styled(_Person)(({ theme }) => ({
  height: theme.spacing(2),
  width: theme.spacing(2),
  color: theme.palette.primary.main,
}));

export const Location = styled(LocationOn)(({ theme }) => ({
  height: theme.spacing(2),
  width: theme.spacing(2),
  color: theme.palette.primary.main,
}));

export const Mail = styled(_Mail)(({ theme }) => ({
  height: theme.spacing(2),
  width: theme.spacing(2),
  color: theme.palette.primary.main,
}));

export const Header = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: theme.typography.fontWeightMedium,
  textTransform: 'uppercase',
}));

export const Item = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(1),
  alignItems: 'center',
}));
