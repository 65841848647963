import { useNotification } from 'hooks';
import { useEffect } from 'react';

export const useSuccessNotification = (success: boolean, message: string) => {
  const { sendNotification } = useNotification();

  useEffect(() => {
    if (success) sendNotification(message, 'success');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);
};
