import { BusinessCenter } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem, Select, Stack } from '@mui/material';
import { orderBy } from 'lodash';
import { useState } from 'react';

export default function AccountDropdown<
  TAccount extends { accountId: string; accountName: string }
>({
  accounts,
  selected,
  onAccountSelected,
}: {
  accounts: TAccount[];
  selected?: TAccount;
  onAccountSelected: (account?: TAccount) => void;
}) {
  const sortedAccounts = orderBy(accounts, 'text');
  const [selectedAccount, setSelectedAccount] = useState(
    selected?.accountId ?? accounts?.[0].accountId
  );
  return (
    <Select
      labelId="select-account"
      id="select-account"
      value={selectedAccount}
      size="small"
      sx={{ width: { xs: '100%', sm: 'auto' }, minWidth: 200 }}
      renderValue={(localSelected) => {
        const account = accounts.find((a) => a.accountId === localSelected);
        return (
          <Stack direction="row" gap={1} alignItems="center">
            <BusinessCenter fontSize="small" color="primary" />
            <ListItemText primary={account?.accountName} />
          </Stack>
        );
      }}
      onChange={(e) => {
        setSelectedAccount(e.target.value);
        onAccountSelected(accounts.find((a) => a.accountId === e.target.value));
      }}
    >
      {sortedAccounts.map((account) => (
        <MenuItem key={account.accountId} value={account.accountId}>
          <ListItemIcon>
            <BusinessCenter fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText primary={account.accountName} />
        </MenuItem>
      ))}
    </Select>
  );
}
