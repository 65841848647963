import { MouseEvent, useState } from 'react';

export const useAnchorEl = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const clearAnchorEl = () => setAnchorEl(null);
  const clearAnchorElThen = (fn: () => unknown) => async () => {
    setAnchorEl(null);
    await fn();
  };

  const handleAnchorElClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return { anchorEl, setAnchorEl, clearAnchorEl, clearAnchorElThen, handleAnchorElClick };
};
