/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantClassifyPropertyDisabledError } from './propertyDisabledError';
import TenantClassifyPropertyDisabledErrorSchema from './propertyDisabledError.json';

export const isTenantClassifyPropertyDisabledError = guard<TenantClassifyPropertyDisabledError>(TenantClassifyPropertyDisabledErrorSchema as any);