import { Font } from '@react-pdf/renderer';

export const registerPdfFonts = () => {
  Font.register({
    family: 'Poppins',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf',
      },
      {
        src: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf',
        fontWeight: 'medium',
      },
      {
        src: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf',
        fontWeight: 'bold',
      },
    ],
  });

  Font.register({
    family: 'Work Sans',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/s/worksans/v17/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNigDp6_cOyA.ttf',
      },
      {
        src: 'https://fonts.gstatic.com/s/worksans/v17/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K3vXNigDp6_cOyA.ttf',
        fontWeight: 'medium',
      },
      {
        src: 'https://fonts.gstatic.com/s/worksans/v17/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K5fQNigDp6_cOyA.ttf',
        fontWeight: 'bold',
      },
    ],
  });
};
