import { zodResolver } from '@hookform/resolvers/zod';
import { isEmpty } from 'lodash';
import { PropsWithChildren, useEffect } from 'react';
import { DeepPartial, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

type SubmitHandler<TFieldValues extends FieldValues, TResult = unknown> = (
  data: TFieldValues,
  event?: React.BaseSyntheticEvent
) => TResult | Promise<TResult>;

export type ZodFormProps<Z extends z.ZodTypeAny, TResult> = PropsWithChildren<{
  schema?: Z;
  defaultValues?: DeepPartial<z.infer<Z>>;
  onSubmit?: SubmitHandler<z.infer<Z>, TResult | null>;
  afterSubmit?: (arg: TResult | null) => void | Promise<void>;
  shouldUnregister?: boolean;
  resetOnDefaultValueChange?: boolean;
  resetOnSubmit?: boolean;
}>;

export default function ZodForm<Z extends z.ZodTypeAny, TResult = unknown>({
  children,
  schema,
  defaultValues,
  onSubmit = () => null,
  afterSubmit,
  shouldUnregister,
  resetOnDefaultValueChange: resetOnDefaultChange,
  resetOnSubmit,
  ...props
}: ZodFormProps<Z, TResult>) {
  const methods = useForm<z.infer<Z>>({
    ...(schema && { resolver: zodResolver(schema) }),
    mode: 'onChange',
    defaultValues,
    shouldUnregister,
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (resetOnDefaultChange && defaultValues) {
      reset(defaultValues);
    }
  }, [resetOnDefaultChange, defaultValues, reset]);

  useEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      console.warn(`Form errors`, methods.formState.errors);
    }
  }, [methods.formState.errors]);

  return (
    <FormProvider<z.infer<Z>> {...methods}>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(async (values) => {
          try {
            const result = await onSubmit(values);

            if (result !== false) {
              await afterSubmit?.(result);
            }

            if (resetOnSubmit) {
              reset();
            }
          } catch (error) {
            console.error(`Failed to submit ZodForm`, { error, values });
          }
        })}
        {...props}
      >
        {children}
      </form>
    </FormProvider>
  );
}
