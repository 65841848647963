import { styled, Modal, IconButton } from '@mui/material';
import { THUMBNAIL_SIZE_SQUARE } from 'system';

export const Thumbnail = styled('img')(() => ({
  backgroundSize: 'cover',
  borderRadius: '11px',
  height: THUMBNAIL_SIZE_SQUARE,
  width: THUMBNAIL_SIZE_SQUARE,
}));

export const PreviewModal = styled(Modal)(() => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
}));

export const CancelIconButton = styled(IconButton)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  top: 25,
  left: 10,
}));

export const DeleteIconButton = styled(IconButton)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  top: 25,
  right: 10,
}));

export const Image = styled('img')(() => ({
  display: 'block',
  width: '100%',
}));
