import { HandlerMap } from '@propra-system/registry';
import { useProfileEventsSubscription } from 'api';

import { useAllErrors } from 'hooks';
import { createContext, useContext } from 'react';
import { Children, noOp } from 'system';
import { useAuth } from '../AuthProvider';
import { useEventHandlers } from './useEventHandlers';

const EventsContext = createContext<ReturnType<typeof useEventHandlers>>({
  hashMap: [] as number[],
  handlerMap: [] as HandlerMap,
  addHandler: noOp,
  removeHandler: noOp,
  handleEvent: noOp,
  addHandlerMap: noOp,
  removeHandlerMap: noOp,
});

export const useEvents = () => useContext(EventsContext);

export default function EventsProvider({ children }: Children) {
  const value = useEventHandlers();
  const { handleEvent } = value;
  const { user } = useAuth();
  const { error: eventsError } = useProfileEventsSubscription({
    variables: { profileId: user?.sub },
    onSubscriptionData: (data) => {
      data.subscriptionData.data?.onProfileEvent &&
        handleEvent(data.subscriptionData.data.onProfileEvent);
    },
  });
  useAllErrors(eventsError);

  return <EventsContext.Provider {...{ value }}>{children}</EventsContext.Provider>;
}
