import { Stack } from '@mui/material';
import { ContactRelationship } from 'api';
import { CheckboxFieldController, DropdownFieldController, TextFieldController } from 'components';
import { validPhone } from 'system';
import { array, boolean, object, SchemaOf, string } from 'yup';

export type EmergencyContactsField = {
  emergencyContacts?: {
    name: string;
    relationship?: ContactRelationship;
    consentToShare: boolean;
    phone: string;
  }[];
};

export const emergencyContactsSchema = object({
  emergencyContacts: array()
    .of(
      object({
        name: string().optional().max(200, 'Max 200 characters allowed'),
        relationship: string()
          .optional()
          .oneOf([...Object.values(ContactRelationship), null])
          .nullable(),
        consentToShare: boolean()
          .when('name', {
            is: (value: string) => Boolean(value),
            then: boolean()
              .required('Consent is required to add an emergency contact')
              .oneOf([true], 'Consent is required to add an emergency contact'),
          })
          .default(false),
        phone: string().test(
          'phone',
          'Emergency Contact Phone must be a valid phone number',
          function (value) {
            const { name } = this.parent;
            return name ? validPhone(value ?? '') : true;
          }
        ),
      })
    )
    .notRequired(),
}) as SchemaOf<EmergencyContactsField>;

type EmergencyContactsStepProps = {
  accountName: string;
};

export function EmergencyContactsStep(props?: Record<string, unknown>) {
  const { accountName } = props as EmergencyContactsStepProps;
  return (
    <Stack gap={3} sx={{ my: 2 }}>
      <TextFieldController label="Name" name="emergencyContacts.0.name" variant="standard" />
      <DropdownFieldController<EmergencyContactsField>
        name="emergencyContacts.0.relationship"
        type="text"
        fullWidth
        label="Relationship"
        data={Object.values(ContactRelationship).map((r) => ({ label: r, value: r }))}
        variant="standard"
      />
      <TextFieldController
        label="Phone number"
        name="emergencyContacts.0.phone"
        variant="standard"
      />
      <CheckboxFieldController<EmergencyContactsField>
        name="emergencyContacts.0.consentToShare"
        label={`In the event of an emergency I authorize the release of information concerning my tenancy at properties managed by ${accountName} to the emergency contact identified above`}
      />
    </Stack>
  );
}
