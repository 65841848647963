import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { compact } from 'lodash';

type Action = () => void;
let isRefreshing = false;
let pendingRequests: Action[] = [];

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value;
};

const addPendingRequest = (pendingRequest: Action) => {
  pendingRequests.push(pendingRequest);
};

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const TOKEN_EXPIRED = 'Token has expired.';

const errorLink = ({
  refresh,
  signout,
  notifier,
}: {
  refresh: () => Promise<unknown>;
  signout: VoidFunction;
  notifier?: (error: string) => void;
}) =>
  onError(({ graphQLErrors = [], networkError, operation, forward }) => {
    if (graphQLErrors?.[0]) {
      for (const err of graphQLErrors) {
        switch (err?.message) {
          case TOKEN_EXPIRED:
            if (!isRefreshing) {
              setIsRefreshing(true);

              return fromPromise(
                refresh().catch(() => {
                  resolvePendingRequests();
                  setIsRefreshing(false);

                  signout();

                  return forward(operation);
                })
              ).flatMap(() => {
                resolvePendingRequests();
                setIsRefreshing(false);

                return forward(operation);
              });
            } else {
              return fromPromise(
                new Promise<void>((resolve) => {
                  addPendingRequest(() => resolve());
                })
              ).flatMap(() => {
                return forward(operation);
              });
            }
        }
      }
    }

    if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
      signout();
    } else {
      notifier &&
        compact(
          [...graphQLErrors.filter(({ message }) => message !== TOKEN_EXPIRED), networkError].map(
            (e) => e?.message
          )
        ).map(notifier);
    }
  });

export default errorLink;
