import { useState } from 'react';

export const useModalControl = <T = unknown, TFocusType extends string | undefined = undefined>(
  defaultFocus?: T,
  defaultFocusType?: TFocusType
) => {
  const [state, setState] = useState({
    open: false,
    focus: undefined as T | undefined,
    focusType: undefined as TFocusType | undefined,
  });
  const handleHide = () => setState((state) => ({ ...state, open: false }));
  const handleShow = (focus?: T, focusType?: TFocusType): void =>
    setState({
      open: true,
      focus: focus ?? defaultFocus,
      focusType: focusType ?? defaultFocusType,
    });

  return [handleShow, handleHide, state.open, state.focus, state.focusType] as const;
};
