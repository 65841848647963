import { IconButton, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export default function EditIconButton({
  loading,
  onClick,
}: {
  loading?: boolean;
  onClick: () => void;
}) {
  return (
    <IconButton aria-label="edit" color="primary" onClick={onClick} disabled={loading}>
      {loading ? <CircularProgress size={14} /> : <EditIcon fontSize="small" />}
    </IconButton>
  );
}
