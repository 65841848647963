import Logout from '@mui/icons-material/Logout';
import Person from '@mui/icons-material/Person';
import Settings from '@mui/icons-material/Settings';
import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useAnchorEl } from 'hooks';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { avatarInitials } from 'system';
import { useAuth } from './providers';

export default function AccountMenu() {
  const { anchorEl, clearAnchorEl, clearAnchorElThen, handleAnchorElClick } = useAnchorEl();

  const { user, signout } = useAuth();
  const menuOpen = Boolean(anchorEl);
  const navigate = useNavigate();

  const onMenuItemClick = (e: MouseEvent, onAction: () => void) => {
    e.preventDefault();
    e.stopPropagation();
    void clearAnchorElThen(onAction)();
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleAnchorElClick(e);
        }}
        sx={{ ml: 3 }}
        aria-controls={menuOpen ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        disableRipple={true}
      >
        <Avatar alt={user.name} src={user.picture}>
          {avatarInitials(user.name)}
        </Avatar>
      </IconButton>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        id="account-menu"
        open={menuOpen}
        onClose={clearAnchorEl}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          },
        }}
      >
        <MenuItem onClick={(e) => onMenuItemClick(e, () => navigate('/profile/personal-details'))}>
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem onClick={(e) => onMenuItemClick(e, () => navigate('/settings'))}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <Divider />
        <MenuItem onClick={(e) => onMenuItemClick(e, signout)}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sign out
        </MenuItem>
      </Menu>
    </>
  );
}
