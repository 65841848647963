import { useTheme } from 'context';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';

const env = (process.env.REACT_APP_ENV ?? 'dev').toLowerCase();

export const Debug = (props: ReactJsonViewProps) => {
  const { darkMode } = useTheme();
  return env === 'prod' ? (
    <></>
  ) : (
    <ReactJson collapsed theme={darkMode ? 'bright' : 'bright:inverted'} {...props} />
  );
};
