/**
 * A list of all financial institutions in Canada
 * sourced from https://www.bankrouting.ca/financial-institution-numbers
 */
export const bankInstitutions: Record<
  string,
  {
    name: string;
    streetAddress: string;
    city: string;
    province: string;
    postalCode: string;
  }
> = {
  '001': {
    name: 'BMO',
    streetAddress: "129 St. James Street, Place d'Armes, P.O. Box 6002",
    city: 'Montreal',
    province: 'QC',
    postalCode: 'H2Y 1L6',
  },
  '002': {
    name: 'Scotiabank',
    streetAddress: 'Scotia Plaza, 8th Floor, 40 King Street West',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5H 1H1',
  },
  '003': {
    name: 'RBC',
    streetAddress: '1 Place Ville Marie, P.O. Box 6001',
    city: 'Montreal',
    province: 'QC',
    postalCode: 'H3C 3A9',
  },
  '004': {
    name: 'TD',
    streetAddress: 'Branch Services, TD Canada Trust, 4880 Tahoe Blvd.',
    city: 'Mississauga',
    province: 'ON',
    postalCode: 'L4W 5P3',
  },
  '006': {
    name: 'National Bank',
    streetAddress: '600 de la Gauchetière West, BNC Tower 4215-1',
    city: 'Montreal',
    province: 'QC',
    postalCode: 'H3B 4L2',
  },
  '010': {
    name: 'CIBC',
    streetAddress: '81 Bay Street, CIBC Square',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5J 0E7',
  },
  '016': {
    name: 'HSBC',
    streetAddress: '885 West Georgia Street, Suite 300',
    city: 'Vancouver',
    province: 'BC',
    postalCode: 'V6C 3E9',
  },
  '030': {
    name: 'Canadian Western Bank',
    streetAddress: '2300-10303 Jasper Avenue, Suite 2300',
    city: 'Edmonton',
    province: 'AB',
    postalCode: 'T5J 3X6',
  },
  '039': {
    name: 'Laurentian',
    streetAddress: '1981 McGill College Ave, 20th Floor',
    city: 'Montreal',
    province: 'QC',
    postalCode: 'H3A 3K3',
  },
  '177': {
    name: 'Bank of Canada',
    streetAddress: '234 Wellington Street',
    city: 'Ottawa',
    province: 'ON',
    postalCode: 'K1A 0G9',
  },
  '260': {
    name: 'Citibank',
    streetAddress: 'Citibank Place, 19th Floor, 123 Front Street West',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5J 2M3',
  },
  '275': {
    name: 'KEB Hana Bank Canada',
    streetAddress: '4950 Yonge Street, Suite 1101',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M2N 6K1',
  },
  '290': {
    name: 'UBS Bank (Canada)',
    streetAddress: '154 University Avenue',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5H 3Z4',
  },
  '294': {
    name: 'SBI Canada Bank',
    streetAddress: 'Suite 800, P.O. Box 81, Royal Bank Plaza, North Tower',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5J 2J2',
  },
  '303': {
    name: 'Amex',
    streetAddress: 'TRS Canada Treasury, 2225 Sheppard Ave East, Suite 100',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M2J 5C2',
  },
  '307': {
    name: 'Industrial and Commercial Bank of China (Canada)',
    streetAddress: 'Suite 102 - 3, 350 Highway 7 East',
    city: 'Richmond Hill',
    province: 'ON',
    postalCode: 'L4B 3N2',
  },
  '308': {
    name: 'Bank of China',
    streetAddress: '396 Dundas Street West',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5T 1G7',
  },
  '309': {
    name: 'Vancity',
    streetAddress: '183 Terminal Avenue',
    city: 'Vancouver',
    province: 'BC',
    postalCode: 'V6A 4G2',
  },
  '310': {
    name: 'First Nations',
    streetAddress: '224 - 4th Avenue South',
    city: 'Saskatoon',
    province: 'SK',
    postalCode: 'S7K 5M5',
  },
  '314': {
    name: 'J.P. Morgan',
    streetAddress: '200 Bay Street, Royal Bank Plaza - South Tower, Suite 1800',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5J 2J2',
  },
  '315': {
    name: 'CTBC Bank',
    streetAddress: '350-2608 Granville St.',
    city: 'Vancouver',
    province: 'BC',
    postalCode: 'V6H 3V3',
  },
  '320': {
    name: 'PC Bank',
    streetAddress: '600-500 Lakeshore Blvd. West P.O. Box 600',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5V 2V9',
  },
  '321': {
    name: 'Habib Canadian Bank',
    streetAddress: '918 Dundas Street, Suite 1B',
    city: 'Mississauga',
    province: 'ON',
    postalCode: 'L4Y 4H9',
  },
  '326': {
    name: 'CIBC',
    streetAddress: '81 Bay Street, CIBC Square',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5J 0E7',
  },
  '334': {
    name: 'VersaBank',
    streetAddress: '140 Fullarton Street, Suite 2002',
    city: 'London',
    province: 'ON',
    postalCode: 'N6A 5P2',
  },
  '338': {
    name: 'Canadian Tire Bank',
    streetAddress: '366 Bunting Road',
    city: 'St. Catharines',
    province: 'ON',
    postalCode: 'L2M 3Y6',
  },
  '340': {
    name: 'ICICI Bank Canada',
    streetAddress: '154 University Avenue, Suite 400',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5H 3Y9',
  },
  '343': {
    name: 'ADS Canadian Bank',
    streetAddress: '44 King Street West',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5H 1H1',
  },
  '344': {
    name: 'General Bank of Canada',
    streetAddress: '#006, 11523 - 100 Ave., Le Marchand Mansion',
    city: 'Edmonton',
    province: 'AB',
    postalCode: 'T5K 0J8',
  },
  '347': {
    name: 'Bridgewater Bank',
    streetAddress: 'P.O. Box 8180 Station South',
    city: 'Edmonton',
    province: 'AB',
    postalCode: 'T6H 5X9',
  },
  '352': {
    name: 'DC Bank',
    streetAddress: '736 Meridian Road N.E.',
    city: 'Calgary',
    province: 'AB',
    postalCode: 'T2A 2N7',
  },
  '355': {
    name: 'Shinhan Bank Canada',
    streetAddress: '5140 Yonge Street, Suite 2300',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M2N 6L7',
  },
  '356': {
    name: 'Citco Bank Canada',
    streetAddress: '2 Bloor Street East, Suite 2700',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M4W 1A8',
  },
  '358': {
    name: 'HomeEquity Bank',
    streetAddress: '1881 Yonge Street, Suite 300',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M4S 3C4',
  },
  '359': {
    name: 'Duo Bank of Canada',
    streetAddress: '1940 Argentia Road',
    city: 'Mississauga',
    province: 'ON',
    postalCode: 'L5N 1P9',
  },
  '361': {
    name: 'Home Bank',
    streetAddress: '2020 Winston Park Drive, Suite 302',
    city: 'Oakville',
    province: 'ON',
    postalCode: 'L6H 6X7',
  },
  '368': {
    name: 'Rogers Bank',
    streetAddress: '333 Bloor St. East',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M4W 1G9',
  },
  '370': {
    name: 'Wealth One',
    streetAddress: '170 Sheppard Avenue East',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M2N 3A4',
  },
  '374': {
    name: 'Motus',
    streetAddress: '3280 Bloor Street West Centre Tower',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M8X 2X3',
  },
  '376': {
    name: 'Exchange',
    streetAddress: '390 Bay Street, Suite 700',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5H 2Y2',
  },
  '377': {
    name: 'RFA',
    streetAddress: '1 Yonge Street, Suite 2401',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5E 1E5',
  },
  '378': {
    name: 'Cidel',
    streetAddress: '60 Bloor St West, 9th floor',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M4W 3B8',
  },
  '381': {
    name: 'Haventree Bank',
    streetAddress: '4610-100 King Street West',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5X 1E5',
  },
  '382': {
    name: 'Coast Capital',
    streetAddress: '800-9900 King George Blvd',
    city: 'Surrey',
    province: 'BC',
    postalCode: 'V3T 0K7',
  },
  '383': {
    name: 'Peoples Bank',
    streetAddress: '1400-888 Dunsmuir St.',
    city: 'Vancouver',
    province: 'BC',
    postalCode: 'V6C 3K4',
  },
  '540': {
    name: 'Manulife',
    streetAddress: '500 King Street North',
    city: 'Waterloo',
    province: 'ON',
    postalCode: 'N2J 4C6',
  },
  '608': {
    name: 'CS Alterna Bank',
    streetAddress: '319 McRae Avenue, 2nd Floor',
    city: 'Ottawa',
    province: 'ON',
    postalCode: 'K1Z 0B9',
  },
  '614': {
    name: 'Tangerine',
    streetAddress: 'Suite 600, 3389 Steels Avenue East',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M2H 3S8',
  },
  '618': {
    name: 'B2B',
    streetAddress: '199 Bay Street, Suite 600, PO Box 270 STN Commerce Court',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5L 0A2',
  },
  '623': {
    name: 'Equitable',
    streetAddress: '30 St. Clair Avenue West, Suite 700',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M4V 3A1',
  },
  '853': {
    name: 'Concentra',
    streetAddress: '333-3rd Avenue North',
    city: 'Saskatoon',
    province: 'SK',
    postalCode: 'S7K 2M2',
  },
  '241': {
    name: 'Bank of America',
    streetAddress: '181 Bay Street, Suite 400',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5J 2V8',
  },
  '242': {
    name: 'NY Mellon',
    streetAddress: '320 Bay Street',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5H 4A6',
  },
  '245': {
    name: 'MUFG',
    streetAddress: 'Suite 1800, P.O. Box 42, Royal Bank Plaza, South Tower',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5J 2J1',
  },
  '250': {
    name: 'BNP Paribas',
    streetAddress: '2001 Robert-Bourassa Blvd.',
    city: 'Montreal',
    province: 'QC',
    postalCode: 'H3A 2A6',
  },
  '265': {
    name: 'Deutsche Bank AG',
    streetAddress: '199 Bay Street, Suite 4700, Commerce Court West',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5L 1E9',
  },
  '269': {
    name: 'Mega International Commercial Bank Co., Ltd.',
    streetAddress: '4950 Yonge Street Suite 1002',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M2N 6K1',
  },
  '270': {
    name: 'JPMorgan Chase',
    streetAddress: 'Suite 4500, TD Bank Tower, 66 Wellington Street West',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5K 1E7',
  },
  '277': {
    name: 'Mizuho',
    streetAddress: '100 Yonge Street, Suite 1102',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5C 2W1',
  },
  '301': {
    name: 'Sumitomo Mitsui',
    streetAddress: 'Toronto Dominion Centre, Suite 1400, P.O. Box 172',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5K 1H6',
  },
  '318': {
    name: 'U.S. Bank',
    streetAddress: '120 Adelaide Street, 23rd Floor',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5H 1T1',
  },
  '322': {
    name: 'Rabobank',
    streetAddress: '22 Adelaide St. West Suite 3720, Bay Adelaide Centre East Tower',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5H 4E3',
  },
  '323': {
    name: 'Capital One',
    streetAddress: '161 Bay Street, 19th Floor',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5J 2S1',
  },
  '327': {
    name: 'State Street',
    streetAddress: '30 Adelaide Street East, Suite 1500',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5C 3G6',
  },
  '328': {
    name: 'Citibank',
    streetAddress: '123 Front Street West, 19th Floor',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5J 2M3',
  },
  '330': {
    name: 'Comerica',
    streetAddress: 'Suite 2200, South Tower, Royal Bank Plaza, P.O. Box 61',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5J 2J2',
  },
  '332': {
    name: 'First Commercial',
    streetAddress: '#100 - 5611 Cooney Road',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'V6X 3J6',
  },
  '335': {
    name: 'United Overseas',
    streetAddress: '650 West Georgia Street, Suite 2400, Vancouver Centre, P.O. Box 11616',
    city: 'Vancouver',
    province: 'BC',
    postalCode: 'V6B 4N9',
  },
  '345': {
    name: 'Fifth Third Bank',
    streetAddress: '20 Bay Street, 12th Floor',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5J 2N8',
  },
  '346': {
    name: 'Société Générale',
    streetAddress: '1501 McGill College Avenue, Suite 1800',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'H3A 3M8',
  },
  '349': {
    name: 'Northern Trust',
    streetAddress: '145 King Street West, Suite 1910',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5H 1J8',
  },
  '357': {
    name: 'M & T',
    streetAddress: 'Brookfield Place, TD Canada Trust Tower, 161 Bay Street, 27th Floor',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5J 2S1',
  },
  '360': {
    name: 'Barclays',
    streetAddress: 'Bay Adelaide Centre, 333 Bay St., Suite 4910, Box 9',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5H 2R2',
  },
  '362': {
    name: 'Wells Fargo',
    streetAddress: '22 Adelaide Street West Suite 2200',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5H 4E3',
  },
  '365': {
    name: 'PNC',
    streetAddress: '130 King W',
    city: 'York',
    province: 'ON',
    postalCode: 'M9N 1L5',
  },
  '366': {
    name: 'China Construction',
    streetAddress: '181 Bay Street, Suite 3650',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5J 2T3',
  },
  '372': {
    name: 'Bank of China',
    streetAddress: 'First Canadian Place, 100 King St. W, Suite 6108',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5X 1C8',
  },
  '809': {
    name: 'Central 1',
    streetAddress: '1441 Creekside Drive',
    city: 'Vancouver',
    province: 'BC',
    postalCode: 'V6J 4S7',
  },
  '815': {
    name: 'Desjardins',
    streetAddress: '1170, rue Peel, bureau 600',
    city: 'Montreal',
    province: 'QC',
    postalCode: 'H3B 0B1',
  },
  '819': {
    name: 'Caisse Populaire',
    streetAddress: '400-205, boulevard Provencher',
    city: 'Winnipeg',
    province: 'MB',
    postalCode: 'R2H 0G4',
  },
  '828': {
    name: 'Central 1',
    streetAddress: '1441 Creekside Drive',
    city: 'Vancouver',
    province: 'BC',
    postalCode: 'V6J 4S7',
  },
  '829': {
    name: 'Caisse Desjardins',
    streetAddress: '214, Chemin Montréal',
    city: 'Ottawa',
    province: 'ON',
    postalCode: 'K1L 8L8',
  },
  '839': {
    name: 'Atlantic Central',
    streetAddress: '6074 Lady Hammond Road, P.O. Box 9200',
    city: 'Halifax',
    province: 'NS',
    postalCode: 'B3K 5N3',
  },
  '849': {
    name: 'Atlantic Central',
    streetAddress: '6074 Lady Hammond Road, P.O. Box 9200',
    city: 'Halifax',
    province: 'NS',
    postalCode: 'B3K 5N3',
  },
  '865': {
    name: 'Caisse populaire acadienne ltée',
    streetAddress: "Place de l'Acadie, 295 St. Pierre Blvd West, P.O. Box 5554",
    city: 'Caraquet',
    province: 'NB',
    postalCode: 'E1W 1B7',
  },
  '869': {
    name: 'Central 1 Credit Union',
    streetAddress: '1441 Creekside Drive',
    city: 'Vancouver',
    province: 'BC',
    postalCode: 'V6J 4S7',
  },
  '879': {
    name: 'Credit Union',
    streetAddress: '400-317 Donald Street',
    city: 'Winnipeg',
    province: 'MB',
    postalCode: 'R3B 2H6',
  },
  '889': {
    name: 'Credit Union',
    streetAddress: '2055 Albert Street, P.O. Box 3030',
    city: 'Regina',
    province: 'SK',
    postalCode: 'S4P 3G8',
  },
  '890': {
    name: 'Caisse populaire Alliance Limitée',
    streetAddress: '1870, rue Bond',
    city: 'North Bay',
    province: 'ON',
    postalCode: 'P1B 4V6',
  },
  '899': {
    name: 'Credit Union',
    streetAddress: '350N-8500 Macleod Trail South',
    city: 'Calgary',
    province: 'AB',
    postalCode: 'T2H 2N1',
  },
  '702': {
    name: 'Manulife',
    streetAddress: '200 Bloor Street East',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M4W 1E5',
  },
  '219': {
    name: 'ATB',
    streetAddress: '16th Floor, 10020 - 100 Street',
    city: 'Edmonton',
    province: 'AB',
    postalCode: 'T5J 0N3',
  },
  '701': {
    name: 'Edward Jones',
    streetAddress: '902-90 Burnhamthorpe Road West',
    city: 'Mississauga',
    province: 'ON',
    postalCode: 'L5B 3C3',
  },
  '703': {
    name: 'Wealthsimple',
    streetAddress: '201 - 80 Spadina Avenue',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5V 2J4',
  },
  '507': {
    name: 'Community Trust',
    streetAddress: '2350 Matheson Blvd. E.',
    city: 'Mississauga',
    province: 'ON',
    postalCode: 'L4W 5A9',
  },
  '509': {
    name: 'TD',
    streetAddress: 'TD Canada Trust, 4880 Tahoe Blvd.',
    city: 'Mississauga',
    province: 'ON',
    postalCode: 'L4W 5P3',
  },
  '522': {
    name: 'Laurentienne',
    streetAddress: '1981, avenue McGill Collège, Bureau 2070',
    city: 'Montréal',
    province: 'QC',
    postalCode: 'H3A 3K3',
  },
  '532': {
    name: 'Effort Trust Company',
    streetAddress: '240 Main Street East',
    city: 'Hamilton',
    province: 'ON',
    postalCode: 'L8N 1H5',
  },
  '536': {
    name: 'IG',
    streetAddress: 'One Canada Centre, 447 Portage Avenue',
    city: 'Winnipeg',
    province: 'MB',
    postalCode: 'R3C 3B6',
  },
  '548': {
    name: 'CIBC Trust',
    streetAddress: 'Suite 1000, 55 Yonge Street',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5E 1J4',
  },
  '550': {
    name: 'Montreal Trust',
    streetAddress: '44 King Street West',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5H 1H1',
  },
  '551': {
    name: 'Sun Life',
    streetAddress: '227 King Street South, P.O. Box 1601, STN Waterloo',
    city: 'Waterloo',
    province: 'ON',
    postalCode: 'N2J 4C5',
  },
  '568': {
    name: 'Peace Hills Trust',
    streetAddress: 'Peace Hills Trust Tower, Samson Mall',
    city: 'Hobbema',
    province: 'AB',
    postalCode: 'T0C 1N0',
  },
  '570': {
    name: 'Royal Trust',
    streetAddress: 'Royal Trust Tower, 10th Floor, P.O. Box 7500, Station A',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5W 1P9',
  },
  '580': {
    name: 'Royal Trust',
    streetAddress: 'Royal Trust Tower, 10th Floor, P.O. Box 7500, Station A',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5W 1P9',
  },
  '590': {
    name: 'National Trust',
    streetAddress: '1 Ontario Street',
    city: 'Stratford',
    province: 'ON',
    postalCode: 'N5A 6S9',
  },
  '597': {
    name: 'TD',
    streetAddress: 'P.O. Box 191, Toronto-Dominion Centre',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5K 1A2',
  },
  '603': {
    name: 'TD',
    streetAddress: 'TD Tower, P.O. Box 191, Toronto-Dominion Centre',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5K 1A2',
  },
  '604': {
    name: 'HSBC',
    streetAddress: 'Hongkong Bank of Canada Building, 885 West Georgia Street, Suite 300',
    city: 'Vancouver',
    province: 'BC',
    postalCode: 'V6C 3E9',
  },
  '606': {
    name: 'Scotiabank',
    streetAddress: '44 King Street West',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5H 1H1',
  },
  '612': {
    name: 'Natcan Trust',
    streetAddress: '600 de la Gauchetière Ouest',
    city: 'Montreal',
    province: 'QC',
    postalCode: 'H3B 4L2',
  },
  '621': {
    name: 'Peoples Trust',
    streetAddress: '1400 - 888 Dunsmuir Street',
    city: 'Vancouver',
    province: 'BC',
    postalCode: 'V6C 3K4',
  },
  '626': {
    name: 'Manulife Trust',
    streetAddress: '500 King Street North',
    city: 'Waterloo',
    province: 'ON',
    postalCode: 'N2J 4C6',
  },
  '627': {
    name: 'Home Trust',
    streetAddress: '145 King Street West, Suite 2300',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5H 1J8',
  },
};
