import {
  LinearProgress as _LinearProgress,
  Box as _Box,
  styled,
  Typography,
  Grid,
  Box,
} from '@mui/material';
import { Form as _Form } from 'components';

export const Container = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    height: 'inherit',
  },
}));

export const Form = styled(_Form)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}) as typeof _Form;

export const LinearProgress = styled(_LinearProgress)({
  width: '100%',
  height: 2,
});

export const LinearProgressContainer = styled(_Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 0),
}));

export const IconContainer = styled('div')(({ theme }) => ({
  '& *': {
    borderRadius: '50%',
    width: theme.spacing(5),
    height: theme.spacing(5),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primaryBackground.main,
  },
}));

export const Header = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: theme.typography.fontWeightMedium,
  [theme.breakpoints.up('md')]: {
    fontSize: 40,
  },
}));

export const HeadingBox = styled(Box)(({ theme }) => ({
  marginTop: 0,
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(2),
  },
}));
