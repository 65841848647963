import { Typography, useTheme, Stack, Button, Link } from '@mui/material';
import { ApplicationTenantFieldsFragment } from 'api';
import { formatDateTime, formatPhone } from 'system';
import DeleteIconButton from '../DeleteIconButton';
import EditIconButton from '../EditIconButton';
import {
  Phone,
  Mail,
  Person,
  Header,
  LeaseHolder,
  CurrencyExchange,
} from './HouseholdDetails.styles';
import { DateTime } from 'luxon';
import { Countdown } from '../Countdown';
import { useEffect, useState } from 'react';
import { fractionToPercentage } from 'apps/portal/pages/profile/Application/utils';

const RESEND_EMAIL_RETRY_SECONDS = 300;

export function TenantDetail({
  tenant,
  tenantNumber,
  onEdit,
  onDelete,
  onResend,
  loading,
  isApplication,
}: {
  tenant: Omit<ApplicationTenantFieldsFragment, 'id, __typename'>;
  tenantNumber?: number;
  onEdit?: () => void;
  onDelete?: () => void;
  onResend?: () => void;
  loading?: boolean;
  isApplication?: boolean;
}) {
  const theme = useTheme();
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCanResend(
        Boolean(
          tenant.sentZ &&
            DateTime.fromISO(tenant.sentZ) <
              DateTime.now().minus({ seconds: RESEND_EMAIL_RETRY_SECONDS })
        )
      );
    }, 1000);
    return () => clearInterval(timer);
  }, [tenant.sentZ]);

  return (
    <Stack marginBottom={2}>
      <Stack direction="row" gap={1} alignItems="center">
        <Header>Tenant {tenantNumber && <>#{tenantNumber}</>}</Header>
        <Stack direction="row" gap={0} alignItems="center">
          {onEdit && <EditIconButton loading={loading} onClick={onEdit} />}
          {onDelete && <DeleteIconButton loading={loading} onClick={onDelete} />}
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 1 }}>
        <Person />
        <Typography variant="body2" color={theme.palette.text.secondary}>
          {tenant.name ?? ''}
        </Typography>
      </Stack>
      {tenant.phone && (
        <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 1 }}>
          <Phone />
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {tenant.phone ? formatPhone(tenant.phone) : ''}
          </Typography>
        </Stack>
      )}
      {tenant.email && (
        <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 1 }}>
          <Mail />
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {tenant.email ?? ''}
          </Typography>
          {tenant.leaseholder && tenant.sentZ && (
            <Button
              disabled={!canResend}
              onClick={onResend}
              variant="text"
              color="primary"
              size="small"
            >
              resend{' '}
              {!canResend && (
                <Countdown timestamp={tenant.sentZ} maxSeconds={RESEND_EMAIL_RETRY_SECONDS} />
              )}
            </Button>
          )}
        </Stack>
      )}
      {tenant.leaseholder && isApplication && (
        <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 1 }}>
          <LeaseHolder />
          <Typography variant="body2" color={theme.palette.text.secondary}>
            Leaseholder
          </Typography>
        </Stack>
      )}
      {tenant.sentZ && (
        <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 1 }}>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            Invitation sent on {formatDateTime(tenant.sentZ)}
          </Typography>
        </Stack>
      )}
      {tenant.leaseholder && isApplication && tenant.payeeFrac && (
        <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 1 }}>
          <CurrencyExchange />
          <Typography variant="body2" color={theme.palette.text.secondary}>
            Share {fractionToPercentage(tenant.payeeFrac)}
          </Typography>
          <Button LinkComponent={Link} href="payees/edit">
            Change
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
