import { Typography, useTheme, Stack } from '@mui/material';
import plur from 'plur';
import { PetFieldsFragment } from 'api';
import { Pets } from './HouseholdDetails.styles';
import { capitalize } from 'system';

export function PetsDetail({ pets }: { pets: PetFieldsFragment[] }) {
  const theme = useTheme();
  return (
    <>
      {pets?.map((pet, index) => (
        <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 1 }} key={index}>
          <Pets />
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {pet.count ?? 0}
          </Typography>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {plur(capitalize(pet.type ?? '') ?? '', pet.count ?? 0)}
          </Typography>
        </Stack>
      ))}
    </>
  );
}
