import { FileCopy } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Bubble, Cell, Info } from './Documents.styles';

export type DocumentCellProps = {
  name?: string;
  docKey: string;
  subtext?: string;
  size?: number | string;
};

export function DocumentCell({ name, docKey, size, subtext }: DocumentCellProps) {
  return (
    <Cell>
      <Bubble>
        <FileCopy />
      </Bubble>
      <Info>
        <Typography variant="body1" noWrap>
          {(name ?? docKey).split('/').reverse()[0]}
        </Typography>
        <Typography variant="subtitle2" noWrap color="textSecondary">
          {size}
        </Typography>
        <Typography variant="subtitle2" noWrap color="textSecondary">
          {subtext}
        </Typography>
      </Info>
    </Cell>
  );
}
