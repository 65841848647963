/* eslint-disable react-hooks/exhaustive-deps */
import { Storage } from '@aws-amplify/storage';
import { useEffect, useState } from 'react';

export const useGetS3Image = (key?: string) => {
  const [image, setImage] = useState<string | undefined>();
  useEffect(() => {
    const fetchImage = async () => {
      if (key) {
        const image = await Storage.get(key);
        setImage(image.toString());
      } else {
        setImage(undefined);
      }
    };
    void fetchImage();
  }, [key]);
  return image;
};

export const useGetS3Document = (key?: string) => {
  const [document, setdocument] = useState<string | undefined>();
  useEffect(() => {
    const fetchDocument = async () => {
      if (key) {
        const doc = await Storage.get(key, {
          bucket: process.env.REACT_APP_DOCUMENT_BUCKET,
        });
        setdocument(doc.toString());
      } else {
        setdocument(undefined);
      }
    };
    void fetchDocument();
  }, [key]);
  return document;
};

export const useGetS3DocumentMeta = (key?: string) => {
  const [document, setdocument] = useState<
    { lastModified?: Date; contentLength?: number; contentType?: string } | undefined
  >();
  useEffect(() => {
    const fetchDocument = async () => {
      if (key) {
        const { LastModified, ContentLength, ContentType } = await Storage.get(key, {
          bucket: process.env.REACT_APP_DOCUMENT_BUCKET,
          download: true,
        });
        setdocument({
          lastModified: LastModified,
          contentLength: ContentLength,
          contentType: ContentType,
        });
      }
    };
    void fetchDocument();
  }, [key]);
  return document;
};

export const useGetS3PublicFile = () => {
  const bucket = process.env.REACT_APP_PUBLIC_BUCKET ?? '';
  const region = process.env.REACT_APP_REGION ?? '';
  const S3Base = `https://${bucket}.s3.${region}.amazonaws.com/`;
  const getUrl = (key?: string) => (key ? `${S3Base}${key}` : '');
  return { getUrl };
};
