import ZodForm from 'components/FormController/ZodForm';
import { Wizard } from 'react-use-wizard';
import { WorkHistoryFields, workHistoryFieldsSchema } from './schema';
import { ConfirmStep } from './Steps/ConfirmStep';
import { NameStep } from './Steps/NameStep';
import { LocationStep } from './Steps/LocationStep';
import { DurationStep } from './Steps/DurationStep';
import { SalaryStep } from './Steps/SalaryStep';
import { PhoneNumberStep } from './Steps/PhoneNumberStep';
import { Box } from '@mui/material';
import { compact } from 'lodash';
import { ConfirmOtherWorkHistoryStep } from './Steps/ConfirmOtherWorkHistoryStep';
import { useFormContext } from 'react-hook-form';
import { ConfirmAdditionalIncomeStep } from './Steps';
import { sameMonthAsToday } from 'system';

export type GetFieldIndexProp = {
  getFieldIndex: (fieldComponent: React.ComponentType<any>) => number;
};

const LocalWizard = ({
  onCancel,
  onFinish,
  showConfirmStep,
  isAdding,
}: {
  showConfirmStep?: boolean;
  onCancel: VoidFunction;
  onFinish: (values?: WorkHistoryFields) => void;
  isAdding?: boolean;
}) => {
  const getFieldIndex = (fieldComponent: React.ComponentType) => {
    return steps.findIndex((step) => step?.type === fieldComponent);
  };
  const { watch } = useFormContext<WorkHistoryFields>();
  const endDate = watch('endDate');
  const isCurrentlyEmployed = Boolean(sameMonthAsToday(endDate) || !endDate);
  const hasOtherWorkHistory = watch('hasOtherWorkHistory');

  const steps = compact([
    showConfirmStep && <ConfirmStep {...{ onFinish, onCancel }} />,
    <NameStep {...{ showConfirmStep, onCancel }} />,
    <LocationStep />,
    <DurationStep {...{ getFieldIndex, onFinish, isAdding, isCurrentlyEmployed }} />,
    isCurrentlyEmployed && <SalaryStep />,
    isCurrentlyEmployed && <PhoneNumberStep {...{ getFieldIndex, onFinish, isAdding }} />,
    isAdding && (
      <ConfirmOtherWorkHistoryStep {...{ onFinish, getFieldIndex, isCurrentlyEmployed }} />
    ),
    isAdding && hasOtherWorkHistory !== 'yes' && <ConfirmAdditionalIncomeStep {...{ onFinish }} />,
  ]);

  return (
    <Wizard>
      {steps.map((step, index) => (
        <Box key={index}>{step}</Box>
      ))}
    </Wizard>
  );
};

export const WorkHistoryWizardForm = ({
  defaultValues,
  onCancel,
  onFinish,
  showConfirmStep,
  loading,
  isAdding,
}: {
  showConfirmStep?: boolean;
  defaultValues?: WorkHistoryFields;
  onCancel: VoidFunction;
  onFinish: (values?: WorkHistoryFields) => void;
  loading?: boolean;
  isAdding?: boolean;
}) => {
  return loading ? (
    <></>
  ) : (
    <ZodForm schema={workHistoryFieldsSchema} defaultValues={defaultValues}>
      <LocalWizard {...{ onCancel, onFinish, showConfirmStep, loading, isAdding }} />
    </ZodForm>
  );
};
