import { Controller, FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import { RichRadioField, RichRadioFieldProps } from '../Fields/RichRadioField';

export default function RichRadioFieldController<
  T extends FieldValues = Record<string, undefined>
>({ name, onChange, disabled, ...props }: RichRadioFieldProps & { name: Path<T> }) {
  const {
    control,
    setValue,
    formState: { isSubmitting },
  } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <RichRadioField
          value={field.value ?? ''}
          onChange={(event, checked) => {
            field.onChange(event);
            onChange?.(event, checked);
          }}
          disabled={disabled || isSubmitting}
          {...props}
          error={fieldState.error}
          onActionAreaClick={(value: string) =>
            setValue(name, value as PathValue<T, (string | undefined) & Path<T>>)
          }
        />
      )}
    />
  );
}
