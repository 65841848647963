import { Clear } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Skeleton, TextField } from '@mui/material';
import { useDebounce } from 'hooks';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';

export default function DocumentSearchInput({
  loading,
  onSearchEntered,
  initialSearch,
}: {
  loading: boolean;
  initialSearch?: string;
  onSearchEntered: (search?: string) => void;
}) {
  const [search, setSearch] = useState<string>(initialSearch ?? '');
  const debouncedSearch = useDebounce(search, 200);

  useEffect(() => {
    onSearchEntered(debouncedSearch);
  }, [debouncedSearch]);

  return (
    <Box sx={{ width: '100%', px: 2, py: 1 }}>
      {loading ? (
        <Skeleton width="100%" />
      ) : (
        <>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search"
            value={search}
            size="small"
            onChange={(event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
            InputProps={{
              endAdornment: (
                <>
                  {search && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear search"
                        onClick={() => setSearch('')}
                        onMouseDown={(e: MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                        edge="end"
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        </>
      )}
    </Box>
  );
}
