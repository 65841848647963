import { Box, BoxProps, Stack, styled } from '@mui/material';
import Nav from 'apps/portal/Nav';
import { PropsWithChildren, ReactNode } from 'react';

const Content = styled(Box)(({ theme }) => ({
  marginTop: 84.8,
  padding: `${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(12)} ${theme.spacing(2)}`,
  minHeight: 'calc(100vh - 96px)',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
    height: '100%',
  },
}));

export default function PaperFrame({
  children,
  drawerChildComponent,
  ...props
}: PropsWithChildren<BoxProps> & { drawerChildComponent?: ReactNode }) {
  return (
    <Stack sx={{ flexGrow: 1 }}>
      <Nav drawerChildComponent={drawerChildComponent} />
      <Content {...props}>{children}</Content>
    </Stack>
  );
}
