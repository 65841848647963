/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantDocumentPresignurlAction } from './presignurl';
import TenantDocumentPresignurlActionSchema from './presignurl.json';

export const isTenantDocumentPresignurlAction = guard<TenantDocumentPresignurlAction>(TenantDocumentPresignurlActionSchema as any);