import { SvgIcon, SvgIconProps } from '@mui/material';

export default function PropraGrey({ ...props }: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 66 15" {...props}>
      <path
        d="M14.7546 5.66667C14.7546 3.83333 16.2366 2.33333 18.048 2.33333C18.8384 2.33333 19.563 2.63333 20.1558 3.1C20.4193 2.36667 20.8145 1.66667 21.3085 1.06667C20.3863 0.4 19.2666 0 18.048 0C16.8295 0 15.6768 0.4 14.7546 1.1V0.366667C14.7546 0.166667 14.5899 0 14.3923 0H12.4822C12.4822 0 12.4492 0 12.4492 0.0333333C12.4492 0.0333333 12.4492 2.7 12.4492 5.53333C12.4492 5.6 12.4492 5.63333 12.4492 5.7C12.4492 5.76667 12.4492 5.8 12.4492 5.86667C12.4492 8.93333 12.4492 12.0333 12.4492 12.0333H14.7217V10.2667C14.7217 10.2667 14.7217 10.2667 14.7546 10.2667V5.66667Z"
        fill="#999999"
      />
      <path
        d="M5.5988 0C4.38024 0 3.22755 0.4 2.30539 1.1V0.366667C2.30539 0.166667 2.14072 0 1.94311 0H0.0329341C0 0 0 0 0 0.0333333V15C0 15 0 15 0.0329341 15H2.30539C2.30539 15 2.30539 15 2.30539 14.9667V10.2333C3.22755 10.9333 4.38024 11.3333 5.5988 11.3333C8.69461 11.3333 11.1976 8.8 11.1976 5.66667C11.1976 2.53333 8.69461 0 5.5988 0ZM5.5988 9C3.78743 9 2.30539 7.5 2.30539 5.66667C2.30539 3.83333 3.78743 2.33333 5.5988 2.33333C7.41018 2.33333 8.89222 3.83333 8.89222 5.66667C8.89222 7.5 7.41018 9 5.5988 9Z"
        fill="#999999"
      />
      <path
        d="M48.5127 5.66667C48.5127 3.83333 49.9947 2.33333 51.8061 2.33333C52.4977 2.33333 53.1235 2.53333 53.6504 2.9C53.9468 2.16667 54.342 1.5 54.8361 0.9C53.9798 0.333333 52.9588 0 51.839 0C50.6205 0 49.4678 0.4 48.5456 1.1V0.366667C48.5456 0.166667 48.381 0 48.1834 0H46.2732C46.2732 0 46.2402 0 46.2402 0.0333333C46.2402 0.0333333 46.2402 2.73333 46.2402 5.56667C46.2402 5.6 46.2402 5.63333 46.2402 5.7C46.2402 5.73333 46.2402 5.76667 46.2402 5.83333C46.2402 8.9 46.2402 12.0333 46.2402 12.0333H48.5127V10.2667C48.5127 10.2667 48.5127 10.2667 48.5456 10.2667L48.5127 5.66667Z"
        fill="#999999"
      />
      <path
        d="M66.0001 0H64.9791H64.024C63.8264 0 63.6618 0.166667 63.6618 0.366667V1.1C62.7396 0.4 61.5869 0 60.3683 0C57.2725 0 54.7695 2.53333 54.7695 5.66667C54.7695 8.8 57.2725 11.3333 60.3683 11.3333C61.5869 11.3333 62.7396 10.9333 63.6618 10.2333V11.6333C63.6618 11.8333 63.8264 12 64.024 12H65.9671V10.3333V1.03333V0H66.0001ZM60.4013 9C58.5899 9 57.1079 7.5 57.1079 5.66667C57.1079 3.83333 58.5899 2.33333 60.4013 2.33333C62.2127 2.33333 63.6947 3.83333 63.6947 5.66667C63.6947 7.5 62.2127 9 60.4013 9Z"
        fill="#999999"
      />
      <path
        d="M26.9406 0C23.8448 0 21.3418 2.53333 21.3418 5.66667C21.3418 8.8 23.8448 11.3333 26.9406 11.3333C30.0364 11.3333 32.5394 8.8 32.5394 5.66667C32.5394 2.53333 30.0364 0 26.9406 0ZM26.9406 9C25.1292 9 23.6472 7.5 23.6472 5.66667C23.6472 3.83333 25.1292 2.33333 26.9406 2.33333C28.752 2.33333 30.234 3.83333 30.234 5.66667C30.234 7.5 28.752 9 26.9406 9Z"
        fill="#999999"
      />
      <path
        d="M39.3898 0C38.1713 0 37.0186 0.4 36.0964 1.1V0.366667C36.0964 0.166667 35.9317 0 35.7341 0H33.8239C33.8239 0 33.791 0 33.791 0.0333333V4.6V6.76667V15C33.791 15 33.791 15.0333 33.8239 15.0333H36.0964C36.0964 15.0333 36.1293 15.0333 36.1293 15V10.2333C37.0515 10.9333 38.2042 11.3333 39.4228 11.3333C42.5186 11.3333 45.0216 8.8 45.0216 5.66667C44.9886 2.53333 42.4856 0 39.3898 0ZM39.3898 9C37.5784 9 36.0964 7.5 36.0964 5.66667C36.0964 3.83333 37.5784 2.33333 39.3898 2.33333C41.2012 2.33333 42.6832 3.83333 42.6832 5.66667C42.6832 7.5 41.2012 9 39.3898 9Z"
        fill="#999999"
      />
    </SvgIcon>
  );
}
