import { IconButton, styled } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

const Menu = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 60,
  [theme.breakpoints.up('md')]: {
    width: 100,
    '&:hover': {
      background: 'rgba(159, 120, 250, 0.16)',
    },
  },
}));

export default function BackButton({
  show = false,
  className,
  onBackClick,
}: {
  show?: boolean;
  className?: string;
  onBackClick: () => void;
}) {
  return (
    <Menu className={className}>
      {show ? (
        <IconButton aria-label="back-button" onClick={onBackClick}>
          <ArrowBackIos />
        </IconButton>
      ) : (
        <></>
      )}
    </Menu>
  );
}
