import { SvgIcon, SvgIconProps } from '@mui/material';
import { useTheme } from 'context';
import { colours } from 'system';

export default function CancelledCalendar({ ...props }: SvgIconProps) {
  const { darkMode } = useTheme();
  return (
    <SvgIcon viewBox="0 0 76 84" {...props}>
      <rect
        x="8"
        y="17"
        width="60"
        height="17"
        fill={darkMode ? colours.lightSlateBlue : colours.purpleHeart}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63 8.66927H67.1667C71.75 8.66927 75.5 12.4193 75.5 17.0026V75.3359C75.5 79.9193 71.75 83.6693 67.1667 83.6693H8.83333C4.20833 83.6693 0.5 79.9193 0.5 75.3359L0.541667 17.0026C0.541667 12.4193 4.20833 8.66927 8.83333 8.66927H13V0.335938H21.3333V8.66927H54.6667V0.335938H63V8.66927ZM8.83333 75.3359H67.1667V33.6693H67.1641V25.3359H67.1667V17.0026H67.1641V17H8.83203V34H8.83333V75.3359Z"
        fill={darkMode ? colours.silver : colours.black}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38 46H58.8333V66.8333H38V46ZM54.0002 53.0002L50.6213 56.3791L54.1211 59.8789L51.9998 62.0002L48.5 58.5005L45.0002 62.0002L42.8789 59.8789L46.3787 56.3791L42.9998 53.0002L45.1211 50.8789L48.5 54.2578L51.8789 50.8789L54.0002 53.0002Z"
        fill="#EB5757"
      />
    </SvgIcon>
  );
}
