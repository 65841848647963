import { InMemoryCache, useApolloClient } from '@apollo/client';
import { GuardHandler, HandlerMap, handleRecord } from '@propra-system/registry';
import { ProfileEvent } from 'api';
import { keyBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { hashCode, shallowOmit } from 'system';
import { cacheHandlers } from './cacheHandlers';

export const useEventHandlers = () => {
  const { cache } = useApolloClient();
  const defaultHandlers = cacheHandlers(cache as InMemoryCache);
  const [hashedHandlerMap, setHashedHandlerMap] = useState<Record<number, GuardHandler>>(
    keyBy(defaultHandlers, (guardHandler) => hashCode(guardHandler.map((fn) => fn.toString())))
  );
  const handlerMap = useMemo(() => Object.values(hashedHandlerMap), [hashedHandlerMap]);
  const hashMap = useMemo(
    () => Object.keys(hashedHandlerMap).map((d) => parseInt(d)),
    [hashedHandlerMap]
  );

  const removeHandler = useCallback((guardHandler: GuardHandler) => {
    const hash = hashCode(guardHandler.map((fn) => fn.toString()));
    setHashedHandlerMap((existing) => shallowOmit(existing, [hash]));
  }, []);

  const removeHandlerMap = useCallback(
    (map: HandlerMap) => map.forEach(removeHandler),
    [removeHandler]
  );

  const addHandler = useCallback((guardHandler: GuardHandler) => {
    const hash = hashCode(guardHandler.map((fn) => fn.toString()));
    setHashedHandlerMap((existing) => ({ ...existing, [hash]: guardHandler }));
  }, []);

  const addHandlerMap = useCallback((map: HandlerMap) => map.forEach(addHandler), [addHandler]);

  const handleEvent = useCallback(
    (event?: ProfileEvent) => {
      if (event?.detailType) {
        const parsedEvent = {
          ...event,
          'detail-type': event.detailType,
          detail: JSON.parse(event.detail || '{}') as Record<string | number | symbol, unknown>,
        };

        handlerMap.some(([p]) => p(parsedEvent)) &&
          void handleRecord(handlerMap, parsedEvent, undefined, { errorOnNoMatch: false });
      }
    },
    [handlerMap]
  );

  return {
    handleEvent,
    addHandler,
    addHandlerMap,
    removeHandler,
    removeHandlerMap,
    hashMap,
    handlerMap,
  };
};
