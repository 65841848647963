/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '../../src/util';
import { SystemAutopayCondofeeAction } from './condofeeAction';
import SystemAutopayCondofeeActionSchema from './condofeeAction.json';

export const isSystemAutopayCondofeeAction = guard<SystemAutopayCondofeeAction>(SystemAutopayCondofeeActionSchema as any);