import { QueryHookOptions, QueryResult } from '@apollo/client';
import { useEffect, useState } from 'react';
import { ensureArray } from '../system';

type PaginateAllQueryOptions<
  TData extends Record<string, unknown>,
  TVariables extends { nextToken?: string },
  TItems extends Array<unknown> | undefined,
> = QueryHookOptions<TData, TVariables> & {
  getNextToken: (data?: TData) => string | undefined;
  getItems?: (data: TData) => TItems;
};

export const usePaginateAllQuery = <
  TData extends Record<string, unknown>,
  TVariables extends { nextToken?: string },
  TItems extends Array<unknown> | undefined = Array<unknown> | undefined,
>(
  useListData: (options: QueryHookOptions<TData, TVariables>) => QueryResult<TData, TVariables>,
  { getNextToken, getItems, ...options }: PaginateAllQueryOptions<TData, TVariables, TItems>
) => {
  const [items, setItems] = useState<TItems>(ensureArray(undefined));
  const { fetchMore, loading, data, error, called } = useListData({
    ...options,
    onCompleted(d) {
      setItems(ensureArray(getItems?.(d)));
      options.onCompleted?.(d);
    },
  });
  const nextToken = getNextToken(data);

  useEffect(() => {
    nextToken && void fetchMore({ variables: { ...options.variables, nextToken } });
  }, [fetchMore, nextToken, options]);

  const [done, setDone] = useState(false);
  useEffect(() => {
    setDone(called && !loading && !nextToken);
  }, [loading, called, nextToken]);

  return [items, { data, loading, error, done }] as const;
};
