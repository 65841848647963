import { first, map } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { rrulestr } from 'rrule';
import { sortByAttribute } from './sort';
import { ensureArray, formatCurrency, isAfter, safeRound, safeSum } from './util';

export const rentFeeId = '0_rent';
export const incentiveFeeId = '1_incentive';
export const maxISODate = '9999-12-31T23:59:59Z';

function nowFromStart(startZ?: string) {
  const realStart = startZ ?? DateTime.now().startOf('day').toISO();
  return DateTime.fromISO(realStart);
}

export function isCurrentSince(baseStartZ?: string) {
  const start = nowFromStart(baseStartZ);
  return (residencyOrEffect?: { startZ: string; endZ?: string }) =>
    Boolean(
      residencyOrEffect &&
        (residencyOrEffect.endZ
          ? Interval.fromISO(`${residencyOrEffect.startZ}/${residencyOrEffect.endZ}`).contains(
              start
            )
          : DateTime.fromISO(residencyOrEffect.startZ) <= start)
    );
}

export const netEffect = (effects?: Array<{ effect: number }>, taxPct = 0) =>
  safeRound(safeSum(map(ensureArray(effects), 'effect')) * safeSum(1, taxPct));

export const nextTerm = (rrule?: string, fromDate = new Date()) => {
  try {
    const dt = DateTime.fromJSDate(rrulestr(rrule ?? '').after(fromDate));
    if (dt.isValid) return dt;
  } catch (e) {
    // no op
  }
};

export const isRentEffect = (effect?: { feeId?: string }) => effect?.feeId == rentFeeId;

export const isFeeEffect = (effect?: { feeId?: string }) =>
  effect?.feeId != rentFeeId && effect?.feeId != incentiveFeeId;

export const nextRateScheduleEffect = (
  effects: Array<{ feeId: string; startZ: string; effect: number }> = [],
  { startZ = DateTime.now().toISO() }: { startZ?: string } = {}
) =>
  first(
    effects
      .filter(isRentEffect)
      .filter((e) => e.startZ > startZ)
      .sort(sortByAttribute('startZ'))
  );

export const isFuture = (residencyOrEffect?: { startZ: string }) =>
  residencyOrEffect && +DateTime.now() < +DateTime.fromISO(residencyOrEffect.startZ);

export const isPast = (residency?: { endZ?: string }) =>
  Boolean(residency?.endZ && +DateTime.fromISO(residency.endZ) <= +DateTime.now());

export function nextScheduledEffects(
  effects: Array<{ feeId: string; startZ: string; effect: number }> = [],
  startZ?: string,
  feeIds?: string[]
) {
  const [nextEffect, ...rest] = effects
    .filter(({ feeId }) => !feeIds || feeIds.includes(feeId))
    .filter(isAfter(startZ ?? DateTime.now().toISO()))
    .sort(sortByAttribute('startZ'));

  return nextEffect
    ? [
        nextEffect,
        ...rest.filter((e) =>
          DateTime.fromISO(nextEffect.startZ).hasSame(DateTime.fromISO(e.startZ), 'day')
        ),
      ]
    : [];
}

export const nextRateScheduleDisplay = (
  effects: Array<{ feeId: string; startZ: string; effect: number }> = [],
  startZ?: string,
  hideEffectiveDate?: boolean,
  gst?: number
) => {
  const nextEffects = nextScheduledEffects(effects, startZ);
  const nextDateTime = nextEffects?.[0] && DateTime.fromISO(nextEffects[0].startZ);

  const effectiveDateDisplay =
    hideEffectiveDate || !nextDateTime
      ? ''
      : nextDateTime.toRelative({
          unit: ['years', 'months', 'days'],
          base: DateTime.now(),
        });

  const total = safeRound(netEffect(nextEffects) * safeSum(1, gst ?? 0));

  return nextDateTime
    ? `${total < 0 ? '↓' : '↑'} ${formatCurrency(total)} ${effectiveDateDisplay ?? ''}`
    : '';
};
