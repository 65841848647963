/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '../../src/util';
import { SystemDboBatchUpdatedEvent } from './batchUpdated';
import SystemDboBatchUpdatedEventSchema from './batchUpdated.json';

export const isSystemDboBatchUpdatedEvent = guard<SystemDboBatchUpdatedEvent>(SystemDboBatchUpdatedEventSchema as any);