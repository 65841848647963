import { Button, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { ReactNode } from 'react';

export type ActionCellProps = {
  id: string;
  icon: ReactNode;
  name: string;
  onClick: (id: string) => void;
  disabled?: boolean;
  float?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
      },
    },
    action: {
      display: 'flex',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(2),
        alignItems: 'center',
      },

      '&--float': {
        float: 'right',
        [theme.breakpoints.down('lg')]: {
          float: 'none',
        },
      },
    },
    icon: {
      marginRight: theme.spacing(1.5),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(0.5),
      },
    },
    text: {
      textTransform: 'capitalize',
      fontSize: '1em',
      fontWeight: 500,
      [theme.breakpoints.down('md')]: {
        fontSize: '0.8em',
      },
    },
  })
);

export default function ActionsCell({
  actions,
  stopPropagation = false,
}: {
  actions: ActionCellProps[];
  stopPropagation?: boolean;
}) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {actions.map(({ id, icon, name, onClick, disabled = false, float = false }) => (
        <Button
          key={`action-${name}-${id}`}
          className={clsx(classes.action, float && `${classes.action}--float`)}
          startIcon={icon}
          onClick={(e) => {
            if (stopPropagation) e.stopPropagation();
            onClick(id);
          }}
          disabled={disabled}
        >
          <Typography className={classes.text}>{name}</Typography>
        </Button>
      ))}
    </div>
  );
}
