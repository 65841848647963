import { SvgIconProps } from '@mui/material';
import * as Icons from '@mui/icons-material';
import clsx from 'clsx';
import { AmenityCategories } from 'system';

const iconComponent = (category: AmenityCategories) =>
  ({
    [AmenityCategories.RECREATION]: Icons.SportsGymnastics,
    [AmenityCategories.SECURITY]: Icons.Security,
    [AmenityCategories.STORAGE]: Icons.BikeScooter,
    [AmenityCategories.VEHICLE]: Icons.LocalParking,
    [AmenityCategories.PETS]: Icons.Pets,
    [AmenityCategories.GREEN_SPACE]: Icons.Yard,
    [AmenityCategories.WASTE]: Icons.CleaningServices,
    [AmenityCategories.ACCESSIBILITY]: Icons.WheelchairPickup,
    [AmenityCategories.SERVICES]: Icons.LocalPolice,
  }[category]);

const validCategory = (category: string): category is AmenityCategories =>
  Object.values(AmenityCategories).includes(category as AmenityCategories);

export default function AmenityIcon({
  category,
  className,
  ...props
}: { category?: string } & SvgIconProps) {
  const Icon = category && validCategory(category) ? iconComponent(category) : Icons.Apartment;

  return <Icon fontSize="small" color="disabled" {...props} className={clsx(className)} />;
}
