/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '../../src/util';
import { SystemAutopayAccountSettledEvent } from './accountSettled';
import SystemAutopayAccountSettledEventSchema from './accountSettled.json';

export const isSystemAutopayAccountSettledEvent = guard<SystemAutopayAccountSettledEvent>(SystemAutopayAccountSettledEventSchema as any);