import { Menu } from '@mui/icons-material';
import {
  BottomNavigation,
  BottomNavigationAction,
  CircularProgress,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavItems } from './useNavItems';

export default function BottomNav() {
  const location = useLocation();

  const { loading, navItems } = useNavItems();
  const visibleNavItems = navItems.filter(({ hidden }) => !hidden);

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const [showDrawer, setShowDrawer] = useState(false);
  const includeMenu = xs && visibleNavItems.length > 4;
  const truncatedNavItems = includeMenu ? visibleNavItems.slice(0, 3) : visibleNavItems;

  const matchedItem = visibleNavItems.find((i) => location.pathname.startsWith(i.to));

  useEffect(() => {
    if (!xs) {
      setShowDrawer(false);
    }
  }, [xs]);

  return loading ? (
    <BottomNavigation sx={{ width: '100%' }} value={matchedItem?.to}>
      <CircularProgress />
    </BottomNavigation>
  ) : (
    <BottomNavigation sx={{ width: '100%' }} value={matchedItem?.to} showLabels>
      <Drawer anchor="left" open={showDrawer} onClose={() => setShowDrawer(false)}>
        <List sx={{ mt: 12 }}>
          {visibleNavItems.map((nav) => (
            <ListItemButton key={`drawer_${nav.to}`} href={nav.to} selected={matchedItem === nav}>
              <ListItemIcon>{nav.icon}</ListItemIcon>
              <ListItemText>{nav.label}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </Drawer>

      {includeMenu && (
        <BottomNavigationAction
          value="more"
          icon={<Menu sx={{ color: 'primary.main' }} />}
          onClick={() => setShowDrawer(!showDrawer)}
        />
      )}

      {truncatedNavItems.map((nav, i) => (
        <BottomNavigationAction
          key={i}
          label={nav.label}
          value={nav.to}
          icon={nav.icon as ReactNode}
          href={nav.to}
        />
      ))}
    </BottomNavigation>
  );
}
