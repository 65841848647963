import { Box, BoxProps } from '@mui/material';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { PropsWithChildren, useRef } from 'react';

type AutoSizeBoxProps = PropsWithChildren<
  {
    bottomPadding?: number;
    minHeight?: number;
  } & Omit<BoxProps, 'ref'>
>;

export const AutoSizeBox = ({
  children,
  minHeight = 450,
  bottomPadding = 0,
  sx,
  ...boxProps
}: AutoSizeBoxProps) => {
  const box = useRef<{ offsetTop?: number }>({ offsetTop: 0 });
  const { height, offsetTop } = useWindowDimensions(box);

  return (
    <Box
      {...boxProps}
      ref={box}
      sx={{
        ...sx,
        height: Math.max(height - offsetTop - 60 - bottomPadding, minHeight),
        minHeight,
      }}
    >
      {children}
    </Box>
  );
};
