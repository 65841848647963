import { Check, Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { RichRadioFieldController } from 'components';
import {
  ConfirmAdditionalIncomeField,
  confirmAdditionalIncomeSchema,
  WorkHistoryFields,
} from '../schema';
import { WizardStep, WizardStepProps } from '../../WizardStep';
import { Header } from '../../WizardForm/WizardForm.styles';
import { useFormContext } from 'react-hook-form';

export function ConfirmAdditionalIncomeStep({
  onFinish,
}: WizardStepProps<typeof confirmAdditionalIncomeSchema>) {
  const { handleSubmit } = useFormContext<WorkHistoryFields>();

  return (
    <WizardStep
      stepperVariant="linear"
      spacing={0}
      title={<Header>Do you have any additional income sources?</Header>}
      schema={confirmAdditionalIncomeSchema}
      onFinish={() => onFinish && handleSubmit(onFinish)()}
    >
      <Stack gap={1} sx={{ my: 2 }}>
        <RichRadioFieldController<ConfirmAdditionalIncomeField>
          name="hasOtherIncome"
          options={[
            {
              value: 'yes',
              label: 'Yes',
              icon: Check,
              subLabel: 'I have additional income',
            },
            {
              value: 'no',
              label: 'No',
              icon: Close,
              subLabel: 'I do not have additional income',
            },
          ]}
        />
      </Stack>
    </WizardStep>
  );
}
