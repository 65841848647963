import { Theme, useMediaQuery } from '@mui/material';

export const useShowBreakpoints = () => {
  const hiddenMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const hiddenMdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  return {
    mdUp: !hiddenMdUp,
    mdDown: !hiddenMdDown,
  };
};
