/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantAutopayEnrollmentCancelledEvent } from './autopayEnrollmentCancelled';
import TenantAutopayEnrollmentCancelledEventSchema from './autopayEnrollmentCancelled.json';

export const isTenantAutopayEnrollmentCancelledEvent = guard<TenantAutopayEnrollmentCancelledEvent>(TenantAutopayEnrollmentCancelledEventSchema as any);