import {
  FormControl,
  FormHelperText,
  InputLabel as _InputLabel,
  MenuItem as _MenuItem,
  Select,
  SelectProps,
  styled,
} from '@mui/material';
import clsx from 'clsx';
import { spreadIf } from 'system';

export type DropdownFieldProps<T = unknown> = Omit<SelectProps<T>, 'error'> & {
  name?: string;
  label: string;
  data: {
    label: string;
    value: string | number;
  }[];
  helperText?: string;
  error?: { message?: string };
};

export const InputLabel = styled(_InputLabel)(({ theme }) => ({
  fontSize: 16,
  marginRight: theme.spacing(6),
  lineHeight: 'normal',
  marginBottom: theme.spacing(1),
}));

export const MenuItem = styled(_MenuItem)(() => ({
  fontSize: 16,
}));

export function DropdownField<T = unknown>({
  name,
  data,
  variant,
  helperText,
  className,
  label,
  error,
  ...props
}: DropdownFieldProps<T>) {
  return (
    <FormControl
      fullWidth={props.fullWidth}
      variant={variant ?? 'filled'}
      className={clsx(className)}
    >
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select<T>
        {...props}
        labelId={`${name}-label`}
        error={Boolean(error?.message)}
        {...spreadIf(variant !== 'standard', {
          sx: { bgcolor: 'background.paper', fontSize: 16 },
        })}
      >
        {data.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error?.message && <FormHelperText error>{error?.message}</FormHelperText>}
    </FormControl>
  );
}
