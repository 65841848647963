/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '../../src/util';
import { SystemAutopayPrepareSettlementAction } from './prepareSettlementAction';
import SystemAutopayPrepareSettlementActionSchema from './prepareSettlementAction.json';

export const isSystemAutopayPrepareSettlementAction = guard<SystemAutopayPrepareSettlementAction>(SystemAutopayPrepareSettlementActionSchema as any);