/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantManagerLeaseExpiredEvent } from './leaseHistory';
import TenantManagerLeaseExpiredEventSchema from './leaseHistory.json';

export const isTenantManagerLeaseExpiredEvent = guard<TenantManagerLeaseExpiredEvent>(TenantManagerLeaseExpiredEventSchema as any);