import { SvgIcon, SvgIconProps } from '@mui/material';
import { useTheme } from 'context';
import { colours } from 'system';

export default function Oops({ ...props }: SvgIconProps) {
  const { darkMode } = useTheme();
  return (
    <SvgIcon viewBox="0 0 215 47" {...props} style={{ width: 215 }}>
      <path
        d="M16.0746 5.176V8.392H0.330622V5.176H16.0746ZM21.4686 1.96H25.0686L39.7566 40.36H36.1566L21.4686 1.96ZM66.8717 41.656V45.256H40.9997V41.656H66.8717ZM79.9967 0.951998L82.2047 3.16C80.0927 5.08 78.4127 7.112 77.1647 9.256C75.9167 11.368 75.0207 13.608 74.4767 15.976C73.9327 18.312 73.6607 20.824 73.6607 23.512C73.6607 26.168 73.9327 28.68 74.4767 31.048C75.0207 33.416 75.9167 35.656 77.1647 37.768C78.4127 39.88 80.0927 41.912 82.2047 43.864L79.9967 46.072C76.6047 43.128 74.0447 39.768 72.3167 35.992C70.5887 32.184 69.7247 28.024 69.7247 23.512C69.7247 19 70.5887 14.84 72.3167 11.032C74.0447 7.224 76.6047 3.864 79.9967 0.951998ZM105.388 0.903998L101.692 2.152C102.892 4.648 105.628 12.088 106.396 14.824L110.14 13.528C109.324 10.84 106.444 3.208 105.388 0.903998ZM126.7 3.976L122.284 2.728C121.324 9.928 118.396 17.608 114.604 22.456C109.756 28.6 102.604 33.208 95.74 35.224L99.052 38.584C105.82 36.184 112.924 31.24 117.868 24.712C121.804 19.528 124.396 12.664 125.836 6.712C126.028 5.944 126.364 4.792 126.7 3.976ZM91.996 3.784L88.204 5.176C89.356 7.24 92.668 15.352 93.58 18.328L97.372 16.936C96.268 13.816 93.244 6.472 91.996 3.784ZM135.022 46.072L132.814 43.864C134.926 41.944 136.606 39.912 137.854 37.768C139.102 35.656 139.998 33.432 140.542 31.096C141.086 28.728 141.358 26.2 141.358 23.512C141.358 20.856 141.086 18.344 140.542 15.976C139.998 13.608 139.102 11.368 137.854 9.256C136.606 7.144 134.926 5.112 132.814 3.16L135.022 0.951998C138.414 3.896 140.974 7.272 142.702 11.08C144.43 14.856 145.294 19 145.294 23.512C145.294 28.024 144.43 32.184 142.702 35.992C140.974 39.8 138.414 43.16 135.022 46.072ZM173.997 41.656V45.256H148.125V41.656H173.997ZM193.554 1.96L178.866 40.36H175.266L189.954 1.96H193.554ZM214.669 5.176V8.392H198.925V5.176H214.669Z"
        fill={darkMode ? colours.lightSlateBlue : colours.purpleHeart}
      />
    </SvgIcon>
  );
}
