import { StringSchema, mixed, string } from 'yup';
import { MixedSchema } from 'yup/lib/mixed';
import { z } from 'zod';

export const requiredString = (msg?: string) => string().required(msg) as StringSchema<string>;

export const requiredFile = (msg?: string) => mixed<File>().required(msg) as MixedSchema<File>;

export const maybe = <TShape extends z.ZodTypeAny>(shape: TShape) =>
  shape.nullish().transform((x) => x ?? undefined);
