/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantManagerFutureLeaseCreatedEvent } from './leaseFuture';
import TenantManagerFutureLeaseCreatedEventSchema from './leaseFuture.json';

export const isTenantManagerFutureLeaseCreatedEvent = guard<TenantManagerFutureLeaseCreatedEvent>(TenantManagerFutureLeaseCreatedEventSchema as any);