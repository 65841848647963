import { useWizard } from 'react-use-wizard';
import { LinearProgress, LinearProgressContainer } from './WizardForm/WizardForm.styles';

export const LinearStepper = () => {
  const { activeStep, stepCount } = useWizard();
  const stepsToPercent = (100 / stepCount) * (activeStep + 1);
  return stepCount > 1 ? (
    <LinearProgressContainer mx={2}>
      <LinearProgress variant="determinate" value={stepsToPercent} />
    </LinearProgressContainer>
  ) : (
    <></>
  );
};
