import { Alert, Avatar, Box, ListItemAvatar, ListItemText, Skeleton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { range } from 'lodash';
import { useMemo, useState } from 'react';
import { filterByValue } from 'system';
import DocumentListItem from './DocumentListItem';
import DocumentSearchInput from './DocumentSearchInput';
import { List, ListItem } from './Documents.styles';
import { DocumentRowModel, KeyAction } from './types';

export default function DocumentList<T extends Record<string, unknown>>({
  loading,
  documents,
  downloadFn,
  extraColumns,
  deleteFn,
}: {
  documents: DocumentRowModel<T>[];
  extraColumns?: GridColDef<DocumentRowModel<T>>[];
  loading: boolean;
  downloadFn?: (document: DocumentRowModel<T>) => void;
  deleteFn?: KeyAction;
}) {
  const [search, setSearch] = useState<string | undefined>('');
  const filteredDocuments = useMemo(
    () => (search ? filterByValue(documents, search) : documents),
    [search, documents]
  );

  return (
    <>
      {!loading && !documents.length ? (
        <Alert severity="info">There are no documents to show.</Alert>
      ) : (
        <Box>
          {loading ? (
            <List>
              {range(7).map((i) => (
                <ListItem key={i} sx={{ m: 0, px: 0 }} isLast={i === 6}>
                  <ListItemAvatar>
                    <Skeleton variant="circular" width={32} height={32}>
                      <Avatar></Avatar>
                    </Skeleton>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Skeleton width="60%" height={20} />}
                    secondary={<Skeleton width="40%" height={14} />}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <List>
              <ListItem>
                <DocumentSearchInput loading={loading} onSearchEntered={setSearch} />
              </ListItem>
              {filteredDocuments.map((document, index) => (
                <DocumentListItem
                  key={index}
                  {...{
                    document,
                    isLastItem: index + 1 === filteredDocuments.length,
                    downloadFn,
                    extraColumns,
                    deleteFn,
                  }}
                />
              ))}
            </List>
          )}
        </Box>
      )}
    </>
  );
}
