import {
  RumActionEventDomainContext,
  RumErrorEventDomainContext,
  RumFetchResourceEventDomainContext,
  RumLongTaskEventDomainContext,
  RumOtherResourceEventDomainContext,
  RumViewEventDomainContext,
  RumXhrResourceEventDomainContext,
  datadogRum,
} from '@datadog/browser-rum';
import { parseJSON } from 'system';
import { z } from 'zod';

const env = process.env.REACT_APP_ENV ?? 'dev';
const version = process.env.REACT_APP_DD_VERSION;

const guard =
  <T extends z.ZodTypeAny>(shape: T) =>
  (value: unknown): value is z.infer<T> =>
    shape.safeParse(value).success;

const isFetchContext = (
  context:
    | RumViewEventDomainContext
    | RumActionEventDomainContext
    | RumFetchResourceEventDomainContext
    | RumXhrResourceEventDomainContext
    | RumOtherResourceEventDomainContext
    | RumErrorEventDomainContext
    | RumLongTaskEventDomainContext
): context is RumFetchResourceEventDomainContext => 'requestInput' in context;

const isGraphqlBody = guard(
  z.object({
    operationName: z.string(),
    variables: z.record(z.unknown()).optional(),
    query: z.string(),
  })
);

datadogRum.init({
  applicationId: '95b6f394-5d6e-48a8-91bd-54c2ee6b4769',
  clientToken: 'pub03409f511758afdccc38c2ee6e91eec9',
  site: 'datadoghq.com',
  service: `tenant-web-${env}`,
  env,
  version,
  sampleRate: 100,
  trackInteractions: true,
  useSecureSessionCookie: true,
  useCrossSiteSessionCookie: true,
  trackFrustrations: true,
  enableExperimentalFeatures: ['clickmap'],
  defaultPrivacyLevel: 'allow',
  beforeSend: (event, context) => {
    if (event.type === 'resource' && event.resource.type === 'fetch' && isFetchContext(context)) {
      const body = parseJSON(String(context.requestInit?.body));
      if (isGraphqlBody(body)) {
        event.resource.url = [event.resource.url, body.operationName].join('/');
        event.context = {
          ...event.context,
          graphql: {
            operationName: body.operationName,
            variables: body.variables,
          },
        };
      }
    }
  },
});

datadogRum.startSessionReplayRecording();
