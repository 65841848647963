import { TextField, TextFieldProps } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { toNumber } from 'lodash';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
        {
          display: 'none',
        },
    },
  })
);

export default function TextFieldController<T extends FieldValues = Record<string, undefined>>({
  name,
  variant,
  helperText,
  className,
  disabled,
  ...props
}: TextFieldProps & { name: Path<T> }) {
  const classes = useStyles();
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          variant={variant ?? 'filled'}
          fullWidth
          {...props}
          {...field}
          value={field.value ?? ''}
          disabled={disabled || isSubmitting}
          onChange={(e) => {
            field.onChange(
              props.type === 'number' ? (e.target.value ? toNumber(e.target.value) : e) : e
            );
            props.onChange?.(e);
          }}
          inputProps={{
            ...props.inputProps,
            inputMode: props.type === 'number' ? 'numeric' : 'text',
          }}
          InputProps={{ ...props.InputProps }}
          error={Boolean(fieldState.error?.message)}
          helperText={fieldState.error?.message || helperText}
          className={clsx(classes.container, className)}
        />
      )}
    />
  );
}
