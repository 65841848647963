import { alpha, Box, BoxProps, keyframes, styled } from '@mui/material';

const indeterminateAnimation = keyframes`
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
`;

const ProgressBar = styled('div')(({ theme }) => ({
  height: '4px',
  width: '100%',
  backgroundColor: alpha(theme.palette.primary.main, 0.2),
  overflow: 'hidden',
}));

const ProgressBarValue = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: theme.palette.primary.main,
  animation: `${indeterminateAnimation} 1s linear infinite`,
  transformOrigin: '0% 50%',
}));

export const CSSLinearProgress = (props: BoxProps) => {
  return (
    <Box {...props}>
      <ProgressBar>
        <ProgressBarValue />
      </ProgressBar>
    </Box>
  );
};
