import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

export const Countdown = ({ timestamp, maxSeconds }: { timestamp: string; maxSeconds: number }) => {
  const sentTime = DateTime.fromISO(timestamp);
  const currentTime = DateTime.now();
  const initialCounter = Math.max(
    0,
    maxSeconds - Math.floor(currentTime.diff(sentTime, 'seconds').seconds)
  );
  const [counter, setCounter] = useState(initialCounter);

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return counter > 0 ? (
    <>
      {`in ${Math.floor(counter / 60)}`.padStart(2, '0')}:{`${counter % 60}`.padStart(2, '0')}
    </>
  ) : (
    <></>
  );
};
