/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantClassifyLeaseNotFoundError } from './leaseNotFoundError';
import TenantClassifyLeaseNotFoundErrorSchema from './leaseNotFoundError.json';

export const isTenantClassifyLeaseNotFoundError = guard<TenantClassifyLeaseNotFoundError>(TenantClassifyLeaseNotFoundErrorSchema as any);