import { createContext, useContext } from 'react';

export const ThemeContext = createContext<{
  darkMode: boolean;
}>({
  darkMode: false,
});

const useTheme = () => useContext(ThemeContext);
export default useTheme;
