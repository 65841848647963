/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '../../src/util';
import { SystemAutopayStatusUpdatedEvent } from './statusUpdated';
import SystemAutopayStatusUpdatedEventSchema from './statusUpdated.json';

export const isSystemAutopayStatusUpdatedEvent = guard<SystemAutopayStatusUpdatedEvent>(SystemAutopayStatusUpdatedEventSchema as any);