import { Grid, Theme, useMediaQuery, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { CustomIcons } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      alignSelf: 'center',
      textAlign: 'center',
    },
    logo: {
      width: 66,
      height: 15,
      margin: theme.spacing(2),
    },
  })
);

export default function Footer() {
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  return mdUp ? (
    <Grid className={classes.footer}>
      <CustomIcons.PropraGrey className={classes.logo} />
    </Grid>
  ) : null;
}
