import { Stack } from '@mui/material';
import { TextFieldController } from 'components';

import { WizardStep, WizardStepProps } from '../../WizardStep';
import { nameFieldSchema } from '../schema';
import { Header } from '../../WizardForm/WizardForm.styles';
import { useWizard } from 'react-use-wizard';

export function NameStep({
  onCancel,
  showConfirmStep,
  ...props
}: WizardStepProps<typeof nameFieldSchema> & { showConfirmStep?: boolean }) {
  const { previousStep } = useWizard();

  return (
    <WizardStep
      stepperVariant="linear"
      spacing={0}
      title={<Header>Where did you work?</Header>}
      schema={nameFieldSchema}
      {...props}
      onCancel={() => {
        !showConfirmStep ? onCancel && onCancel() : previousStep();
      }}
    >
      <Stack gap={1} sx={{ my: 2 }}>
        <TextFieldController label="Name of workplace" name="name" variant="standard" />
      </Stack>
    </WizardStep>
  );
}
