import { ArrowDropDown } from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  PopperPlacementType,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { LoadingButton } from 'components';
import { MouseEvent, useRef, useState } from 'react';

export default function SplitButton({
  options,
  className,
  disabled,
  dropDownPlacement = 'bottom',
  sx,
}: {
  options: {
    label: string;
    onClick: () => void;
    loading?: boolean;
  }[];
  className?: string;
  disabled?: boolean;
  dropDownPlacement?: PopperPlacementType;
  sx?: SxProps<Theme>;
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = () => {
    options[selectedIndex].onClick();
  };

  const handleMenuItemClick = (
    _event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    options[index].onClick();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: globalThis.MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return options.length === 0 ? (
    <></>
  ) : options.length === 1 ? (
    <LoadingButton
      onClick={handleClick}
      style={{ height: 37 }}
      loading={options[0].loading}
      sx={sx}
      className={className}
      variant="contained"
    >
      {options[0].label}
    </LoadingButton>
  ) : (
    <>
      <ButtonGroup
        disabled={disabled}
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <LoadingButton
          onClick={handleClick}
          style={{ height: 37 }}
          loading={options[selectedIndex].loading}
          sx={sx}
          className={className}
        >
          {options[selectedIndex].label}
        </LoadingButton>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={dropDownPlacement}
        style={{ zIndex: 10 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      <Typography noWrap>{option.label}</Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
