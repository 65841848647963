/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantLeaseAcceptedEvent } from './leaseAccepted';
import TenantLeaseAcceptedEventSchema from './leaseAccepted.json';

export const isTenantLeaseAcceptedEvent = guard<TenantLeaseAcceptedEvent>(TenantLeaseAcceptedEventSchema as any);