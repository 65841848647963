/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '../../src/util';
import { SystemAutopayFeeAddedEvent } from './feeAdded';
import SystemAutopayFeeAddedEventSchema from './feeAdded.json';

export const isSystemAutopayFeeAddedEvent = guard<SystemAutopayFeeAddedEvent>(SystemAutopayFeeAddedEventSchema as any);