/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantClassifyAccountDisabledError } from './accountDisabledError';
import TenantClassifyAccountDisabledErrorSchema from './accountDisabledError.json';

export const isTenantClassifyAccountDisabledError = guard<TenantClassifyAccountDisabledError>(TenantClassifyAccountDisabledErrorSchema as any);