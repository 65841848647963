import { useGetBooksActivationQuery } from 'api';

export const useBooksActivated = (accountIds: string[]) => {
  const { data, loading } = useGetBooksActivationQuery({
    variables: { accountIds },
    skip: !accountIds,
  });

  return {
    loading,
    anyActivated: Boolean(data?.booksActivation?.anyActivated),
  };
};
