/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantProfileResidentAddedEvent } from './residentAdded';
import TenantProfileResidentAddedEventSchema from './residentAdded.json';

export const isTenantProfileResidentAddedEvent = guard<TenantProfileResidentAddedEvent>(TenantProfileResidentAddedEventSchema as any);