import ZodForm from 'components/FormController/ZodForm';
import { Wizard } from 'react-use-wizard';
import { useFormContext } from 'react-hook-form';
import { AddressHistoryFields, addressHistoryFieldsSchema } from './schema';
import { RentalTypeStep } from './steps/RentalTypeStep';
import { LandlordStep } from './steps/LandlordStep';
import { AddressStep } from './steps/AddressStep';
import { DurationStep } from './steps/DurationStep';
import { RentalType } from 'api';
import { sameMonthAsToday } from 'system';
import { ConfirmAdditionalAddressStep } from './steps/ConfirmAdditionalAddressStep';

const LocalWizard = ({
  onCancel,
  onFinish,
  isAdding,
}: {
  onCancel: VoidFunction;
  onFinish: (values?: AddressHistoryFields) => void;
  isAdding?: boolean;
}) => {
  const { watch } = useFormContext<AddressHistoryFields>();
  const rentalType = watch('rentalType');
  const endDate = watch('endDate');
  const endBeforeThisMonth = Boolean(!sameMonthAsToday(endDate) && endDate && isAdding);

  return (
    <Wizard>
      <RentalTypeStep {...{ onCancel }} />
      {rentalType === RentalType.Rental && <LandlordStep />}
      <AddressStep />
      <DurationStep {...{ onFinish, endBeforeThisMonth }} />
      {endBeforeThisMonth && <ConfirmAdditionalAddressStep {...{ onFinish }} />}
    </Wizard>
  );
};

export const AddressHistoryWizardForm = ({
  defaultValues,
  onCancel,
  onFinish,
  loading,
  isAdding,
}: {
  defaultValues?: AddressHistoryFields;
  onCancel: VoidFunction;
  onFinish: (values?: AddressHistoryFields) => void;
  loading?: boolean;
  isAdding?: boolean;
}) => {
  return loading ? (
    <></>
  ) : (
    <ZodForm schema={addressHistoryFieldsSchema} defaultValues={defaultValues}>
      <LocalWizard {...{ onCancel, onFinish, loading, isAdding }} />
    </ZodForm>
  );
};
