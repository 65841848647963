/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '../../src/util';
import { SystemDboMessageProcessedEvent } from './messageProcessed';
import SystemDboMessageProcessedEventSchema from './messageProcessed.json';

export const isSystemDboMessageProcessedEvent = guard<SystemDboMessageProcessedEvent>(SystemDboMessageProcessedEventSchema as any);