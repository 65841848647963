import { ReactNode } from 'react';
import { Typography, styled } from '@mui/material';

export const Input = styled('input')(() => ({
  display: 'none',
}));

export const UploadButton = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '11px',
  display: 'inline-block',
  marginTop: theme.spacing(2),
  padding: theme.spacing(1),
  textTransform: 'none',
  width: '100%',
  cursor: 'pointer',
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '13px',
  fontWeight: 500,
  textAlign: 'center',
}));

type Props = {
  inputId: string;
  children?: ReactNode;
  pickImage: (file: File | undefined) => void;
  useButton?: boolean;
};

export default function ImagePicker({ inputId, children, pickImage, useButton = true }: Props) {
  return (
    <>
      <Input
        accept="image/*"
        id={inputId}
        type="file"
        capture="environment"
        onChange={(e) => pickImage(e?.target?.files?.[0])}
      />
      <label htmlFor={inputId}>
        {useButton && (
          <UploadButton aria-label="upload image">
            <ButtonText>Add an image</ButtonText>
          </UploadButton>
        )}
        {children}
      </label>
    </>
  );
}
