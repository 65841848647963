import { Box, Grid, styled } from '@mui/material';

export const Container = styled(Grid)(() => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

export const Header = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  background: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const HeaderContent = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  maxWidth: 420,
  padding: theme.spacing(1.5),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2),
  },
}));

export const Content = styled(Grid)(() => ({
  width: '100%',
  maxWidth: 420,
  margin: 'auto',
  flexGrow: 1,
}));

export const EmptyMenuBox = styled(Box)(({ theme }) => ({
  width: 60,
  [theme.breakpoints.up('md')]: {
    width: 100,
  },
}));
