import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { DropdownField, DropdownFieldProps } from '../Fields/DropdownField';

export default function DropdownFieldController<T extends FieldValues = Record<string, undefined>>({
  name,
  renderValue,
  handleDropdownChange,
  disabled,
  ...props
}: Omit<DropdownFieldProps<T> & { name: Path<T> }, 'renderValue' | 'onChange'> & {
  renderValue?: string;
  handleDropdownChange?: (value: string) => void;
}) {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState }) => (
        <DropdownField<unknown>
          {...{ ...props, ...field }}
          fullWidth
          inputRef={ref}
          error={fieldState.error}
          value={field.value || renderValue || ''}
          disabled={disabled || isSubmitting}
          renderValue={renderValue ? () => renderValue : undefined}
          onChange={(event) => {
            handleDropdownChange?.(String(event.target.value));
            field.onChange(event);
          }}
        />
      )}
    />
  );
}
