import { SvgIcon, SvgIconProps } from '@mui/material';
import { useTheme } from 'context';
import { colours } from 'system';

export default function MachineLearning({ ...props }: SvgIconProps) {
  const { darkMode } = useTheme();
  const fill = darkMode ? colours.whiteSmoke : colours.black;
  return (
    <SvgIcon viewBox="0 0 86 86" {...props}>
      <path
        d="M61.4397 18.7197C59.8787 18.7197 58.4127 19.3267 57.3087 20.4307C56.2047 21.5347 55.5977 23.0007 55.5977 24.5607C55.5977 26.1217 56.2047 27.5877 57.3087 28.6917C58.4127 29.7957 59.8787 30.4027 61.4397 30.4027C63.0007 30.4027 64.4667 29.7957 65.5697 28.6917C66.6737 27.5877 67.2807 26.1217 67.2807 24.5617C67.2807 23.0007 66.6737 21.5337 65.5697 20.4297C64.4667 19.3267 63.0007 18.7197 61.4397 18.7197Z"
        fill={fill}
      />
      <path
        d="M61.4397 55.5967C59.8787 55.5967 58.4127 56.2037 57.3087 57.3077C56.2047 58.4117 55.5977 59.8767 55.5977 61.4377C55.5977 62.9987 56.2057 64.4647 57.3097 65.5687C58.4137 66.6727 59.8797 67.2807 61.4407 67.2807C63.0017 67.2807 64.4677 66.6737 65.5707 65.5697C66.6747 64.4657 67.2817 62.9997 67.2817 61.4387C67.2817 59.8777 66.6747 58.4117 65.5707 57.3077C64.4667 56.2037 62.9997 55.5967 61.4397 55.5967Z"
        fill={fill}
      />
      <path
        d="M6.1242 37.1572C4.5632 37.1572 3.0962 37.7642 1.9932 38.8682C0.890204 39.9722 0.283203 41.4382 0.283203 42.9992C0.283203 44.5612 0.890204 46.0272 1.9932 47.1302C3.0972 48.2342 4.5642 48.8422 6.1252 48.8422C7.6852 48.8422 9.1512 48.2342 10.2552 47.1312C12.5322 44.8542 12.5332 41.1472 10.2552 38.8672C9.1522 37.7652 7.6852 37.1572 6.1242 37.1572Z"
        fill={fill}
      />
      <path
        d="M48.5461 11.6681C48.3081 11.9061 48.0521 12.1211 47.7891 12.3251L55.2371 19.7731C55.4401 19.5101 55.6551 19.2551 55.8941 19.0161C56.1331 18.7781 56.3881 18.5631 56.6511 18.3591L49.2031 10.9111C49.0001 11.1741 48.7851 11.4291 48.5461 11.6681Z"
        fill={fill}
      />
      <path
        d="M43.0012 74.0352C41.4402 74.0352 39.9742 74.6422 38.8702 75.7452C36.5922 78.0222 36.5932 81.7292 38.8702 84.0082C39.9742 85.1112 41.4412 85.7192 43.0012 85.7192C44.5612 85.7192 46.0282 85.1112 47.1322 84.0082C49.4102 81.7302 49.4102 78.0242 47.1322 75.7452C46.0292 74.6432 44.5622 74.0352 43.0012 74.0352Z"
        fill={fill}
      />
      <path
        d="M19.0171 30.1047C18.7781 29.8657 18.5631 29.6107 18.3601 29.3477L10.9121 36.7957C11.1751 36.9987 11.4301 37.2137 11.6691 37.4517C11.9091 37.6917 12.1231 37.9477 12.3261 38.2097L19.7741 30.7617C19.5111 30.5587 19.2561 30.3437 19.0171 30.1047Z"
        fill={fill}
      />
      <path
        d="M66.9836 30.1047C66.7456 30.3437 66.4896 30.5587 66.2266 30.7617L73.6756 38.2107C73.8776 37.9487 74.0916 37.6927 74.3316 37.4527C74.5706 37.2147 74.8256 36.9997 75.0886 36.7957L67.6406 29.3477C67.4376 29.6107 67.2236 29.8667 66.9836 30.1047Z"
        fill={fill}
      />
      <path
        d="M79.8783 48.8422C81.4393 48.8422 82.9053 48.2352 84.0093 47.1322C86.2863 44.8542 86.2863 41.1482 84.0093 38.8692C82.9053 37.7652 81.4383 37.1572 79.8773 37.1572C78.3163 37.1572 76.8503 37.7652 75.7463 38.8682C73.4693 41.1462 73.4703 44.8532 75.7473 47.1312C76.8513 48.2342 78.3173 48.8422 79.8783 48.8422Z"
        fill={fill}
      />
      <path
        d="M50.3117 50.3146L50.3131 50.3132C52.2602 48.3662 53.3432 45.7559 53.3432 42.9992C53.3432 40.2428 52.2597 37.6336 50.3146 35.6867L50.3117 35.6838C48.3649 33.7387 45.7563 32.6572 43.0022 32.6572C40.2475 32.6572 37.6362 33.7402 35.6892 35.6872L35.6877 35.6887C33.7435 37.6347 32.6602 40.2439 32.6602 42.9992C32.6602 45.7533 33.741 48.364 35.6892 50.3122C37.6368 52.2598 40.2462 53.3412 43.0012 53.3412C45.7545 53.3412 48.3643 52.2603 50.3117 50.3146Z"
        fill="#24D1AD"
        stroke={darkMode ? colours.lightSlateBlue : colours.purpleHeart}
        strokeWidth="9"
      />
      <path
        d="M48.1913 48.1914L48.1918 48.1909C49.5768 46.8059 50.3432 44.9585 50.3432 42.9982C50.3432 41.0388 49.5767 39.1917 48.1923 37.8061L48.1913 37.8051C46.8064 36.4214 44.9602 35.6562 43.0022 35.6562C41.0439 35.6562 39.1955 36.4226 37.8105 37.8076L37.81 37.8081C36.4266 39.1927 35.6602 41.0398 35.6602 42.9982C35.6602 44.957 36.4251 46.8045 37.8105 48.1899C39.1957 49.5751 41.0422 50.3402 43.0012 50.3402C44.9589 50.3402 46.8062 49.5753 48.1913 48.1914Z"
        fill="#24D1AD"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M37.4566 74.3306C37.6956 74.0926 37.9506 73.8776 38.2136 73.6736L30.7656 66.2256C30.5616 66.4886 30.3476 66.7436 30.1086 66.9826C29.8696 67.2216 29.6146 67.4366 29.3516 67.6396L36.8006 75.0876C37.0016 74.8256 37.2166 74.5706 37.4566 74.3306Z"
        fill={fill}
      />
      <path
        d="M55.896 66.9816C55.657 66.7436 55.442 66.4876 55.239 66.2246L47.791 73.6726C48.054 73.8766 48.309 74.0916 48.548 74.3296C48.788 74.5696 49.002 74.8246 49.205 75.0866L56.653 67.6386C56.39 67.4356 56.134 67.2206 55.896 66.9816Z"
        fill={fill}
      />
      <path
        d="M74.3335 48.5451C74.0935 48.3051 73.8795 48.0501 73.6765 47.7881L66.2285 55.2361C66.4915 55.4391 66.7465 55.6541 66.9855 55.8931C67.2245 56.1321 67.4395 56.3871 67.6425 56.6501L75.0905 49.2021C74.8275 48.9981 74.5715 48.7831 74.3335 48.5451Z"
        fill={fill}
      />
      <path
        d="M30.1066 19.0171C30.3456 19.2551 30.5606 19.5111 30.7636 19.7741L38.2116 12.3261C37.9486 12.1221 37.6936 11.9071 37.4546 11.6691C37.2156 11.4301 37.0006 11.1751 36.7976 10.9121L29.3496 18.3601C29.6126 18.5631 29.8686 18.7771 30.1066 19.0171Z"
        fill={fill}
      />
      <path
        d="M37.4566 48.5441C37.2176 48.3061 37.0026 48.0501 36.7996 47.7871L29.3516 55.2351C29.6146 55.4391 29.8696 55.6541 30.1086 55.8921C30.3476 56.1311 30.5626 56.3861 30.7656 56.6491L38.2136 49.2011C37.9506 48.9981 37.6956 48.7831 37.4566 48.5441Z"
        fill="white"
      />
      <path
        d="M24.5627 67.2787C26.1227 67.2787 27.5897 66.6717 28.6927 65.5677C29.7957 64.4637 30.4037 62.9977 30.4037 61.4367C30.4037 59.8757 29.7967 58.4097 28.6927 57.3057C27.5897 56.2027 26.1227 55.5947 24.5627 55.5947C23.0017 55.5947 21.5357 56.2027 20.4317 57.3067C19.3277 58.4107 18.7207 59.8767 18.7207 61.4377C18.7207 62.9977 19.3287 64.4637 20.4317 65.5677C21.5347 66.6717 23.0017 67.2787 24.5627 67.2787Z"
        fill={fill}
      />
      <path
        d="M24.5627 30.4018C26.1227 30.4018 27.5887 29.7948 28.6917 28.6918C29.7957 27.5878 30.4027 26.1208 30.4027 24.5608C30.4027 22.9998 29.7957 21.5338 28.6917 20.4298C27.5877 19.3258 26.1217 18.7188 24.5617 18.7188C23.0007 18.7188 21.5337 19.3258 20.4307 20.4298C19.3277 21.5338 18.7207 22.9997 18.7207 24.5597C18.7207 26.1207 19.3287 27.5868 20.4317 28.6908C21.5357 29.7948 23.0027 30.4018 24.5627 30.4018Z"
        fill={fill}
      />
      <path
        d="M42.9992 11.9653C44.5592 11.9653 46.0272 11.3572 47.1302 10.2542C48.2342 9.15025 48.8412 7.68425 48.8412 6.12325C48.8412 4.56225 48.2342 3.09625 47.1302 1.99225C46.0272 0.890249 44.5602 0.28125 42.9992 0.28125C41.4382 0.28125 39.9722 0.88825 38.8692 1.99225C37.7662 3.09625 37.1582 4.56225 37.1582 6.12325C37.1582 7.68425 37.7652 9.15025 38.8692 10.2542C39.9732 11.3582 41.4392 11.9653 42.9992 11.9653Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
