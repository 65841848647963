import { useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar, VariantType } from 'notistack';

export const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  const sendNotification = (message: string, type?: VariantType) => {
    enqueueSnackbar((message as unknown as Error).message || message, {
      variant: type ?? 'default',
      autoHideDuration: 5000,
      anchorOrigin: xs
        ? {
            vertical: 'top',
            horizontal: 'center',
          }
        : {
            vertical: 'bottom',
            horizontal: 'left',
          },
    });
  };

  return {
    sendNotification,
  };
};
