import { Stack } from '@mui/material';
import { TextFieldController } from 'components';
import { WizardStep, WizardStepProps } from '../../WizardStep';
import { Header } from '../../WizardForm/WizardForm.styles';
import { phoneNumberSchema, WorkHistoryFields } from '../schema';
import { sameMonthAsToday } from 'system';
import { useWizard } from 'react-use-wizard';
import { useFormContext } from 'react-hook-form';
import { GetFieldIndexProp } from '../WorkHistoryWizardForm';
import { SalaryStep } from './SalaryStep';
import { DurationStep } from './DurationStep';

export function PhoneNumberStep({
  onFinish,
  getFieldIndex,
  isAdding,
}: WizardStepProps & GetFieldIndexProp & { isAdding?: boolean }) {
  const { goToStep } = useWizard();
  const { watch, handleSubmit } = useFormContext<WorkHistoryFields>();
  const endDate = watch('endDate');
  return (
    <WizardStep
      stepperVariant="linear"
      spacing={0}
      title={<Header>How can we contact them?</Header>}
      schema={phoneNumberSchema}
      onFinish={isAdding ? undefined : () => onFinish && handleSubmit(onFinish)()}
      finishLabel={isAdding ? undefined : 'Submit'}
      onBack={() => {
        sameMonthAsToday(endDate) || !endDate
          ? goToStep(getFieldIndex(SalaryStep))
          : goToStep(getFieldIndex(DurationStep));
      }}
    >
      <Stack gap={1} sx={{ my: 2 }}>
        <TextFieldController label="Phone number" name="phone" variant="standard" />
      </Stack>
    </WizardStep>
  );
}
