import * as Icons from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Typography } from './Status.styles';

export enum Statuses {
  INFO_NEEDED = 'INFO_NEEDED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  OPTIONAL = 'OPTIONAL',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
}

const iconComponent = (status: Statuses) =>
  ({
    INFO_NEEDED: Icons.ReportProblem,
    IN_PROGRESS: Icons.RestartAlt,
    COMPLETE: Icons.CheckCircle,
    OPTIONAL: Icons.CheckCircleOutline,
    ACTION_REQUIRED: Icons.ReportProblem,
  }[status]);

const statusLabel = (status: Statuses) =>
  ({
    INFO_NEEDED: 'More info needed',
    IN_PROGRESS: 'Continue',
    COMPLETE: 'Complete',
    OPTIONAL: 'Optional',
    ACTION_REQUIRED: 'Final action required',
  }[status]);

const validStatus = (status: string): status is Statuses => Object.keys(Statuses).includes(status);

export type StatusProps = {
  status: Statuses;
};

export const Status = ({ status }: StatusProps) => {
  const Icon = status && validStatus(status) ? iconComponent(status) : Icons.Build;
  const iconColor = [Statuses.INFO_NEEDED, Statuses.ACTION_REQUIRED].includes(status)
    ? 'warning'
    : 'success';

  return (
    <Stack gap={[0, 1]} direction="row" alignItems="center">
      <Icon color={iconColor} sx={{ fontSize: 18 }} />
      <Typography>{statusLabel(status)}</Typography>
    </Stack>
  );
};
