import Ajv, { JSONSchemaType, ValidateFunction } from 'ajv';
import addFormats from 'ajv-formats';
import { SQSEvent } from 'aws-lambda';
import { Context, EventBridgeEvent, GuardHandler, GuardResult, HandlerMap } from './types';

export const ajv: Ajv = addFormats(
  new Ajv({
    allowUnionTypes: true,
  })
);

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
const stringify = (obj: unknown) => JSON.stringify(obj, (k, v) => (v === undefined ? null : v), 2);
const passthroughLogger =
  <T = unknown>(logFn: Console['log']) =>
  (message: T) => {
    logFn(stringify(message));
    return message;
  };
const basename = (path = '') => {
  const arr = path.split('/');
  return arr[arr.length - 1];
};

type Falsy = null | undefined | false | 0 | '';
export type SchemaFor<T> = JSONSchemaType<T>;

/**
 * @deprecated Use Zod shapes instead of AJV guards
 */
export const guard = <T = unknown>(schema: SchemaFor<T>) => ajv.compile<T>(schema);

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const uniq = <T = unknown>(arr: T[]) => [...new Set(arr)];
export const compact = <T = unknown>(arr: (T | Falsy)[]): T[] => arr.filter(Boolean) as T[];
export const error = <T = unknown>(message: T) => passthroughLogger<T>(console.error)(message);
export const warn = <T = unknown>(message: T) => passthroughLogger<T>(console.warn)(message);
export const info = <T = unknown>(message: T) => passthroughLogger<T>(console.info)(message);
export const debug = <T = unknown>(message: T) => passthroughLogger<T>(console.debug)(message);
export const trace = () => new Error().stack?.split('\n') ?? [];
export const indent = (message: string, tabs = 0) => console.info(`${'  '.repeat(tabs)}${message}`);

export const tap =
  <T = unknown>(fn: (array: T[]) => void) =>
  (array: T[]) => {
    fn(array);
    return array;
  };

export const except =
  <T extends Record<string, unknown>>(props: { [key in keyof T]: unknown }) =>
  (record: T) =>
    Object.entries(props).some(([key, value]) => record[key] !== value);

export const has =
  <T extends Record<string, unknown>>(props: { [key in keyof T]: unknown }) =>
  (record: T) =>
    Object.entries(props).every(([key, value]) => record[key] === value);

/**
 * @deprecated Prefer to use `valid` - this will be removed in a future iteration
 */
export const check = <TReturn = unknown, TFocus = unknown>(
  focus: unknown,
  guard: ValidateFunction<TFocus>,
  fn: (focus: TFocus) => Promise<unknown> | TReturn
) => {
  const valid = guard(focus);
  return valid
    ? fn(focus)
    : Promise.resolve(
        warn({
          message: 'Validation failed for this object, guarded function did not execute',
          trace: trace(),
          objectType: typeof focus,
          object: focus,
          validationErrors: guard.errors,
        })
      );
};

/**
 * @deprecated Prefer to use `valid` - this will be removed in a future iteration
 */
export const ensure = <TReturn = unknown, TFocus = unknown>(
  focus: unknown,
  guard: ValidateFunction<TFocus>,
  fn: (focus: TFocus) => Promise<unknown> | TReturn
) => {
  const valid = guard(focus);
  return valid
    ? fn(focus)
    : Promise.resolve(
        error({
          message: 'Validation failed for this object, guarded function did not execute',
          trace: trace(),
          objectType: typeof focus,
          object: focus,
          validationErrors: guard.errors,
        })
      );
};

interface EnsureMulti {
  <T1, T2, R>(
    tuples: [[unknown, ValidateFunction<T1>], [unknown, ValidateFunction<T2>]],
    fn: (p1: T1, p2: T2) => R
  ): R;
  <T1, T2, T3, R>(
    tuples: [
      [unknown, ValidateFunction<T1>],
      [unknown, ValidateFunction<T2>],
      [unknown, ValidateFunction<T3>],
    ],
    fn: (p1: T1, p2: T2, p3: T3) => R
  ): R;
  <T1, T2, T3, T4, R>(
    tuples: [
      [unknown, ValidateFunction<T1>],
      [unknown, ValidateFunction<T2>],
      [unknown, ValidateFunction<T3>],
      [unknown, ValidateFunction<T4>],
    ],
    fn: (p1: T1, p2: T2, p3: T3, p4: T4) => R
  ): R;
  <T1, T2, T3, T4, T5, R>(
    tuples: [
      [unknown, ValidateFunction<T1>],
      [unknown, ValidateFunction<T2>],
      [unknown, ValidateFunction<T3>],
      [unknown, ValidateFunction<T4>],
      [unknown, ValidateFunction<T5>],
    ],
    fn: (p1: T1, p2: T2, p3: T3, p4: T4, p5: T5) => R
  ): R;
  <T1, T2, T3, T4, T5, T6, R>(
    tuples: [
      [unknown, ValidateFunction<T1>],
      [unknown, ValidateFunction<T2>],
      [unknown, ValidateFunction<T3>],
      [unknown, ValidateFunction<T4>],
      [unknown, ValidateFunction<T5>],
      [unknown, ValidateFunction<T6>],
    ],
    fn: (p1: T1, p2: T2, p3: T3, p4: T4, p5: T5, p6: T6) => R
  ): R;
  <T1, T2, T3, T4, T5, T6, T7, R>(
    tuples: [
      [unknown, ValidateFunction<T1>],
      [unknown, ValidateFunction<T2>],
      [unknown, ValidateFunction<T3>],
      [unknown, ValidateFunction<T4>],
      [unknown, ValidateFunction<T5>],
      [unknown, ValidateFunction<T6>],
      [unknown, ValidateFunction<T7>],
    ],
    fn: (p1: T1, p2: T2, p3: T3, p4: T4, p5: T5, p6: T6, p7: T7) => R
  ): R;
  <T1, T2, T3, T4, T5, T6, T7, T8, R>(
    tuples: [
      [unknown, ValidateFunction<T1>],
      [unknown, ValidateFunction<T2>],
      [unknown, ValidateFunction<T3>],
      [unknown, ValidateFunction<T4>],
      [unknown, ValidateFunction<T5>],
      [unknown, ValidateFunction<T6>],
      [unknown, ValidateFunction<T7>],
      [unknown, ValidateFunction<T8>],
    ],
    fn: (p1: T1, p2: T2, p3: T3, p4: T4, p5: T5, p6: T6, p7: T7, p8: T8) => R
  ): R;
}

/**
 * @depcrecated Prefer to use `valid` - this will be removed in a future iteration
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const ensureAll: EnsureMulti = (tuples: [unknown, ValidateFunction][], fn: Function) => {
  const valid = tuples.reduce((valid, [focus, guard]) => {
    const focusValid = guard(focus);
    if (!focusValid) {
      error({
        objectType: typeof focus,
        object: focus,
        validationErrors: guard.errors,
      });
    }
    return valid && focusValid;
  }, true);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return valid
    ? fn(...tuples.map(([v]) => v))
    : Promise.resolve(
        error({
          message: 'Validation failed for one or more objects, guarded function did not execute',
          trace: trace(),
        })
      );
};

export const guardHandler = <T = unknown>(
  guard: GuardHandler<T>[0],
  handler: GuardHandler<T>[1]
): GuardHandler<T> => [guard, handler];

type BaseEvent = {
  source: 'system' | string;
  detail: Record<string, unknown>;
  'detail-type'?: string;
  detailType?: string;
};

export const isBaseEvent = (data: unknown): data is BaseEvent =>
  data !== null &&
  typeof data === 'object' &&
  typeof (data as Record<string, unknown>)['detail'] === 'object' &&
  (typeof (data as Record<string, unknown>)['detail-type'] === 'string' ||
    typeof (data as Record<string, unknown>)['detailType'] === 'string') &&
  typeof (data as Record<string, unknown>)['source'] === 'string';

export const handleRecord = (
  handlerMap: HandlerMap,
  event: EventBridgeEvent,
  context?: Context,
  options?: {
    errorOnNoMatch?: boolean;
  }
) => {
  const { errorOnNoMatch } = { errorOnNoMatch: true, ...options };
  const wrapper = errorOnNoMatch ? errorWhenNoneValidFor : (array: GuardResult<unknown>[]) => array;
  return Promise.all(
    wrapper(handlerMap.map(runGuards(event)))
      .filter(({ valid }) => valid)
      .flatMap(({ handler, modelName = 'Handler' }) => handler(event, context))
  );
};

export const runGuards =
  (event: EventBridgeEvent) =>
  ([guard, handler]: GuardHandler): GuardResult => ({
    modelName: modelName(guard),
    valid: guard(event),
    errors: guard.errors,
    handler,
  });

export const errorWhenNoneValidFor = tap((results: GuardResult[]) => {
  if (results.every(({ valid }) => !valid)) {
    error({
      message: 'Event did not match any handler guard',
      errors: results
        .filter(
          ({ errors }) =>
            errors && !errors.some(({ instancePath }) => instancePath === '/detail-type')
        )
        .reduce((r, { errors, modelName }, i) => ({ ...r, [modelName ?? i]: errors }), {}),
    });
  }
});

const hasRef = ajv.compile<{ $ref: string }>({
  type: 'object',
  required: ['$ref'],
  properties: { $ref: { type: 'string' } },
});

export const modelName = (guard: ValidateFunction) =>
  hasRef(guard.schema) ? basename(guard.schema.$ref) : 'Unknown';

export const unwrapIfSingletonArray = <T = unknown>(arr: T[]) => (arr.length === 1 ? arr[0] : arr);

export const spreadObject = <P = unknown, T = unknown>(predicate: P, obj?: T) =>
  predicate ? (obj ? obj : predicate) : {};
export const spreadArray = <P = unknown, T = unknown>(predicate: P, arr?: T) =>
  predicate
    ? arr
      ? Array.isArray(arr)
        ? arr
        : [arr]
      : Array.isArray(predicate)
        ? predicate
        : [predicate]
    : [];

export const emptyContext = (): Context => ({
  callbackWaitsForEmptyEventLoop: false,
  functionName: '',
  functionVersion: '',
  invokedFunctionArn: '',
  memoryLimitInMB: '',
  awsRequestId: '',
  logGroupName: '',
  logStreamName: '',
  getRemainingTimeInMillis: () => NaN,
  done: () => null,
  fail: () => null,
  succeed: () => null,
});

export const isSQSEvent = guard<SQSEvent>({
  type: 'object',
  required: ['Records'],
  properties: {
    Records: {
      type: 'array',
      items: {
        type: 'object',
        required: [
          'messageId',
          'receiptHandle',
          'body',
          'attributes',
          'messageAttributes',
          'md5OfBody',
          'eventSource',
          'eventSourceARN',
          'awsRegion',
        ],
        properties: {
          messageId: { type: 'string' },
          receiptHandle: { type: 'string' },
          body: { type: 'string' },
          attributes: {
            type: 'object',
            required: [
              'ApproximateReceiveCount',
              'SentTimestamp',
              'SenderId',
              'ApproximateFirstReceiveTimestamp',
            ],
            properties: {
              AWSTraceHeader: { type: 'string', nullable: true },
              ApproximateReceiveCount: { type: 'string' },
              SentTimestamp: { type: 'string' },
              SenderId: { type: 'string' },
              ApproximateFirstReceiveTimestamp: { type: 'string' },
              SequenceNumber: { type: 'string', nullable: true },
              MessageGroupId: { type: 'string', nullable: true },
              MessageDeduplicationId: { type: 'string', nullable: true },
              DeadLetterQueueSourceArn: { type: 'string', nullable: true },
            },
          },
          messageAttributes: {
            type: 'object',
            required: [],
            patternProperties: {
              '.*': {
                type: 'object',
                required: ['dataType'],
                properties: {
                  stringValue: { type: 'string', nullable: true },
                  binaryValue: { type: 'string', nullable: true },
                  stringListValues: {
                    type: 'array',
                    items: { type: 'string', nullable: true },
                    nullable: true,
                  },
                  binaryListValues: {
                    type: 'array',
                    items: { type: 'string', nullable: true },
                    nullable: true,
                  },
                  dataType: { type: 'string', nullable: false },
                },
              },
            },
          },
          md5OfBody: { type: 'string' },
          eventSource: { type: 'string' },
          eventSourceARN: { type: 'string' },
          awsRegion: { type: 'string' },
        },
      },
    },
  },
});
