import {
  AppBar,
  Paper,
  Toolbar,
  Typography,
  IconButton as _IconButton,
  Stack as _Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTopAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: theme.zIndex.drawer + 1,
}));

export const StyledBottomAppBar = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  borderTop: `1px solid ${theme.palette.divider}`,
  zIndex: 1000,
  padding: theme.spacing(1),
  borderRadius: 0,
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  margin: theme.spacing(1.25, 3),
  justifyContent: 'space-between',
}));

export const BottomStyledToolbar = styled(Toolbar)(({ theme }) => ({
  margin: theme.spacing(1, 2),
  justifyContent: 'space-between',
}));

export const LinkText = styled(Typography)<{ selected?: boolean }>(({ theme, selected }) => ({
  cursor: 'pointer',
  color: theme.palette.text.primary,
  ...(selected ? { color: theme.palette.text.primary } : {}),
}));

export const NavIcon = styled('span')<{ selected?: boolean }>(({ theme, selected }) => ({
  marginTop: theme.spacing(1),
  color: selected ? theme.palette.primary.main : theme.palette.text.primary,
}));

export const IconButton = styled(_IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  display: 'block',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const DesktopNavStack = styled(_Stack)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'inherit',
  },
}));
