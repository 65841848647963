import { Apartment, PeopleAlt, Home } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { RichRadioFieldController } from 'components';
import { RentalTypeField, rentalTypeFieldSchema } from '../schema';
import { WizardStep, WizardStepProps } from '../../WizardStep';
import { Header } from '../../WizardForm/WizardForm.styles';

export function RentalTypeStep({ onCancel }: WizardStepProps<typeof rentalTypeFieldSchema>) {
  return (
    <WizardStep
      schema={rentalTypeFieldSchema}
      stepperVariant="linear"
      spacing={0}
      title={<Header>Let's review your address history</Header>}
      onCancel={onCancel}
    >
      <Stack gap={1} sx={{ my: 2 }}>
        <RichRadioFieldController<RentalTypeField>
          name="rentalType"
          options={[
            {
              value: 'rental',
              label: 'Rental',
              icon: Apartment,
            },
            {
              value: 'own',
              label: 'Owned',
              icon: Home,
            },
            {
              value: 'family',
              label: 'Family Owned',
              icon: PeopleAlt,
            },
          ]}
        />
      </Stack>
    </WizardStep>
  );
}
