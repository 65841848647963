import { useLayoutEffect, useState } from 'react';

export const useWindowDimensions = (
  ref?: React.MutableRefObject<{
    offsetTop?: number;
  }>
) => {
  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });
  const [offsetTop, setOffsetTop] = useState(0);

  useLayoutEffect(() => {
    let timeout = -1;

    const handleResize = () => {
      window.cancelAnimationFrame(timeout);
      timeout = window.requestAnimationFrame(() => {
        setWindowDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        });
        if (ref?.current.offsetTop) {
          setOffsetTop(ref.current.offsetTop);
        }
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.cancelAnimationFrame(timeout);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { ...windowDimensions, offsetTop };
};
