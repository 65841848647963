/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantAutopayEnrollmentCreatedEvent } from './autopayEnrollmentCreated';
import TenantAutopayEnrollmentCreatedEventSchema from './autopayEnrollmentCreated.json';

export const isTenantAutopayEnrollmentCreatedEvent = guard<TenantAutopayEnrollmentCreatedEvent>(TenantAutopayEnrollmentCreatedEventSchema as any);