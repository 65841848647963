/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantProfileEmailChangedEvent } from './emailChanged';
import TenantProfileEmailChangedEventSchema from './emailChanged.json';

export const isTenantProfileEmailChangedEvent = guard<TenantProfileEmailChangedEvent>(TenantProfileEmailChangedEventSchema as any);