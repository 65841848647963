import { Stack } from '@mui/material';
import { GooglePlacesAutocomplete, TextFieldController } from 'components';
import { useFormContext } from 'react-hook-form';
import { AddressField, addressFieldSchema } from '../schema';
import { WizardStep } from '../../WizardStep';
import { Header } from '../../WizardForm/WizardForm.styles';

export function AddressStep() {
  const { setValue, watch } = useFormContext<AddressField>();
  const streetName = watch('streetName');
  return (
    <WizardStep
      schema={addressFieldSchema}
      stepperVariant="linear"
      spacing={0}
      title={<Header>What is the address?</Header>}
    >
      <Stack gap={3} sx={{ my: 2 }}>
        <GooglePlacesAutocomplete
          disableClearable={true}
          initialValue={streetName}
          onChange={(location) => {
            if (location?.streetName) setValue('streetName', location.streetName);
            if (location?.city) setValue('city', location.city);
            if (location?.province) setValue('province', location.province);
            if (location?.postalCode) setValue('postalCode', location.postalCode);
          }}
          renderInput={(params) => {
            const paramsWithoutAdornment = {
              ...params,
              InputProps: {
                ...params.InputProps,
                endAdornment: null,
              },
            };
            return (
              <TextFieldController
                {...paramsWithoutAdornment}
                name="streetName"
                label="Street"
                variant="standard"
              />
            );
          }}
        />
        <TextFieldController name="city" label="City" variant="standard" />
        <TextFieldController name="province" label="Province" variant="standard" />
        <TextFieldController name="postalCode" label="Postal Code" variant="standard" />
      </Stack>
    </WizardStep>
  );
}
