/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { TenantResidentInsuranceUpdatedEvent } from './insuranceUpdated';
import TenantResidentInsuranceUpdatedEventSchema from './insuranceUpdated.json';

export const isTenantResidentInsuranceUpdatedEvent = guard<TenantResidentInsuranceUpdatedEvent>(TenantResidentInsuranceUpdatedEventSchema as any);